<template>
  <div>
    <FinancialProducts />
    <Brands />
    <TrendingList />
    <financial-decisions />
    <div class="d-flex justify-content-center">
      <loan-rates />
    </div>
    <div class="overflow-hidden">
      <b-container class="my-5">
        <Carousel />
        <ExploreMoreCarousel />
      </b-container>
    </div>
  </div>
</template>

<script>
import Trustpilotlogo from "../assets/Trustpilot-logo.svg";
import brandLogo from "../assets/brandlogo.jpg";
import BestMoneyDropdown from "../components/BestMoneyDropdown.vue";
import Brands from "../components/Brands.vue";
import Carousel from "../components/Carousel.vue";
import ExploreMoreCarousel from "../components/ExploreMoreCarousel.vue";
import FinancialDecisions from "../components/FinancialDecisions.vue";
import FinancialProducts from "../components/FinancialProducts.vue";
import LoanRates from "../components/LoanRates.vue";
import TrendingList from "../components/TrendingList.vue";

export default {
  name: "Home",

  components: {
    ExploreMoreCarousel,
    LoanRates,
    FinancialDecisions,
    BestMoneyDropdown,
    FinancialProducts,
    Carousel,
    Brands,
    TrendingList,
  },
  data() {
    return {
      reviewOnlineAccount: [
        {
          logo: brandLogo,
          Trustpilotlogo: Trustpilotlogo,
          data: [
            {
              name: "Fees?",
              price: "10.0",
              description: "No Fees at all",
            },
            {
              name: "Security",
              price: "10.0",
              description:
                "FDIC insured, SSL/AES encryption and supports 2 factor authentication ",
            },
            {
              name: "Customer service",
              price: "8.0",
              description:
                "Early morning, evening and weekend service with multiple communication options and a dedicated fraud team ",
            },
            {
              name: "Online experience",
              price: "10.0",
              description:
                "Excellent user friendly site with transparency of rates and full online banking capabilities  ",
            },
            {
              name: "Mobile experience",
              price: "10.0",
              description:
                "Excellent mobile app with all mobile banking features enabled, including digital check deposits ",
            },
          ],
          prosData: [
            "No minimum deposit requirement",
            "High yields savings account",
            "No monthly fees",
            "Incredible mobile app experience",
          ],
          consData: ["Only one physical location"],
          check: false,
          title: "SoFi Checking and Savings",
        },
      ],
    };
  },
  methods: {
    toggle(id, value) {
      console.log("id,value", id, value);
      this.reviewOnlineAccount[id].check = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
