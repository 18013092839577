<template>
    <b-container class="py-md-2 pt-2 pb-0 px-md-2 px-0">
        <div class="trending-top-card">
            <b-row>
                <b-col md="6">
                    <div class="trending-top-card__img">
                        <img class="w-100 h-100" :src="item.mainImage" />
                    </div>
                </b-col>
                <b-col md="6" class="text-left mt-md-0 mt-3">
                    <p class="trending-top-card__title fw--700 text-site-dark mb-1">
                        {{ item?.description1 }}
                    </p>
                    <p class="trending-top-card__title fw--700 text-site-dark mb-1">
                        {{ item?.description2 }}
                    </p>

                    <p class="trending-top-card__date mt-1 mt-md-4 text-left text-site-dark mb-0">
                        {{ item?.date }}
                    </p>

                    <button class="mt-1 mt-md-4 trending-top-card__btn px-3 text-site-button fw--600 bg-white py-2">
                        View {{ item?.title }}
                        <i class="fa fa-arrow-right ml-2"></i>
                    </button>
                </b-col>
            </b-row>
        </div>

        <b-row>
            <b-col v-for="(subItem, index) in item?.subItems.slice(0, 2)" lg="4" :key="index" class="mt-4 border-right">
                <div class="trending-review-card d-flex">
                    <div class="trending-review-card__img mr-3">
                        <img class="w-100 h-100" :src="subItem.icon" />
                    </div>
                    <div class="d-flex flex-column">
                        <p class="trending-review-card__title fw--700 text-left mb-2">
                            {{ subItem?.title }}
                        </p>

                        <div class="d-flex flex-lg-column align-items-lg-start align-items-sm-center align-items-start flex-sm-row flex-column">
                            <p class="m-0 trending-review-card__description text-left">
                                {{ subItem?.name }}
                            </p>
                            <div class="dot d-lg-none d-sm-inline d-none mx-1"></div>
                            <p class="m-0 trending-review-card__description text-left">
                                {{ subItem?.date }}
                            </p>
                        </div>
                    </div>
                </div>
            </b-col>

            <b-col v-if="item?.review" lg="4" class="mt-4">
                <div class="trending-review-card d-flex">
                    <div class="trending-review-card__border-img px-1 mr-3">
                        <img class="w-100 h-100 logo-img" :src="item?.review?.icon" />
                    </div>
                    <div class="d-flex flex-column">
                        <p class="trending-review-card__title fw--700 text-left mb-2">{{ item?.review?.title }} Review</p>
                        <p class="m-0 trending-review-card__description text-left">
                            {{ item?.review?.date }}
                        </p>
                    </div>
                </div>
            </b-col>
            <!-- <b-col lg="4" class="mt-4 border-right">
        <div class="trending-review-card d-flex">
          <div class="trending-review-card__img mr-3">
            <img
              class="w-100 h-100"
              src="https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/12WaystoLowerCarInsuranceCostthumbnail.20221221224407.jpg"
            />
          </div>
          <div class="d-flex flex-column trending-review-card__title">
            <p class="trending-review-card__title fw--700 text-left mb-2">
              11 strategies to cut car insurance costs
            </p>
            <div
              class="d-flex flex-lg-column align-items-lg-start align-items-center flex-row"
            >
              <p class="m-0 trending-review-card__description text-left">
                Demo pass
              </p>
              <div class="dot d-lg-none d-inline mx-1"></div>
              <p class="m-0 trending-review-card__description text-left">
                Mar, 2023
              </p>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="4" class="mt-4">
        <div class="trending-review-card d-flex">
          <div class="trending-review-card__border-img px-1 mr-3">
            <img
              class="w-100 h-100 logo-img"
              src="../assets/TrendingList/quotewizard.svg"
            />
          </div>
          <div class="d-flex flex-column trending-review-card__title">
            <p class="trending-review-card__title fw--700 text-left mb-2">
              11 strategies to cut car insurance costs
            </p>
            <p class="m-0 trending-review-card__description text-left">
              Mar, 2023
            </p>
          </div>
        </div>
      </b-col> -->
        </b-row>
    </b-container>
</template>
<script>
import '../assets/scss/index.scss';

export default {
    name: 'TrendingListCard',

    data() {
        return {};
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.border-right {
    border-right: 1px solid #e3e3e2;
    @media (max-width: 991.99px) {
        border: 0px !important;
    }
}

.dot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #787878;
}
.trending-top-card {
    &__img {
        height: 262px;
        img {
            object-fit: cover;
        }
    }

    &__title {
        // font-size: 24px;
        font-size: 24px;
        line-height: 25px;
        text-align: start;
    }

    &__date {
        font-size: 14px;
        line-height: 21px;
        color: #787878 !important;
    }

    &__btn {
        border: 2px solid #49a8ff !important;
        font-size: 14px;
        line-height: 21px;
    }
    &:hover {
        cursor: pointer;
        .trending-top-card__title {
            text-decoration: underline;
        }
    }

    @media (max-width: 991.99px) {
        &__title {
            // font-size: 24px;
            font-size: 24px;
            line-height: 25px;
            text-align: start;
        }

        &__date {
            font-size: 14px;
            line-height: 21px;
            color: #787878 !important;
        }

        &__btn {
            border: 2px solid #49a8ff !important;
            font-size: 14px;
            line-height: 21px;
        }
        &:hover {
            cursor: pointer;
            .trending-top-card__title {
                text-decoration: underline;
            }
        }

        @media (max-width: 991.99px) {
            &__title {
                font-size: 20px;
                line-height: 21px;
            }
            &__img {
                height: 210px !important;
            }
        }

        @media (max-width: 767.99px) {
            &__img {
                height: 176px !important;
            }
            &__btn {
                width: 100% !important;
            }
        }
    }
}
.trending-review-card {
    &__img {
        width: 131px !important;
        height: 90px !important;

        img {
            border-radius: 5px;
            object-fit: cover;
            width: 131px !important;
            height: 90px !important;
        }
    }

    &__border-img {
        width: 131px !important;
        height: 90px !important;
        border-radius: 5px;
        border: 1px solid #e3e3e2 !important;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            border-radius: 5px;
            object-fit: cover;
            width: 105px !important;
            height: auto !important;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 20px;
    }

    &__description {
        font-size: 13px;
        color: #787878 !important;
        line-height: 18px;
    }
    &:hover {
        cursor: pointer;
        .trending-review-card__title {
            text-decoration: underline;
        }
    }
}
</style>
