<template>
  <div>
    <hr class="my-5" />

        <h2 class="fs--20 fw--700 text-justify text-site-dark">
            {{ data.header }}
        </h2>
        <div v-for="(bankData, index) in data.data" :key="index">
            <h2 class="fw--700 fs--20 d-flex">
                <span v-if="!bankData.hideIndex" class="mr-2">{{ index + 1 }}.</span>
                <a href="" class="d-flex text-site-primary1 fw--700 fs--20 text-decoration-underline">
                    {{ bankData.title }}
                </a>
            </h2>

            <img class="my-2" :src="bankData.icon" alt="" width="150px" />
            <p class="fs--17 fw--400 mt-4 text-site-dark text-justify">
                <a :href="bankData.to" class="text-decoration-underline text-site-primary1">
                    {{bankData.link}}</a>
                {{ bankData.description1 }}
            </p>
            <table class="table">
                <thead>
                    <tr class="bg-site-primary1">
                        <th class="py-4 text-site-secondary1" scope="col">Pros</th>
                        <th class="py-4 text-site-secondary1" scope="col">Cons</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="text-left">
                            <ul>
                                <li v-for="(item, index) in bankData.pros" :key="index">
                                    {{ item.list }}
                                </li>
                            </ul>
                        </td>
                        <td class="text-left">
                            <ul>
                                <li v-for="(item, index) in bankData.cons" :key="index">
                                    {{ item.list }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="button chime-button px-2 py-2 fs--16 fw--600 my-5">
                <span>VISIT SITE</span>
                <span class="arrow">
                    <i class="fa-sharp fa-solid fa-arrow-right"></i>
                </span>
            </div>
            <hr class="my-5" />
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ExploreAccount",
  props: ["data"],
};
</script>
<style lang="scss" scoped>
tbody {
  background-color: #f0f0f0 !important;
  li::marker {
    color: #49a8ff;
  }
}
.button {
    span {
        text-transform: uppercase;
        @media only screen and (max-width: 767.98px) {
            text-transform: capitalize;
        }
    }
}
</style>
