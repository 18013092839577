<template>
    <b-container class="my-4">
        <b-row>
            <b-col xs="12" class="px-2 compare">
                <div class="text-left text-site-dark">
                    <h6 class="fs--20 fw--700 text-site-dark">
                        {{ compareData?.title }}
                    </h6>
                    <p class="fs--17 text-site-dark">{{ compareData?.description }}</p>
                </div>
                <div class="text-left text-site-dark">
                    <p class="fs--17 text-site-dark">{{ compareData?.featureTitle }}</p>
                    <div v-for="(obj, index) in compareData?.featureData" :key="index" class="text-left d-flex py-2">
                        <div class="simple-box mr-3 my-2"></div>
                        <div>
                            <span class="fs--16 fw--700 text-md-nowrap text-site-dark">{{ obj?.name }}</span>
                            <span class="fs--16 text-site-dark" v-html="obj?.explanation"></span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
export default {
    name: 'CompareOnlineAccount',
    props: {
        compareData: Object,
    },
};
</script>
<style lang="scss" scoped>
.compare {
    a {
        color: #49a8ff !important;
        @media only screen and (max-width: 767.98px) {
            color: black !important;
        }
    }
}
</style>
