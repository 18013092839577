<template>
  <div class="text-left text-site-dark">
    <h5 class="fs--17 fw--600">Some of the listings are powered by Bankrate</h5>
    <h5 class="fs--17 fw--600 mt-5">Bankrate Advertiser Disclosure:</h5>
    <p class="fs--17 mt-5">
      The listings that appear on this page are from companies from which this
      website receives compensation, which may impact how, where and in what
      order products appear. This table does not include all companies or all
      available products. Bankrate does not endorse or recommend any companies.
    </p>
    <h6 class="fs--20 fw--700 mt-5">Why Open an Online Savings Account?</h6>
    <p class="fs--17 mt-5">
      An
      <a href="" class="text-decoration-underline text-site-dark"
        >online savings account</a
      >
      is like a regular savings account, but with higher rates, lower fees, and
      greater convenience.
    </p>
    <p class="fs--17 mt-5">
      Banks that operate online spend less on overhead than banks with
      brick-and-mortar branches. These savings get passed on to customers in the
      form of higher rates (also known as annual percentage yield, or APY).
    </p>
    <p class="fs--17 mt-5">
      <span class="fw--700">APY</span>, or
      <span class="fw--700">annual percentage yield</span>, is the yearly return
      on a bank or investment account.
    </p>
    <p class="fs--17 mt-5">
      Online savings accounts have become more popular in recent years as
      internet-only banks have emerged as competition for traditional banks.
      These days, most online banks offer mobile apps that let users track
      savings, deposit checks, and transfer money between accounts—all from the
      comfort of their smartphone.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
