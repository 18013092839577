<template>
  <div class="custom-accordion my-4">
    <div class="">
      <div
        class="accordion-header d-flex align-items-center justify-content-between bg-site-secondary-lite px-4 py-3 d-flex"
        @click="toggleAccordion"
      >
        <div class="d-flex align-items-center">
          <div
            class="accordion-header__icon bg-site-primary d-flex align-items-center justify-content-center fw--600"
          >
            {{ idx + 1 }}
          </div>
          <h2 class="text-site-dark fw--600 accordion-header__title m-0">
            {{ item.title }}
          </h2>
        </div>

        <img
          :style="{ transform: item.expanded ? 'rotate(180deg)' : 'rotate(0)' }"
          src="../assets/arrow-down.svg"
          class="accordion-header__arrow"
        />
      </div>

      <transition name="fade">
        <div v-if="item.expanded" class="card-body">
          <TrendingListCard :item="item" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TrendingListCardVue from "./TrendingListCard.vue";
import TrendingListCard from "./TrendingListCard.vue";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    idx: String,
  },
  components: {
    TrendingListCard,
  },
  methods: {
    toggleAccordion() {
      this.$emit("toggle");
    },
  },
};
</script>

<style lang="scss" scoped>
/* Add your custom styles here */
.custom-accordion {
  border: 3px solid #f5f5f5;
  @media (max-width: 767.98px) {
    border: 0;
  }
  .accordion-header {
    cursor: pointer;
    display: flex !important;
    align-items: center !important;

    &__icon {
      width: 45px;
      height: 45px;
      color: #ffffff;
      border-radius: 50%;
      margin-right: 15px;
      font-size: 27px;
    }
    &__title {
      font-size: 20px;
    }

    &__arrow {
      transition: transform 0.3s ease; /* Add transition for smooth animation */
    }

    @media (max-width: 767.99px) {
      &__title {
        font-size: 18px;
      }
      &__icon {
        width: 40px;
        height: 40px;

        margin-right: 10px;
        font-size: 16px;
      }
    }
  }
}

.card {
  /* Add your styles for each accordion item */
  margin-bottom: 1rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.card-header {
  /* Add your styles for the accordion header */
  cursor: pointer;
  padding: 0.75rem;
  background-color: #f8f9fa;
}

.card-body {
  /* Add your styles for the accordion content */
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  @media (max-width: 767.98px) {
    padding: 1rem 0 0;
    border: 0;
  }
}
</style>
