<template>
    <b-row class="review-top mb-5 bg-white" no-gutters>
        <b-col md="5" class="bg-site-secondary1 review-top__left">
            <div class="d-flex justify-content-between align-items-center justify-md-content-start">
                <img class="review-top__logo" :src="dataReview?.logo" />

                <div class="d-md-none">
                    <div class="px-2 px-sm-3 py-2 bg-site-button text-white fs--16 fw--600 cursor-pointer">Visit Site</div>
                </div>
            </div>
            <div class="d-flex align-items-center mb-md-3 mt-1 py-2">
                <div class="fs--16 fw--600 text-site-black">Reviews {{ dataReview.review }}</div>
                <div class="ml-2">
                    <img class="review-top__review-logo" :src="dataReview?.Trustpilotlogo" />
                </div>
            </div>
            <div class="d-md-block d-none text-left">
                <h6 class="fs--18 fw--700 mt-4 mb-4 text-site-black">{{ dataReview?.title }} at a glance</h6>
            </div>
            <div class="d-md-block d-none" v-for="(obj, index) in dataReview?.data" :key="index">
                <div class="d-flex justify-content-between align-items- text-left">
                    <div>
                        <p class="fs--16 fw--600 mb-0 text-site-black">{{ obj?.name }}</p>
                        <p class="text-left fs--14 fw--400 text-site-black">
                            {{ obj?.description }}
                        </p>
                    </div>
                    <p class="fs--16 fw--600 mb-0 text-site-black">{{ obj.price }}</p>
                </div>
            </div>
        </b-col>
        <b-col md="7" class="review-top__right">
            <div class="fs--18 text-left mb-0 mb-md-4 d-none d-md-block text-site-black">
                Is
                <span class="fs--18 fw--700 text-site-black">
                    {{ dataReview?.title }}
                </span>
                the right choice for you?
            </div>
            <div class="d-md-none text-left text-site-black">
                <h6 class="fs--18 fw--700 mt-2 mb-3 text-site-black">{{ dataReview?.title }} at a glance</h6>
            </div>
            <div class="d-md-none" v-for="(obj, index) in dataReview?.data" :key="index">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="fs--16 fw--600 text-site-black">{{ obj?.name }}</div>
                    <div class="fs--16 fw--600 text-site-black">{{ obj.price }}</div>
                </div>
                <p class="text-left fs--14 fw--400 text-site-black">
                    {{ obj?.description }}
                </p>
            </div>
            <hr class="d-md-none" />
            <div v-if="dataReview?.check == false" class="fs--18 justify-content-between mb-4 d-flex d-md-none">
                <div>
                    <span class="fs--16 text-site-black">{{ dataReview?.title }} Pros & Cons</span>
                </div>
                <div @click="toggle(index, true)">
                    <span class="cursor-pointer">
                        <svg
                            viewBox="0 0 8 5"
                            class="cthH2V9o"
                            style="
                                 {
                                    width: 12px;
                                    height: 10px;
                                }
                            "
                        >
                            <path
                                fill="#1564BF"
                                d="M3.941 4.609a.68.68 0 00.47-.195L7.667 1.16A.677.677 0 007.668.2a.679.679 0 00-.96-.002L3.933 2.973 1.157.198A.678.678 0 10.2 1.16l3.254 3.254a.68.68 0 00.487.195z"
                            ></path>
                        </svg>
                    </span>
                </div>
            </div>
            <b-row class="d-none d-md-flex">
                <b-col lg="6">
                    <div class="fs--18 fw--600 text-left my-4">Pros</div>
                    <div v-for="(value, index) in dataReview?.prosData" :key="index" class="text-left d-flex my-3">
                        <div class="mr-2">
                            <svg
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 12 9"
                                class="XLjLPI2H"
                                style="
                                     {
                                        width: 12px;
                                        height: 10px;
                                    }
                                "
                            >
                                <path
                                    d="M11.077 0a.881.881 0 00-.61.274C8.272 2.492 6.274 4.68 4.14 6.866L1.443 4.689a.881.881 0 00-1.248.14.901.901 0 00.139 1.26l3.329 2.687a.882.882 0 001.186-.07c2.386-2.412 4.53-4.79 6.88-7.166a.9.9 0 00.2-.996.887.887 0 00-.852-.543z"
                                    fill="#1564BF"
                                    style=""
                                ></path>
                            </svg>
                            <!-- </div> -->
                        </div>
                        <div>
                            <span class="fs--16 text-site-black">{{ value }}</span>
                        </div>
                    </div>
                </b-col>
                <b-col lg="6">
                    <div class="fs--18 fw--600 text-left my-4">Cons</div>
                    <div>
                        <div v-for="(value, index) in dataReview?.consData" :key="index" class="text-left my-3 d-flex">
                            <div class="mr-2">
                                <svg
                                    fill="#1564BF"
                                    viewBox="0 0 11 11"
                                    style="
                                         {
                                            width: 12px;
                                            height: 10px;
                                        }
                                    "
                                >
                                    <path
                                        d="M9.893 1.117l.566-.566-.566.566zm0 .566l-.565-.566.565.566zm-.565-.566l.565.566-.565-.566zM5.505 4.94l-.565.565.565.566.566-.566-.566-.565zM1.683 1.117l.566-.566-.566.566zm-.566 0L.551.551l.566.566zm0 .566l.566-.566-.566.566zM4.94 5.505l.565.566.566-.566-.566-.565-.565.565zM1.117 9.328l.566.565-.566-.565zm.566.565l-.566-.565.566.565zm3.822-3.822l.566-.566-.566-.565-.565.565.565.566zm3.823 3.822l.565-.565-.565.565zm.565-.565l-.565.565.565-.565zM6.071 5.505l-.566-.565-.565.565.565.566.566-.566zm3.257-3.822a.4.4 0 010-.566l1.131 1.132a1.2 1.2 0 000-1.698L9.328 1.683zm.565 0a.4.4 0 01-.565 0L10.459.55a1.2 1.2 0 00-1.697 0l1.131 1.132zM6.071 5.505l3.822-3.822L8.762.55 4.94 4.374l1.13 1.131zM1.117 1.683L4.94 5.505l1.13-1.131L2.25.55 1.117 1.683zm.566 0a.4.4 0 01-.566 0L2.25.55a1.2 1.2 0 00-1.698 0l1.132 1.132zm0-.566a.4.4 0 010 .566L.55.55a1.2 1.2 0 000 1.698l1.132-1.132zM5.505 4.94L1.683 1.117.55 2.25l3.824 3.82 1.131-1.13zM1.683 9.893l3.822-3.822L4.374 4.94.55 8.762l1.132 1.131zm0-.565a.4.4 0 010 .565L.55 8.762a1.2 1.2 0 000 1.697l1.132-1.131zm-.566 0a.4.4 0 01.566 0L.55 10.459a1.2 1.2 0 001.698 0L1.117 9.328zM4.94 5.505L1.117 9.328l1.132 1.131L6.07 6.637 4.94 5.505zm4.953 3.823L6.071 5.505 4.94 6.637l3.822 3.822 1.131-1.131zm-.565 0a.4.4 0 01.565 0l-1.131 1.131a1.2 1.2 0 001.697 0L9.328 9.328zm0 .565a.4.4 0 010-.565l1.131 1.131a1.2 1.2 0 000-1.697L9.328 9.893zM5.505 6.071l3.823 3.822 1.131-1.131L6.637 4.94 5.505 6.07zm3.823-4.954L5.505 4.94l1.132 1.13 3.822-3.821-1.131-1.132z"
                                        fill="inherit"
                                    ></path>
                                </svg>
                            </div>
                            <div>
                                <span class="fs--16 text-site-black">{{ value }}</span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="dataReview?.check" class="d-md-none">
                <b-col xs="12" sm="12">
                    <div class="text-left">
                        <span class="fs--18 fw--700">Pros & Cons</span>
                    </div>
                </b-col>
                <b-col sm="6">
                    <div class="fs--18 fw--600 text-left my-4">Pros</div>
                    <div v-for="(value, index) in dataReview?.prosData" :key="index" class="text-left d-flex my-3">
                        <div>
                            <span class="mr-3">
                                <svg
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 12 9"
                                    class="XLjLPI2H"
                                    style="
                                         {
                                            width: 12px;
                                            height: 10px;
                                        }
                                    "
                                >
                                    <path
                                        d="M11.077 0a.881.881 0 00-.61.274C8.272 2.492 6.274 4.68 4.14 6.866L1.443 4.689a.881.881 0 00-1.248.14.901.901 0 00.139 1.26l3.329 2.687a.882.882 0 001.186-.07c2.386-2.412 4.53-4.79 6.88-7.166a.9.9 0 00.2-.996.887.887 0 00-.852-.543z"
                                        fill="#1564BF"
                                        style=""
                                    ></path>
                                </svg>
                            </span>
                        </div>
                        <div>
                            <span class="fs--16 text-site-black">{{ value }}</span>
                        </div>
                    </div>
                </b-col>
                <b-col sm="6">
                    <div class="fs--18 fw--600 text-left my-4 text-site-black">Cons</div>
                    <div>
                        <div v-for="(value, index) in dataReview?.consData" :key="index" class="text-left my-3 d-flex">
                            <div>
                                <span class="mr-2">
                                    <svg
                                        fill="#1564BF"
                                        viewBox="0 0 11 11"
                                        style="
                                             {
                                                width: 12px;
                                                height: 10px;
                                            }
                                        "
                                    >
                                        <path
                                            d="M9.893 1.117l.566-.566-.566.566zm0 .566l-.565-.566.565.566zm-.565-.566l.565.566-.565-.566zM5.505 4.94l-.565.565.565.566.566-.566-.566-.565zM1.683 1.117l.566-.566-.566.566zm-.566 0L.551.551l.566.566zm0 .566l.566-.566-.566.566zM4.94 5.505l.565.566.566-.566-.566-.565-.565.565zM1.117 9.328l.566.565-.566-.565zm.566.565l-.566-.565.566.565zm3.822-3.822l.566-.566-.566-.565-.565.565.565.566zm3.823 3.822l.565-.565-.565.565zm.565-.565l-.565.565.565-.565zM6.071 5.505l-.566-.565-.565.565.565.566.566-.566zm3.257-3.822a.4.4 0 010-.566l1.131 1.132a1.2 1.2 0 000-1.698L9.328 1.683zm.565 0a.4.4 0 01-.565 0L10.459.55a1.2 1.2 0 00-1.697 0l1.131 1.132zM6.071 5.505l3.822-3.822L8.762.55 4.94 4.374l1.13 1.131zM1.117 1.683L4.94 5.505l1.13-1.131L2.25.55 1.117 1.683zm.566 0a.4.4 0 01-.566 0L2.25.55a1.2 1.2 0 00-1.698 0l1.132 1.132zm0-.566a.4.4 0 010 .566L.55.55a1.2 1.2 0 000 1.698l1.132-1.132zM5.505 4.94L1.683 1.117.55 2.25l3.824 3.82 1.131-1.13zM1.683 9.893l3.822-3.822L4.374 4.94.55 8.762l1.132 1.131zm0-.565a.4.4 0 010 .565L.55 8.762a1.2 1.2 0 000 1.697l1.132-1.131zm-.566 0a.4.4 0 01.566 0L.55 10.459a1.2 1.2 0 001.698 0L1.117 9.328zM4.94 5.505L1.117 9.328l1.132 1.131L6.07 6.637 4.94 5.505zm4.953 3.823L6.071 5.505 4.94 6.637l3.822 3.822 1.131-1.131zm-.565 0a.4.4 0 01.565 0l-1.131 1.131a1.2 1.2 0 001.697 0L9.328 9.328zm0 .565a.4.4 0 010-.565l1.131 1.131a1.2 1.2 0 000-1.697L9.328 9.893zM5.505 6.071l3.823 3.822 1.131-1.131L6.637 4.94 5.505 6.07zm3.823-4.954L5.505 4.94l1.132 1.13 3.822-3.821-1.131-1.132z"
                                            fill="inherit"
                                        ></path>
                                    </svg>
                                </span>
                            </div>
                            <div>
                                <span class="fs--16 text-site-black">{{ value }}</span>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="d-flex justify-content-end px-3"></b-col>
                <div v-if="dataReview?.check == true" @click="toggle(index, false)" class="cursor-pointer">
                    <span class="text-site-blue fs--16 fw--600 mr-1">Close</span>
                    <svg
                        viewBox="0 0 8 5"
                        class="Z3S5isc7"
                        style="
                             {
                                width: 12px;
                                height: 10px;
                            }
                        "
                    >
                        <path
                            fill="#1564BF"
                            d="M3.941 4.609a.68.68 0 00.47-.195L7.667 1.16A.677.677 0 007.668.2a.679.679 0 00-.96-.002L3.933 2.973 1.157.198A.678.678 0 10.2 1.16l3.254 3.254a.68.68 0 00.487.195z"
                        ></path>
                    </svg>
                </div>
            </b-row>
            <div class="review-top__button d-none d-md-block">
                <div class="px-2 px-sm-3 py-2 bg-site-button text-white fs--16 fw--600 cursor-pointer">Visit Site</div>
            </div>
        </b-col>
    </b-row>
</template>
<script>
import '../assets/scss/index.scss';
import brandLogo from '../assets/brandlogo.jpg';
import Trustpilotlogo from '../assets/Trustpilot-logo.svg';
import '../assets/scss/index.scss';

export default {
    name: 'ReviewAccount',
    props: {
        dataReview: Object,
        index: Number,
        toggle: Function,
    },
    data() {
        return {
            logo: brandLogo,
            Trustpilotlogo: Trustpilotlogo,
            data: [
                {
                    name: 'Fees?',
                    price: '10.0',
                    description: 'No Fees at all',
                },
                {
                    name: 'Security',
                    price: '10.0',
                    description: 'FDIC insured, SSL/AES encryption and supports 2 factor authentication ',
                },
                {
                    name: 'Customer service',
                    price: '8.0',
                    description: 'Early morning, evening and weekend service with multiple communication options and a dedicated fraud team ',
                },
                {
                    name: 'Online experience',
                    price: '10.0',
                    description: 'Excellent user friendly site with transparency of rates and full online banking capabilities  ',
                },
                {
                    name: 'Mobile experience',
                    price: '10.0',
                    description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits ',
                },
            ],
            prosData: ['No minimum deposit requirement', 'High yields savings account', 'No monthly fees', 'Incredible mobile app experience'],
            consData: ['Only one physical location'],
        };
    },
    methods: {},
};
</script>

<style lang="scss"></style>
