<template>
    <div class="text-left">
        <h6 class="fs--20 fw--700 text-site-dark">Why Open an Online Checking Account?</h6>
        <p class="fs--17 fw--400 mt-3 text-site-dark">
            An
            <a href="#" class="text-site-dark text-decoration-underline">online checking account</a>
            beats a traditional checking account for the same reason as email beats the postal system and online shopping beats brick-and-mortar stores. Online checking
            accounts are based on traditional checking accounts, but with more convenience, less cost, and less hassle.
        </p>
        <p class="fs--17 fw--400 mt-5 text-site-dark">
            Online checking accounts have been around since the early days of the internet, but at first were quite limited in terms of functionality. In recent years
            online checking accounts have become more sophisticated. In fact, many online checking accounts now include a free mobile app that lets people do their
            banking from their phone.
        </p>
        <p class="fs--17 fw--400 mt-5 text-site-dark">
            These days, consumers can do virtually all their
            <a href="#" class="text-site-dark text-decoration-underline">banking online</a>
            or on their mobile banking app, from bank-to-bank transfers, to depositing checks, to scheduling bill payments. Many online banks even offer add-ons like ID
            theft insurance, roadside assistance, and retail rewards programs.
        </p>
        <hr class="my-5" />
    </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.fs--20 {
    @media only screen and (max-width: 767.98px) {
        font-size: 1.6rem !important;
    }
}
</style>
