<template>
  <div class="compare-rates mb-4">
    <div class="compare-rates-inner">
      <p class="compare-rates-heading">
        Compare mortgage loan rates to find the best APR
      </p>
      <p class="compare-rates-description">What is your home worth?</p>
      <div class="slider mb-4">
        <div
          class="slider-output d-flex justify-content-between align-items-center"
          id="slider-output"
          :style="`left: ${getLeft()}`"
        >
          <div
            role="button"
            class="slider-button d-flex d-md-none"
            @click="
              sliderValue =
                sliderValue > 50000 ? Number(sliderValue) - 10000 : sliderValue
            "
          >
            -
          </div>
          ${{ Number(sliderValue).toLocaleString() }}
          <div
            role="button"
            class="slider-button d-flex d-md-none border-0"
            @click="
              sliderValue =
                sliderValue < 3000000
                  ? Number(sliderValue) + 10000
                  : sliderValue
            "
          >
            +
          </div>
        </div>
        <input
          type="range"
          v-model="sliderValue"
          class="slider-input"
          min="50000"
          max="3000000"
          step="10000"
        />
        <div
          class="d-flex justify-content-between align-items-center py-3 text-site-greyDark"
        >
          <small class="mb-0">$50,000</small>
          <small class="mb-0">$3,000,000</small>
        </div>
      </div>
      <button
        class="compare-rates-btn bg-site-button text-white mt-md-4 mt-5 mx-auto"
      >
        Compare Rates
      </button>
      <p class="compare-rates-subDescription mt-3 mb-0">
        Checking rates will<span> not </span>affect your credit score.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliderValue: 50000,
    };
  },
  methods: {
    getLeft() {
      let sliderOutput = document.getElementById("slider-output");
      let offsetWidth = sliderOutput?.offsetWidth || 138;
      return `calc(${(
        (Number(this.sliderValue) / 3000000) *
        100
      ).toString()}% - ${offsetWidth / 2}px)`;
    },
  },
};
</script>
