<template>
  <div class="text-left">
    <hr class="my-5" />

    <h6 class="fs--20 fw--700 text-site-dark">VaroMoney Disclaimer:</h6>
    <p class="fs--17 mt-3 text-site-dark">
      1 Annual Percentage Yields (APY) are accurate as of December 1, 2022.
      Rates may change at any time without prior notice, before or after the
      account is opened. You can earn 5.00% on your first $5,000 if you meet
      these requirements: Make sure that you Varo account balances are above or
      equal to $0 at the close of the month. Receive total direct deposits of
      $1,000 or more each qualifying period. Additional balances over $5,000
      will earn 3.00% APY. The requirements must be met within the Qualifying
      Period which begins on the first calendar day of the month and ends at the
      close of business (4:25 pm MST/ 3:25 pm MDT) on the last business day of
      the month. Meet the requirements in the current month to qualify to earn
      5.00% APY for the following month. Qualifying direct deposits are
      electronic deposits of your paycheck, pension or government benefits (such
      as Social Security or unemployment) from your employer or government
      agency. Tax refunds and government stimulus payments, Person-to-Person
      payments (such as Venmo) and funds deposited using a Varo routing number
      are not considered a direct deposit. Be aware that interest is paid in
      whole cents only.
    </p>
    <p class="fs--17 mt-5 text-site-dark">
      <a href="#" class="text-site-dark text-decoration-underline"></a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
