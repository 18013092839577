<template>
    <div class="form-group">
        <div class="input-container">
            <label :class="{ active: selectedOption }">Account type</label>
            <select v-model="selectedOption" @change="handleChange">
                <option v-if="$route.path == '/compare-online-banking'" value="OnlineBanking">Online Banking</option>
                <option value="SavingAccounts">Saving Accounts</option>
                <option value="CheckingAccounts">Checking Accounts</option>
                <option value="BusinessChecking">Business Checking</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        selectedOption: String, // Receive selectedOption as a prop
    },
    methods: {
        handleChange() {
            console.log('THils', this.selectedOption);
            // Call a method in the parent component when the select value changes
            this.$emit('change', this.selectedOption);
        },
    },
};
</script>

<style scoped>
.form-group {
    margin-bottom: 20px;
}

.input-container {
    position: relative;
    height: 60px;
}

label {
    position: absolute;
    /* top: 0;
  left: 0; */
    left: 10px;
    top: 10px;
    transition: transform 0.3s ease-out, color 0.3s ease-out;
    pointer-events: none;
}

label.active {
    /* transform: translateY(-1.5em); */
    font-size: 12px;
    /* color: #3498db; */
    /* left: 10px;
  top: 10px; */
    top: 10px !important;
    left: 15px !important;
}

select {
    width: 100%;
    padding: 10px;
    padding-top: 15px;
    border: 1px solid #ccc;
    border-radius: 0px;
    margin-top: 5px;
    background-color: transparent; /* Ensure the background remains transparent */
    height: 60px;
    border: 1px solid #93a1a8;
    font-size: 16px;
    color: #171717;
    font-weight: 600;
}

select:focus-visible {
    outline: none !important;
    border: 1px solid #93a1a8 !important;
    box-shadow: none !important;
}
</style>
