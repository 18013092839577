<template>
    <div>
        <HeaderAccounts :data="headerData" />
        <b-container class="d-md-block d-none"><toggle /></b-container>
        <FilterMobile />
        <div class="bg-site-secondary-lite extraPaddingTop">
            <b-container class="">
                <b-row class="border-bottom pb-5 mb-5">
                    <b-col xl="9">
                        <div v-for="(obj, index) in choiceData" :key="index" class="mb-3">
                            <TopChoice :objChoice="obj" :index="index" :topChoice="''" />
                        </div>

                        <div class="d-xl-flex d-none pro-tip align-items-center bg-white text-left mb-3">
                            <span><img :src="eicon" class="mt-2" /></span>
                            <span class="text-site-primary fs--18 fs--600 ml-4">Pro tip:</span>
                            <span class="fs--15 ml-2">Most online banking apps allow users to deposit checks straight from their mobile device.</span>
                        </div>

                        <div v-for="(obj, index) in choiceDataPro" :key="index" class="mb-3">
                            <TopChoice :objChoice="obj" :index="index + 3" :topChoice="''" />
                        </div>
                    </b-col>

                    <b-col class="d-none d-xl-block" xl="3">
                        <sidebar-articles />
                    </b-col>
                </b-row>
                <div class="border-bottom pb-5 mb-5 smWhite">
                    <h4 class="text-left fs--20 fw--700 d-lg-block d-none">Our Top Choice</h4>
                    <div class="d-flex align-items-center d-lg-none d-block my-3">
                        <img src="../assets/TrendingList/crown.svg" />
                        <span class="mx-3 text-site-primary fs--20 fw--700">|</span>
                        <h4 class="text-left fs--20 fw--600 mb-0">Our Top Choice</h4>
                    </div>
                    <TopChoice :objChoice="choiceData[0]" :index="'01'" :topChoice="'Most Popular on Credit Grail'" />
                </div>

                <div class="pb-5">
                    <!-- <hr class="my-5 d-lg-block d-none" /> -->
                    <h4 class="text-left font-weight-bold mb-4 d-lg-block d-none">Review Our Top Online Accounts</h4>

                    <div v-for="(obj, index) in reviewOnlineAccount" :key="index" class="my-5 d-lg-block d-none">
                        <ReviewAccount :dataReview="obj" :index="index" :toggle="toggle" />
                    </div>
                    <hr class="my-5 d-lg-block d-none" />

                    <b-row no-gutters>
                        <b-col xs="12" lg="8">
                            <why-open-online-account />

                            <which-account-is-right />
                            <hr class="my-5" />
                            <CompareOnlineAccount :compareData="compareOnline" />
                            <hr class="my-5" />
                            <OurTopClient />
                            <hr class="my-5" />
                            <CompareOnlineAccount :compareData="compareData" />
                            <hr class="my-5" />
                            <USBankDisclaimer />
                            <hr class="my-5" />
                            <VaroMoney />
                            <hr class="my-5" />
                            <Chime />
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                    <br />
                </div>
            </b-container>
        </div>
    </div>
</template>

<script>
import HeaderAccounts from '../components/HeaderAccounts.vue';
import Toggle from '@/components/Toggle.vue';
import ReviewAccount from '../components/ReviewAccount.vue';
import SidebarArticles from '../components/SidebarArticles.vue';
import TopChoice from '../components/TopChoice.vue';
import brandLogo from '../assets/brandlogo.jpg';
import Trustpilotlogo from '../assets/Trustpilot-logo.svg';
import CompareOnlineAccount from '../components/CompareOnlineAccount.vue';
import eicon from '../assets/TrendingList/eicon.png';
import discover from '../assets/TrendingList/discover.png';
import upgrade from '../assets/TrendingList/upgrade.svg';
import starsm from '../assets/TrendingList/stargreen.svg';
import BMO from '../assets/TrendingList/BMO.svg';
import varo from '../assets/TrendingList/Varo.svg';
import citi from '../assets/TrendingList/citi.png';
import barclay from '../assets/TrendingList/barclays.png';
import chrime from '../assets/TrendingList/chrime.png';
import winden from '../assets/winden-Logo.svg';
import dollar from '../assets/dollar.svg';
import wallet from '../assets/wallet.svg';
import star from '../assets/TrendingList/star.svg';
import rewardIcon from '../assets/Icon3.svg';
import FilterMobile from '../components/global/FilterMobile.vue';
import WhyOpenOnlineAccount from '../components/WhyOpenOnlineAccount.vue';
import WhichAccountIsRight from '../components/WhichAccountIsRight.vue';
import OurTopClient from '../components/OurTopClient.vue';
import USBankDisclaimer from '../components/USBankDisclaimer.vue';
import VaroMoney from '../components/VaroMoney.vue';
import Chime from '../components/Chime.vue';

export default {
    name: 'OnlineBanking',
    components: {
        HeaderAccounts,
        Toggle,
        TopChoice,
        SidebarArticles,
        ReviewAccount,
        CompareOnlineAccount,
        FilterMobile,
        WhyOpenOnlineAccount,
        WhichAccountIsRight,
        OurTopClient,
        USBankDisclaimer,
        VaroMoney,
        Chime,
    },
    data() {
        return {
            headerData: {
                heading: 'Online Banking Accounts',
                subHeading: 'Stay on top of your finances',
                description: 'Find the best online bank account to easily manage your money. Compare top online accounts with exclusive deals and 24/7 access',
            },
            eicon: eicon,
            reviewOnlineAccount: [
                {
                    review: '2,597',
                    logo: winden,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No Fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '6.0',
                            description: 'Customer service during extended business hours',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent user friendly site with transparency of rates and full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['No starting or maintenance fees on Standard account', 'Automatically set aside money for taxes', 'Get your direct deposit early'],
                    consData: ['Limited withdrawal/deposit opportunities'],
                    check: false,
                    title: 'Winden',
                },
                {
                    review: '234',
                    logo: discover,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '10.0',
                            description: '24/7 service with multiple communication options, dedicated fraud team',
                        },
                        {
                            name: 'Online experience',
                            price: '8.0',
                            description: 'User friendly site with full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['1% cash back on debit purchases. See website for details', 'No fees', 'Comprehensive fraud protection'],
                    consData: ['Only one physical branch location', 'No interest paid on the checking account'],
                    check: false,
                    title: 'Discover Bank Cashback Debit Checking',
                },

                {
                    review: '39,707',
                    logo: upgrade,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No monthly fees',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '8.0',
                            description: 'Early morning, evening and weekend service with multiple communication options and a dedicated fraud team',
                        },
                        {
                            name: 'Online experience',
                            price: '8.0',
                            description: 'User friendly site with full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['No monthly or ATM fees²', 'Up to 2% cash back on debit card purchases¹', 'No minimum deposit required'],
                    consData: ['No cash deposits', 'Limited online accounting dashboard'],
                    check: false,
                    title: 'Upgrade Rewards Checking Plus',
                },
            ],
            choiceData: [
                {
                    rewardIcon: rewardIcon,
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: star,
                    iconsm: starsm,
                    logo: winden,
                    reviewValue: 9.8,
                    dollar: dollar,
                    siteView: '1,271',
                    earning: '60K+ Fee-Free ATMs˜',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Winden',
                        subtitle: 'Banking designed for entrepreneurs',
                        anualPercntage: 'Best for new and small businesses',
                        list: ['Free Wire and ACH transfers', 'Earn unlimited 1% cash-back on all purchases', 'No minimum deposit required'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: wallet,
                    logo: discover,
                    reviewValue: 4.2,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',

                    topChoiceContent: {
                        title: 'Discover Bank Cashback Debit Checking',
                        subtitle: 'Zero fees and cashback rewards',
                        anualPercntage: 'Apply online in minutes',
                        list: [
                            '1% cash back on debit purchases. See website for details',
                            'No. Fees. Period.',
                            'Get paid early with direct deposit',
                            'Fee-free overdraft protection transfer service',
                        ],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: upgrade,
                    reviewValue: 4.2,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',

                    topChoiceContent: {
                        title: 'Upgrade Rewards Checking Plus',
                        subtitle: 'Get more value with a better banking experience',
                        anualPercntage: '',
                        list: ['Earn up to 2% cashback on debit card purchases', 'No minimum balances or account fees', 'Free mobile app for Android and iOS devices'],
                    },
                },
            ],

            choiceDataPro: [
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: varo,
                    reviewValue: 8.6,
                    dollar: '',
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',

                    topChoiceContent: {
                        title: 'Varo Bank',
                        subtitle: 'Zero monthly fees for checking & savings',
                        anualPercntage: '',
                        list: ['Live customer service available all week', 'Automatic savings options available', 'Earn 3.00% APY & qualify to earn up to 5.00% APY'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: barclay,
                    reviewValue: 7.8,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',

                    topChoiceContent: {
                        title: 'Barclays Online Savings',
                        subtitle: 'Premium bank with online services',
                        anualPercntage: '',
                        list: ['Virtual tools to help you save', 'No monthly maintenance fees', 'Earn 4.35% APY on savings account'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: citi,
                    reviewValue: 9.0,
                    earning: null,
                    siteView: null,
                    buttonText: 'View  Details',
                    buttonTextsm: 'View  Details',

                    topChoiceContent: {
                        title: 'Citi® Priority Relationship Tier',
                        subtitle: 'Citi Mobile® App available to make banking easy',
                        anualPercntage: '',
                        list: [
                            'Earn up to $2,000 cash bonus when you open a new eligible checking account in the Citi Priority Relationship Tier',
                            'Access cash at 70,000+ Fee-Free ATMs in the U.S.',
                            'New To Relationship customers can choose their Relationship Tier',
                        ],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: BMO,
                    reviewValue: 8.4,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',

                    topChoiceContent: {
                        title: 'BMO',
                        subtitle: 'Make real financial progress with digital tools',
                        anualPercntage: '',
                        list: [
                            'New accounts qualify for up to $600 cash bonus, terms apply',
                            'Open an account online in 5 minutes',
                            'Access 40,000+ fee-free ATMs across the U.S.',
                        ],
                    },
                },
            ],

            compareData: {
                title: 'What Services do Online Checking Accounts Offer?',
                description:
                    'Online checking accounts offer all the same functions as traditional checking accounts—and more. Online checking accounts have revolutionized banking, allowing users to do everything they would have previously done in-branch from the comfort of their own home, or from on the go.',
                featureTitle: 'Here are some of the features usually offered with an online checking account:',
                featureData: [
                    {
                        name: 'Domestic and international wire transfers -',
                        explanation: '<span>Send and receive money between your account and another person’s account.</span>',
                    },
                    {
                        name: 'Transfer money between accounts -',
                        explanation: `<span>Transfer money between your own checking and <a href="/">savings accounts</a> .</span>`,
                    },
                    {
                        name: 'Multiple account integration -',
                        explanation: '<span> Keep your accounts organized and easy to access.</span>',
                    },
                    {
                        name: 'Bill payment - ',
                        explanation: '<span>Stay on top of your bills and set reminders so you never miss a beat.</span>',
                    },
                    {
                        name: 'Digital check deposit -',
                        explanation: '<span>Instantly scan and deposit a check with your smartphone and get access to the funds straight away. </span>',
                    },
                    {
                        name: 'Digital/downloadable account statements -',
                        explanation: '<span>Great for managing your budget, and for instantly sending records to your accountant.</span>',
                    },
                    {
                        name: 'Mobile banking app -',
                        explanation: '<span> Manage your accounts from your mobile device.</span>',
                    },
                    {
                        name: 'Mobile payments -',
                        explanation: '<span>Make purchases straight from your account with Google Pay, Apple Pay, or Samsung Pay.</span>',
                    },
                    {
                        name: 'Sophisticated security and encryption -',
                        explanation: '<span>Keep your data safe with multi-factor authentication and extra-strong encryption.</span>',
                    },
                    {
                        name: 'FDIC Insurance -',
                        explanation: '<span>Deposits insured up to at least $250,000 (and sometimes as high as $1.5 million, depending on the bank).</span>',
                    },
                    {
                        name: 'SMS / email alerts - ',
                        explanation: '<span>Notifications whenever any unusual or suspicious activity occurs in relation to your bank account.</span>',
                    },
                    {
                        name: 'ATMs -',
                        explanation:
                            '<span> Withdraw money from thousands of ATMs in the US and around the world, and get reimbursements from third-party ATM withdrawals.</span>',
                    },
                    {
                        name: 'Credit card rewards -',
                        explanation: '<span>Get points for making purchases with your account-linked credit card</span>',
                    },
                ],
            },
            compareOnline: {
                title: 'How to Compare Online Checking Accounts',
                description:
                    'No 2 checking accounts are equal, so it’s worth doing a comparison shop before settling on an online bank. The following is a checklist of things to focus on when comparing online checking accounts.',
                featureTitle: '',
                featureData: [
                    {
                        name: 'Monthly Fee:',
                        explanation: `<span>Some online checking accounts come with no monthly fees while others come with a flat monthly fee (typically ranging from $1 to $50). Typically, banks agree to waive the monthly fee if the customer keeps their balance above a minimum amount (e.g. $1,000 or $10,000). Always ask what you’re getting for your fee. For a basic checking account, there shouldn’t be any fee. For an account that’s packed with features, a fee is considered acceptable.<a href=""> Chime*</a> an award-winning financial app and debit card, offer overdraft protection up to $200 without fees on top of zero monthly, transaction, and foreign fees. *Chime is a financial technology company, not a bank. Banking services provided by, and debit card issued by, The Bancorp Bank or Stride Bank, N.A.; Members FDIC.</span>`,
                    },
                    {
                        name: 'Additional Fees:',
                        explanation: `<span>Online banks always charge a fee for international transfers and some also charge for domestic transfers. Most online banks offer free ATM withdrawals from their own ATMs and reimbursements from third-party ATMs.<a href=""> Varo </a>has no-fee ATM withdraws from over 55,000 Allpoint® ATMs, which are conveniently placed in stores like Walgreens, CVS, Target, and more.</span>`,
                    },
                    {
                        name: 'Customer service: ',
                        explanation: `<span>Some online banks have physical branches, while others operate exclusively online. Some offer personalized customer service (usually for a monthly fee) while others make you wait on the phone with everyone else. Some offer live chat straight from your online or <a href="mobile bank account,"></a> others don’t. Many online banks offer round-the-clock customer service. </span>`,
                    },
                    {
                        name: 'Savings accounts:',
                        explanation: `<span>Some banks only offer checking accounts. Others offer a selection of checking accounts,<a href="">savings accounts</a> , and certificates of deposit. Better yet, some banks offer hybrid checking-savings accounts that let the customer simultaneously have everyday access to their account and save money.</span>`,
                    },

                    {
                        name: 'Credit or debit cards:',
                        explanation: `<span>Like regular checking accounts, online checking accounts usually come with the option of linking a credit or debit card to the account. Online banks are increasingly offering customers a choice of credit card plans, each with a different rate and different set of features and benefits.</span>`,
                    },

                    {
                        name: 'Loans:',
                        explanation: `Another benefit of <a href="">opening an online checking account</a>  is it can give you easy access to loans. Depending on the bank, there may be personal loans, home loans, auto loans, and many other types of lending products. Online loans offer the same benefits as online checking accounts: greater convenience, lower fees, and quicker applications.`,
                    },
                ],
            },
        };
    },
    methods: {
        toggle(id, value) {
            console.log('id,value', id, value);
            this.reviewOnlineAccount[id].check = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.extraPaddingTop {
    padding-top: 60px;
    @media (max-width: 991.98px) {
        padding-top: 0px;
    }
}
</style>
