<template>
    <div>
        <div class="py-1 text-left fs--14 fw--400 border-bottom" v-if="$route.path != '/'">
            <b-container>
                <span class="topbar">
                    <p class="topbar-description text-dark m-0">
                        The listings featured on this site are from companies from which this site receives compensation. This may influence where how, and in what order
                        such listings appear on this site
                    </p>
                </span>
            </b-container>
            <div class="header position-relative text-right d-md-none d-block mr-3">
                <b-link id="button-2" class="text-site-dark header__link fs--14 mt-2">Advertising Disclosure</b-link>

                <b-tooltip target="button-2" custom-class="add" placement="bottomleft" triggers="hover">
                    This site is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation
                    from companies that appear on the site, which may impact the location and order in which brands (and/or their products) are presented, and may also
                    impact the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all providers on the market. Except
                    as expressly set forth in our
                    <b-link class="fw--700 text-decoration-underline text-site-dark">Terms of Use</b-link>
                    , all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which
                    appears on this site is subject to change at any time
                </b-tooltip>
            </div>
        </div>
        <b-navbar toggleable="lg" type="dark" class="border-bottom">
            <b-container>
                <b-navbar-brand class="" href="/"><img src="../../assets/creditgrail-logo.svg" alt="" /></b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->
                    <b-navbar-nav class="ml-auto pt-lg-0 pt-5">
                        <b-nav-item-dropdown text="Compare" right class="px-3">
                            <b-dropdown-item href="/compare-savings-accounts" active-class="active">Saving Accounts</b-dropdown-item>
                            <b-dropdown-item href="/compare-checking-accounts" active-class="active">Checking Accounts</b-dropdown-item>
                            <b-dropdown-item href="/compare-business-checking" active-class="active">Business Checking</b-dropdown-item>
                        </b-nav-item-dropdown>
                        <b-nav-item exact class="px-3" href="#" active-class="active">Reviews</b-nav-item>
                        <b-nav-item exact class="pl-3" href="/compare-savings-accounts" exact-active-class="active">Articles</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-container>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
};
</script>

<style></style>
