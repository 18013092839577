<template>
  <div>
    <div
      role="button"
      class="d-flex align-items-center"
      @click="isOpen = !isOpen"
    >
      <img :src="icon" class="best-money-dropdown-icon" />
      <h5 class="m-0 best-money-dropdown-heading text-site-black">
        {{ title }}
      </h5>
      <i class="fa fa-angle-down ml-auto"></i>
    </div>
    <div v-if="isOpen">
      <small class="text-left best-money-dropdown-paragraph d-block mt-2">
        {{ description }}
      </small>
    </div>
    <hr v-if="getBottomLine" />
  </div>
</template>

<script>
export default {
  props: ["title", "description", "icon", "bottomLine"],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    getBottomLine() {
      return this.bottomLine === undefined ? true : this.bottomLine;
    },
  },
};
</script>

<style></style>
