<template>
  <div class="text-left text-site-darkmb-5">
    <h6 class="fs--20 fw--700 mb-3 mb-lg-0">Which account is right for you?</h6>
    <table class="table mt-1 text-center which-account">
      <thead class="bg-site-primary1 text-white">
        <tr>
          <td>Bank</td>
          <td>Monthly Fees</td>
          <td>Earns Interest</td>
          <td>Explore</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <img src="../assets/Relay.webp" class="bank-img" />
          </td>
          <td>None</td>
          <td>Yes</td>
          <td>
            <div class="button bg-site-primary1 px-2 py-2 fs--16 fw--600">
              <span>Visit Site</span>
              <span class="arrow">
                <i class="fa-sharp fa-solid fa-arrow-right"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <img src="../assets/BluevineLogo.webp" class="bank-img" />
          </td>
          <td>None</td>
          <td>Yes</td>
          <td>
            <div class="button bg-site-primary1 px-2 py-2 fs--16 fw--600">
              <span>Visit Site</span>
              <span class="arrow">
                <i class="fa-sharp fa-solid fa-arrow-right"></i>
              </span>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <img src="../assets/lili_logo.svg" class="bank-img" />
          </td>
          <td>None</td>
          <td>Yes</td>
          <td>
            <div class="button bg-site-primary1 px-2 py-2 fs--16 fw--600">
              <span>Visit Site</span>
              <span class="arrow">
                <i class="fa-sharp fa-solid fa-arrow-right"></i>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "BusinessCheckingTable",
};
</script>

<style lang="scss" scoped>
.buttonTrans {
  position: relative;

  // padding: 10px;
  width: -webkit-fill-available !important;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
  display: inline-flex; /* Display as flex to handle children */
  align-items: center; /* Center the arrow vertically with the text */
}
.arrow {
  position: absolute;
  right: 7%;
  margin-left: 5px; /* Adjust the distance between text and arrow */
  display: none;
  transition: display 0.2s ease; /* Apply transition to opacity property only */
}

.buttonTrans:hover .arrow {
  display: block; /* Show the arrow with a fade-in effect */
  animation: myAnim 0.2s ease 0s 1 normal none;
  @media (max-width: 767.98px) {
    display: none !important;
  }
}

.buttonTrans:hover {
  // transform: translateX(-10%); /* Move the text to the left */
}
@keyframes myAnim {
  0% {
    transform: scale(0.5);
    transform-origin: 100% 50%;
  }

  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}

.bank-img {
  max-width: 120px;
  @media only screen and (max-width: 767.98px) {
    max-width: 100px;
  }
}
table {
  max-width: 100%;
}
td {
  @media only screen and (max-width: 767.98px) {
    font-size: 15px;
  }
}
</style>
