<template>
  <div class="text-left text-site-dark pb-5">
    <h3 class="fs--20 fw--700">Disclaimers</h3>
    <h5 class="fs--17 fw--600 mt-3">
      Discover High Yield Online Savings Account:
    </h5>
    <p class="fs--17 mt-5">
      To get your $150 or $200 Bonus: What to do: Apply for your first Discover
      Online Savings Account, online, in the Discover App or by phone. Enter
      Offer Code NI923 when applying. Deposit into your account a total of at
      least $15,000 to earn a $150 Bonus or deposit a total of at least $25,000
      to earn a $200 Bonus. Deposit must be posted to account within 30 days of
      account open date. Maximum bonus eligibility is $200.
    </p>

    <p class="fs--17 mt-5">
      What to know: Offer not valid for existing or prior Discover savings
      customers or existing or prior customers with savings accounts that are
      co-branded, or affinity accounts provided by Discover. Eligibility is
      based on primary account owner. Account must be open when bonus is
      credited. Bonus will be credited to the account within 60 days of the
      account qualifying for the bonus. Bonus is interest and subject to
      reporting on Form 1099-INT. Offer ends 12/14/2023, 11:59 PM ET. Offer may
      be modified or withdrawn without notice.
    </p>
    <h5 class="fs--17 fw--600 mt-5">SoFi Checking and Savings:</h5>
    <p class="fs--17 mt-5">
      * SoFi members with Direct Deposit or $5,000 or more in Qualifying
      Deposits during the 30-Day Evaluation Period can earn 4.60% annual
      percentage yield (APY) on savings balances (including Vaults) and 0.50%
      APY on checking balances. There is no minimum Direct Deposit amount
      required to qualify for the stated interest rate. Members without either
      Direct Deposit or Qualifying Deposits, during the 30-Day Evaluation Period
      will earn 1.20% APY on savings balances (including Vaults) and 0.50% APY
      on checking balances. Interest rates are variable and subject to change at
      any time. These rates are current as of 8/9/2023. There is no minimum
      balance requirement. Additional information can be found at
      <a href="" class="text-site-dark text-decoration-underline"
        >http://www.sofi.com/legal/banking-rate-sheet</a
      >.
    </p>
    <h5 class="fs--17 fw--600 mt-5">Customers Bank with Raisin</h5>
    <p class="fs--17 mt-5">
      *APY is accurate as of November 9, 2023. This is a variable rate account,
      Interest rate and APY may change after initial deposit. Minimum initial
      deposit to open account and earn interest is $1.00.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
