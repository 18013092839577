<template>
  <b-container class="py-4 trending-list">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h2 class="text-site-dark fw--600 trending-list__heading m-0">
        Credit Grail Trending List
      </h2>
      <div
        class="d-md-flex d-none text-site-button fw--600 trending-list__btn"
        @click="collapseAll"
      >
        Collapse all
        <img
          src="../assets/TrendingList/doublearrow.svg"
          width="20px"
          height="20px"
          class="ml-1"
        />
      </div>
    </div>
    <CustomAccordion
      v-for="(item, index) in accordionItems"
      :key="index"
      :item="item"
      :idx="index"
      @toggle="handleToggle(index)"
    />
  </b-container>
</template>
<script>
import "../assets/scss/index.scss";
import CustomAccordion from "./CustomAccordion.vue";
import quotewizard from "../assets/TrendingList/quotewizard.svg";
import quicken from "../assets/TrendingList/quicken.svg";
import credible from "../assets/TrendingList/credible.svg";
import ladder from "../assets/TrendingList/ladder.svg";
import lemonade from "../assets/TrendingList/lemonade.svg";

export default {
  name: "TrendingList",
  components: {
    CustomAccordion,
  },

  data() {
    return {
      accordionItems: [
        {
          title: "Car Insurance",
          description1: "Best Car Insurance Quotes of 2023",
          description2: "Drive with confidence knowing you’re covered",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/CarInsuranceTrnedyImage.20230319225226.jpg",
          review: {
            title: "Quote Wizard",
            date: "Jan, 2023",
            icon: quotewizard,
          },
          subItems: [
            {
              title: "11 strategies to cut car insurance costs",
              name: "Katy Ward",
              date: "Mar, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/12WaystoLowerCarInsuranceCostthumbnail.20221221224407.jpg",
            },
            {
              title: "Lower Monthly Car Insurance Payments",
              name: "Michael Graw",
              date: "Feb, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/CIHowtoLowerMonthlyPaymentthumbnail.20221221111735.jpg",
            },
          ],
        },

        {
          title: "Home Loans",
          description1: "Secure & Efficient Mortgage Lenders November 2023",
          description2: "Mortgage rate comparison  made simple ",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/happy.20230117090505.jpg",
          review: {
            title: "Quicken Loans",
            date: "Nov, 2023",
            icon: quicken,
          },
          subItems: [
            {
              title: "Mortgage Rates Forecast for 2023",
              name: "Michael Graw",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/iStock-1239849888.20221221160620.jpg",
            },
            {
              title: "First-Time Homebuyer Financial Assistance 101",
              name: "Michael Graw",
              date: "Apr, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/thumbnailfirst.20221221073414.jpg",
            },
          ],
        },
        {
          title: "Personal Loans",
          description1: "Best Personal Loan Lenders 2023",
          description2: "Get the cash you need, fast",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/new.20230117122309.jpg",
          review: {
            title: "Credible",
            date: "Aug, 2023",
            icon: credible,
          },
          subItems: [
            {
              title: "Do Personal Loans Hurt Credit Scores?",
              name: "Michael Graw",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/unnamed12.20221227214119.jpg",
            },
            {
              title: "Why Get a Personal Loan?",
              name: "Michael Graw",
              date: "Feb, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/unnamed15.20230117155023.jpg",
            },
          ],
        },

        {
          title: "Life Insurance",
          description1: "Secure Your Loved Ones' Futures",
          description2: "Best Term Life Insurance Companies of 2023",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/life.20230117122835.jpg",
          review: {
            title: "Ladder",
            date: "Apr, 2023",
            icon: ladder,
          },
          subItems: [
            {
              title: "Unique Life Insurance Perks",
              name: "Emma Street",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/PerksofLifeInsurance.20221222101444.jpg",
            },
            {
              title: "What is 20-Year Term Life Insurance?",
              name: "Danielle Greving",
              date: "jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/Whatis20-yeartermlifeinsurancethumb.20230118121506.jpg",
            },
          ],
        },
        {
          title: "Pet Insurance ",
          description1: "Best Pet Insurance Providers of 2023",
          description2: "Get your bet bills covered",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/petin.20230117124613.jpg",
          review: {
            title: "Spot Pet Insurance",
            date: "Aug, 2023",
            icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/products/uploads/photo/Spot%2520-%2520new%2520logo.20210922104107.png",
          },
          subItems: [
            {
              title: "How Much Does a Vet Visit Cost?",
              name: "Danielle Greving",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/HowMuchDoesaVetVisitCostthumbnail.20221221082718.jpg",
            },
            {
              title: "How breed impacts dog insurance costs",
              name: "Catherine Miller",
              date: "Mar, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/Mostexpensivedogbreedstoinsurethumbnail.20221221091902.jpg",
            },
          ],
        },
        {
          title: "Online Banking",
          description1: "Best Online Banking Accounts November 2023",
          description2: "Stay on top of your finances",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/ob.20230117115925.jpg",
          review: {
            title: "Chime®",
            date: "Oct, 2023",
            icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/products/uploads/photo/180x60%2520Chime%2520Logo.20210126165647.png",
          },
          subItems: [
            {
              title: "12 ways to Boost Your Savings ",
              name: "Katy Ward",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/12tipsthumb.20230117124240.jpg",
            },
            {
              title: "The Scoop on Overdraft Protection",
              name: "Danielle Greving",
              date: "Ape, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/thumbovredraft.20230117124434.jpg",
            },
          ],
        },

        {
          title: "Student Loans Refinance",
          description1: "Best Student Loan Refinance Companies November 2023",
          description2: "How low can your rates be?",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/slr.20230117192437.jpg",
          review: {
            title: "Splash Financial ",
            date: "Apr, 2023",
            icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/products/uploads/photo/big-Splash_Logo_Black_72dpi_Logo1.20221216170011.png",
          },
          subItems: [
            {
              title: "When to Refinance Student Loans",
              name: "Danielle Greving",
              date: "Feb, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/unnamed8.20221227174051.jpg",
            },
            {
              title: "Refinancing or Consolidation?",
              name: "Danielle Greving",
              date: "jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/iStock-465727260image1.20221228095325.jpg",
            },
          ],
        },

        {
          title: "Home Insurance",
          description1: "Best Homeowners Insurance of 2023",
          description2: "Cover your hove & everything in it",
          date: "Nov, 2023",
          expanded: true,
          mainImage:
            "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/foundation-entities/uploads/photo/HI.20230117193036.jpg",
          review: {
            title: "Lemonade",
            date: "jan, 2023",
            icon: lemonade,
          },
          subItems: [
            {
              title: "Switching Home Insurance 101",
              name: "Danielle Greving",
              date: "Jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/HowtoSwitchHomeownersInsurancethumb.20230117185756.jpg",
            },
            {
              title: "Renters Insurance: What does it Cover?",
              name: "Danielle Greving",
              date: "jan, 2023",
              icon: "https://images.bestmoney.com/image/fetch/f_auto,q_auto/https://images.bestmoney.com/f_auto%2Cq_auto/v1/production/articles/uploads/photo/WhatDoesRentersInsuranceCoverthumb.20230118134919.jpg",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleToggle(index) {
      this.$set(this.accordionItems, index, {
        ...this.accordionItems[index],
        expanded: !this.accordionItems[index].expanded,
      });
    },
    collapseAll() {
      this.accordionItems.forEach((item, index) => {
        this.$set(this.accordionItems, index, {
          ...item,
          expanded: false,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  .container {
    max-width: 540px !important;
  }
}
.trending-list {
  &__heading {
    font-size: 22px;
    text-align: start;
  }
  &__btn {
    font-size: 16px;
    cursor: pointer;
  }

  @media (max-width: 767.99px) {
    &__heading {
      font-size: 20px;
    }
    &__btn {
      font-size: 14px;
    }
  }
}
</style>
