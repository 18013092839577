<template>
  <div class="rating-card bg-site-secondary">
    <div class="rating-card__rating">
      <i class="fa fa-star mr-1"></i> {{ item?.ratingText }}
    </div>
    <div class="d-flex align-items-center px-4 py-3">
      <img :src="item.icon" alt="Financial Product Icon" class="mr-3" />
      <div class="d-flex flex-column align-items-start pl-3">
        <p class="m-0 rating-card__title text-site-dark fw--700">
          {{ item?.title }}
        </p>
        <p class="m-0 rating-card__description text-site-dark mt-1">
          {{ item?.description }}
        </p>
        <div
          class="d-flex align-items-center m-0 rating-card__services text-site-dark mt-1 fw-600"
        >
          <span
            v-for="(service, index) in item.services"
            :key="index"
            class="rating-card__link"
          >
            {{ service.name }}
            <template v-if="index !== item.services.length - 1">
              <span class="mr-1 text-site-dark separator">|</span>
            </template>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "../../assets/scss/index.scss";

export default {
  name: "FinancialRatingCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.separator {
  user-select: none !important;
  cursor: default !important;
}
</style>
