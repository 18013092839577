<template>
    <div>
        <hr class="my-5" />
        <h2 class="fs--20 fw--700 text-justify text-site-dark">
            {{ data.header }}
        </h2>
        <hr class="my-5" />
        <a href="" class="d-flex text-site-primary1 fw--700 fs--20 text-decoration-underline">
            {{ data.title }}
        </a>
        <img :src="data.icon" alt="" />
        <p class="fs--17 fw--400 mt-4 text-site-dark text-justify">
            {{ data.description1 }}
        </p>
        <p class="fs--17 fw--400 mt-4 text-site-dark text-justify">
            <em>
                {{ data.description2 }}
            </em>
        </p>
        <table class="table">
            <thead>
                <tr class="bg-site-primary1">
                    <th class="py-4 text-site-secondary1" scope="col">Pros</th>
                    <!-- <th></th> -->
                    <th class="py-4 text-site-secondary1" scope="col">Cons</th>

                    <!-- <th></th> -->
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">
                        <ul>
                            <li v-for="(item, index) in data.pros" :key="index">
                                {{ item.list }}
                            </li>
                        </ul>
                    </td>
                    <td class="text-left">
                        <ul>
                            <li v-for="(item, index) in data.cons" :key="index">
                                {{ item.list }}
                            </li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="button chime-button px-2 py-2 fs--16 fw--600 my-5">
            <span>VISIT SITE</span>
            <span class="arrow">
                <i class="fa-sharp fa-solid fa-arrow-right"></i>
            </span>
        </div>
        <hr class="my-5" />
    </div>
</template>
<script>
export default {
    props: ['data'],
};
</script>
<style lang="scss" scoped>
tbody {
    background-color: #f0f0f0 !important;
    li::marker {
        color: #49a8ff;
    }
}
.button {
    span {
        text-transform: uppercase;
        @media only screen and (max-width: 767.98px) {
            text-transform: capitalize;
        }
    }
}
</style>
