<template>
    <div>
        <BusinessHeader />

        <b-container class="d-lg-block d-none">
            <p class="text-left fs--17 fw--400 mt-5">Explore more business checking accounts</p>
            <toggle />
        </b-container>
        <FilterMobile />

        <div class="bg-site-secondary-lite extraPaddingTop">
            <b-container class="">
                <b-row class="border-bottom pb-5 mb-5">
                    <b-col xl="9">
                        <div v-for="(obj, index) in choiceData" :key="index" class="mb-3">
                            <TopChoice :objChoice="obj" :index="index" :topChoice="''" />
                        </div>
                    </b-col>

                    <b-col class="d-none d-xl-block" xl="3">
                        <sidebar-articles />
                    </b-col>
                </b-row>
                <div class="border-bottom pb-5 mb-5 smWhite">
                    <h4 class="text-left fs--20 fw--700 d-lg-block d-none">Our Top Choice</h4>
                    <div class="d-flex align-items-center d-lg-none d-block my-3">
                        <img src="../assets/TrendingList/crown.svg" />
                        <span class="mx-3 text-site-primary fs--20 fw--700">|</span>
                        <h4 class="text-left fs--20 fw--600 mb-0">Our Top Choice</h4>
                    </div>
                    <TopChoice :objChoice="choiceData[0]" :index="'01'" :topChoice="'Most Popular on Credit Grail'" />
                </div>

                <div class="d-md-block d-none">
                    <h4 class="text-left font-weight-bold mb-4">Review Our Top Online Accounts</h4>

                    <div v-for="(obj, index) in reviewOnlineAccount" :key="index" class="my-5">
                        <ReviewAccount :dataReview="obj" :index="index" :toggle="toggle" />
                    </div>
                </div>
                <hr class="my-5 d-md-block d-none" />

                <div class="bottom-details">
                    <b-row no-gutters>
                        <b-col xs="12" lg="8">
                            <div v-for="(data, index) in choosingBusinessChecking" :key="index">
                                <BusinessCheckingDetails :data="data" />
                            </div>
                            <BusinessCheckingTable />
                            <ExploreAccount :data="prosCons" />
                            <Disclaimer />
                        </b-col>
                    </b-row>
                </div>
            </b-container>
        </div>
    </div>
</template>

<script>
import BusinessHeader from '../components/BusinessHeader.vue';
import Toggle from '../components/Toggle.vue';
import ReviewAccount from '../components/ReviewAccount.vue';
import SidebarArticles from '../components/SidebarArticles.vue';
import TopChoice from '../components/TopChoice.vue';
import brandLogo from '../assets/brandlogo.jpg';
import wallet from '../assets/wallet.svg';
import discover from '../assets/TrendingList/discover.png';
import upgrade from '../assets/TrendingList/upgrade.svg';
import bluevine from '../assets/TrendingList/bluevine.png';
import found from '../assets/TrendingList/found.png';
import grasshoper from '../assets/TrendingList/grasshoper.png';
import axos from '../assets/TrendingList/axos.svg';
import lili from '../assets/TrendingList/lili.svg';
import winden from '../assets/winden-Logo.svg';
import mercury from '../assets/TrendingList/mercury.png';
import novo from '../assets/TrendingList/novo.svg';
import relay from '../assets/TrendingList/relay.png';
import Trustpilotlogo from '../assets/Trustpilot-logo.svg';
import dollar from '../assets/dollar.svg';
import star from '../assets/TrendingList/star.svg';
import starsm from '../assets/TrendingList/stargreen.svg';
import rewardIcon from '../assets/Icon3.svg';
import FilterMobile from '../components/global/FilterMobile.vue';
import BusinessCheckingDetails from '../components/BusinessCheckingDetails.vue';
import BusinessCheckingTable from '../components/BusinessCheckingTable.vue';
import ExploreAccount from '../components/ExploreAccount.vue';
import chrime from '../assets/TrendingList/chrime.png';
import lili_logo from '../assets/lili_logo.svg';
import Relay from '../assets/Relay.webp';
import BluevineLogo from '../assets/BluevineLogo.webp';
import Disclaimer from '../components/Disclaimer.vue';

export default {
    name: 'BusinessChecking',
    components: {
        BusinessHeader,
        Toggle,
        TopChoice,
        SidebarArticles,
        ReviewAccount,
        FilterMobile,
        BusinessCheckingDetails,
        ExploreAccount,
        BusinessCheckingTable,
        Disclaimer,
    },
    data() {
        return {
            isDrawerOpen: false,

            reviewOnlineAccount: [
                {
                    review: '2,597',
                    logo: winden,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No Fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '6.0',
                            description: 'Customer service during extended business hours',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent user friendly site with transparency of rates and full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['No starting or maintenance fees on Standard account', 'Automatically set aside money for taxes', 'Get your direct deposit early'],
                    consData: ['Limited withdrawal/deposit opportunities'],
                    check: false,
                    title: 'Winden',
                },

                {
                    review: '1,241',
                    logo: relay,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No Fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '8.0',
                            description: 'Customer service via phone 9-5 on weekdays, email support 7 days a week',
                        },
                        {
                            name: 'Online experience',
                            price: '8.0',
                            description: 'Excellent user friendly site with transparency of rates and full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: [
                        ' Order up to 50 physical or virtual debit Mastercards/account',
                        'Sync and import transaction data from QuickBooks and Xero',
                        'No checking account, minimum balance, ATM, or overdraft fees',
                    ],
                    consData: ['No 24/7 customer support', 'No interest earned on deposits'],
                    check: false,
                    title: 'Relay',
                },

                {
                    review: '7,566',
                    logo: bluevine,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'Straightforward structure with no monthly fees',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured and SSL/AES encrypted account',
                        },
                        {
                            name: 'Customer service',
                            price: '8.0',
                            description: 'Early morning and evening service with multiple communication options and a dedicated fraud team',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent and user-friendly site with Business Checking, Line of Credit, and Bill Pay',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with easy digital check deposit',
                        },
                    ],
                    prosData: ['No monthly fees', 'Free standard ACH and incoming wire transfers', 'Send international payments to 32 countries in 15 currencies'],
                    consData: ['Customer support only available during set business hours', 'No business credit card'],
                    check: false,
                    title: 'Bluevine',
                },
            ],
            choiceData: [
                {
                    rewardIcon: rewardIcon,
                    popularity: 8.3,
                    brandReputation: 8.8,
                    editorialReviews: 9.2,
                    editorialLink: '',
                    link: '',
                    background: 'show',
                    icon: star,
                    iconsm: starsm,
                    logo: winden,
                    reviewValue: 9.8,
                    dollar: dollar,
                    siteView: '1,271',
                    earning: null,
                    indexNumber: '1',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Winden',
                        subtitle: 'Banking designed for entrepreneurs',
                        anualPercntage: 'Best for new and small businesses',
                        list: ['Free Wire and ACH transfers', 'Earn unlimited 1% cash-back on all purchases', 'No minimum deposit required'],
                    },
                },
                {
                    popularity: 9.0,
                    brandReputation: 9.1,
                    editorialReviews: 9.2,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: wallet,
                    logo: relay,
                    reviewValue: 9.1,
                    // dollar: dollar,
                    siteView: null,
                    earning: null,
                    indexNumber: '2',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Relay',
                        subtitle: 'Putting you in complete control of your cashflow',
                        anualPercntage: 'Best for new and small businesses',
                        list: [
                            'Earn up to 3% APY with an automated savings account',
                            'No account or overdraft fees, no minimums',
                            'Open up to 20 checking accounts and 50 virtual or physical debit cards',
                        ],
                    },
                },
                {
                    popularity: 8.8,
                    brandReputation: 9.1,
                    editorialReviews: 9.6,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: dollar,
                    logo: bluevine,
                    reviewValue: 9.1,
                    // dollar: dollar,
                    siteView: null,
                    earning: null,
                    indexNumber: '3',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Bluevine',
                        subtitle: 'Banking platform for small businesses',
                        anualPercntage: 'Unlimited Transactions',
                        list: ['You can earn 2.0% APY on balances up to $250K', 'Free ACH and incoming wire transfers', 'Save time with a robust bill pay platform'],
                    },
                },
                {
                    popularity: 8.5,
                    brandReputation: 9.3,
                    editorialReviews: 9.6,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: mercury,
                    reviewValue: '9.0',
                    dollar: '',
                    siteView: null,
                    earning: null,
                    indexNumber: '4',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Mercury',
                        subtitle: 'Banking designed for startups',
                        // anualPercntage: "Unlimited Transactions",
                        list: ['No monthly or transaction fees', 'No minimum opening deposit', 'Up to $5 million in FDIC insurance'],
                    },
                },
                {
                    popularity: 8.0,
                    brandReputation: 9.0,
                    editorialReviews: 9.2,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: wallet,
                    logo: found,
                    reviewValue: 8.6,
                    // dollar: dollar,
                    siteView: null,
                    earning: null,
                    indexNumber: '5',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Found',
                        subtitle: 'All-in-one banking platform for the self-employed',
                        anualPercntage: 'Best for sole proprietorships',
                        list: [
                            'No monthly fees, account fees, or minimums',
                            'Personalize and send professional invoices',
                            'Track finances and organize taxes end-to-end',
                        ],
                    },
                },
                {
                    popularity: 8.3,
                    brandReputation: 9.1,
                    editorialReviews: 8.8,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: wallet,
                    logo: novo,
                    reviewValue: 8.6,
                    // dollar: dollar,
                    siteView: null,
                    earning: null,
                    indexNumber: '6',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Novo Boost',
                        subtitle: 'Award-winning free business checking',
                        anualPercntage: 'Best for any size LLC',
                        list: ['Get paid and pay vendors, faster', 'Access revenue in just hours', '50+ automation and financial insight tools'],
                    },
                },
                // {
                //   popularity: 20,
                //   brandReputation: 50,
                //   editorialReviews: 70,
                //   editorialLink: "",
                //   link: "",
                //   background: "",
                //   icon: "",
                //   logo: axos,
                //   reviewValue: 8.5,
                //   dollar: dollar,
                //   siteView: null,
                //   earning: null,
                //   indexNumber: "7",
                //   buttonTextsm: "View Rates",
                //   buttonText: "EXPLORE",
                //   topChoiceContent: {
                //     title: "Axos",
                //     subtitle: "Get more from your business account",
                //     // anualPercntage: "Best for any size LLC",
                //     list: [
                //       "Up to $400 bonus for business owners*",
                //       "Earn up to 1.01% APY",
                //       "Unlimited domestic ATM fee reimbursements",
                //     ],
                //   },
                // },
                {
                    popularity: 7.8,
                    brandReputation: 8.6,
                    editorialReviews: 9.2,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: grasshoper,
                    reviewValue: 8.3,
                    dollar: dollar,
                    siteView: null,
                    earning: null,
                    indexNumber: '7',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Grasshoppert',
                        subtitle: 'Digital banking solutions for businesses',
                        // anualPercntage: "Best for any size LLC",
                        list: ['No overdraft or monthly maintenance fees', 'Set up an online account in as little as 5 minutes', 'Earn up to 2.25% APYs'],
                    },
                },
            ],
            choosingBusinessChecking: [
                {
                    title: 'What to Consider When Choosing a Business Checking Account',
                    details: [
                        {
                            title: '',
                            desc: 'Before you sign up for a business checking account, it’s important to think carefully about what financial services your business needs. Business checking accounts differ widely, particularly when it comes to transaction limits, fees, interest, and balance requirements.',
                        },
                    ],
                },
                {
                    title: '1. Transaction Limits',
                    details: [
                        {
                            title: '',
                            desc: 'Many banks place limits on both deposits and withdrawals to your business checking account. For example, you may only be allowed to deposit a certain amount of cash into your account each month for free. If you exceed this cash deposit limit, you could face hefty fees that eat into your business’s revenue.',
                        },
                        {
                            title: '',
                            desc: 'Just as frequently, banks place limits on the number of transactions your business can make for free. For example, you may only be allowed to make a certain number of check payments, electronic transfers, debit card payments, or cash withdrawals each month. If you exceed that, expect to pay more fees.',
                        },
                    ],
                },
                {
                    title: '2. Fees',
                    details: [
                        {
                            title: '',
                            desc: 'Fees are one of the most important things to look at when choosing a business checking account. Banks often advertise business checking accounts as “free,” but in reality the account is only free under certain - often very restrictive - conditions.',
                        },
                        {
                            title: 'Monthly Account Fees',
                            desc: 'To start, check whether a bank will charge a monthly account fee. This is a fee that you’ll pay every month, no matter how often you make transactions from your account. Small monthly fees may be worthwhile if the bank eliminates other fees, but in general it’s a good idea to stick with business checking accounts that come with $0 monthly charges.',
                        },
                        {
                            title: '',
                            desc: 'You also need to watch out for “maintenance” fees. These are often listed separately from the primary monthly fee, and they can be as high as $50 per month at some banks. If a bank you like offers an account with a maintenance fee, ask them to waive it. If they won’t, you should reconsider whether that business checking account is worth it.',
                        },
                        {
                            title: 'Transaction Fees',
                            desc: 'For banks that offer “free” business checking accounts, transaction fees are the most common way to make money off of your business. Typically, business checking accounts come with anywhere between 200 and 500 transactions per month. While that sounds like a lot - and it may be for some businesses - it’s easy to run over that limit towards the end of the month if your business is processing dozens of transactions per day.',
                        },
                        {
                            title: '',
                            desc: 'Transaction fees are often a flat fee per payment over your limit. So, for example, you might be charged $0.50 for every excess debit card transaction. That’s not much if you’re placing a supply order worth thousands of dollars, but it can be as much as a 25% surcharge if you’re using your business debit card to buy a $2 cup of coffee.',
                        },
                    ],
                },
                {
                    title: '3. Interest',
                    details: [
                        {
                            title: '',
                            desc: 'Another important factor that differs between banks is whether they offer interest on cash inside your business checking account. With interest rates low for the foreseeable future, this might not be a huge deal. ',
                        },
                        {
                            title: '',
                            desc: 'Still, if you have a $100,000 balance in your business checking account, earning interest at even a modest 0.1% APY adds up to an extra $100 per year. Although that’s not much, it’s enough to throw a small office party for your employees.',
                        },
                        {
                            title: '',
                            desc: 'So, be sure to check whether a bank offers interest payments to business account holders and how the interest rates compare to competitors.',
                        },
                    ],
                },
                {
                    title: '4. Balance Requirements',
                    details: [
                        {
                            title: '',
                            desc: 'Many banks require that you keep a minimum balance inside your business checking account. If you fall below this minimum balance, you could be subject to more fees.',
                        },
                        {
                            title: '',
                            desc: 'On the other hand, some banks reward you rather than punish you for keeping a higher balance with them. For example, if you have more than a threshold amount of cash in your business checking account, you may qualify for higher transaction limits or higher interest rates.',
                        },
                        {
                            title: '',
                            desc: 'This reward structure can make some business checking accounts that initially seem too expensive much more appealing.',
                        },
                    ],
                },
                {
                    title: '5. Physical vs. Digital Banking',
                    details: [
                        {
                            title: '',
                            desc: 'Another thing to consider when choosing a bank for your business checking account is whether it has a physical footprint or whether it’s an online-only institution. Digital banks can often offer better account terms and higher interest rates since they have less overhead than traditional banks.',
                        },
                        {
                            title: '',
                            desc: 'On the other hand, if you need access to physical infrastructure like ATMs or want the ability to talk to someone at your bank in person, then a physical bank might be better for your business.',
                        },
                        {
                            title: '',
                            desc: 'Whichever option you prefer, take a look to see what other services your bank offers. If your business needs a loan down the road, the bank that runs your business checking account is a good place to start. Plus, banks frequently offer better terms to existing customers.',
                        },
                        {
                            title: '',
                            desc: 'If you choose a small local bank or an online-only checking account, just keep in mind that you might have less financial flexibility in the future. ',
                        },
                    ],
                },
            ],
            prosCons: {
                header: 'Explore Online Business Checking Accounts',
                data: [
                    {
                        title: 'Relay',
                        icon: Relay,
                        link: 'Relay',
                        to: 'https://lets.bankwithrelay.com/bestmoney/?utm_campaign=Affiliate%20Program&utm_source=BestMoney&utm_medium=Parnership&referralcode=BestMoney&sid=l0Pyv9WaIG',
                        description1:
                            'is an online banking platform for small businesses, accountants, and bookkeepers to automate their bill pay and manage their cash flow and operating expenses. It charges no fees and makes it easy to send and receive ACH transfers, checks, or wires. You can sync transaction data directly in QuickBooks or Xero, and set up role-based permissions so there’s no need to share login information between staff.',

                        pros: [
                            {
                                list: 'Order up to 50 physical or virtual debit Mastercards/account',
                            },
                            {
                                list: 'No checking account, minimum balance, ATM, or overdraft fees',
                            },
                        ],
                        cons: [
                            {
                                list: 'No 24/7 customer support',
                            },
                            {
                                list: 'No interest earned on deposits',
                            },
                        ],
                    },
                    {
                        title: 'BlueVine',
                        icon: BluevineLogo,
                        link: 'BlueVine’s',
                        to: 'https://www.bluevine.com/business-checking?subid=CfgbscKHgF&utm_medium=cpc&utm_source=NIDDA&utm_term=pdsrch',
                        description1:
                            'online banking option is a safe and affordable platform for small businesses looking to grow their company, receive low interest funds or simply to have a place to park their revenue. With no fees, thousands of free ATMs and several banking services offered, online banking with BlueVine is both simple and affordable.',

                        pros: [
                            {
                                list: 'High yield interest checking',
                            },
                            {
                                list: 'No minimum deposit or maintenance fees',
                            },
                        ],
                        cons: [
                            {
                                list: 'Customer support only during business hours',
                            },
                            {
                                list: 'Outgoing wire transfer fees',
                            },
                        ],
                    },
                    {
                        title: 'Lili',
                        icon: lili_logo,
                        link: 'Lili',
                        to: 'https://lp.lili.co/lili-business/?utm_term=desktop&utm_content=bestmoney-business-banking&utm_medium=partners&utm_source=Natural%20Intelligence%20LTD%2C&utm_partner=2QP1JgUo7xyPR4HV4SXUZzKhUkFXkS25US4i0w0&irgwc=1',
                        description1:
                            'Bank is a great option for freelancers who are struggling to manage their taxes and prefer a digital-interfaced bank. With no fees to worry about on the Standard account and no location to tie you down, it makes it easy to travel and work as you please with a great bank beside you every step of the way. ',

                        pros: [
                            {
                                list: 'Standard account is completely fee-free',
                            },
                            {
                                list: 'Automatically set aside money for taxes',
                            },
                        ],
                        cons: [
                            {
                                list: 'Limited withdrawal/deposit opportunities',
                            },
                            {
                                list: 'Customer service is not 24/7',
                            },
                        ],
                    },
                ],
            },
        };
    },
    methods: {
        toggle(id, value) {
            console.log('id,value', id, value);
            this.reviewOnlineAccount[id].check = value;
        },
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
            // Disable scrolling when the drawer is open
            if (this.isDrawerOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.extraPaddingTop {
    padding-top: 65px;
    @media (max-width: 991.98px) {
        padding-top: 0px;
    }
}
</style>
