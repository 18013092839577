<template>
    <b-container>
        <div class="financial-decisions pt-4">
            <div class="heading-wrapper">
                <h4 class="heading">Take the hard work out of financial decisions</h4>
            </div>
            <div class="row mt-4">
                <div class="col-lg-4">
                    <div class="w-100">
                        <img src="../assets/Compare-image.svg" class="mb-3 d-lg-inline d-none" />
                        <h3 class="financial-decisions-card-heading">Compare</h3>
                        <p>
                            Understand your options with our comparison charts that feature essential information from top providers about loans, mortgages, banking and
                            more.
                        </p>
                        <img src="../assets/Compare-image.svg" class="mt-3 d-lg-none d-inline" />
                    </div>
                </div>
                <div class="col-lg-4 mt-5 mt-lg-0">
                    <img src="../assets/Discover-image.svg" class="mb-3 mt-lg-5 d-lg-inline d-none" />
                    <h3 class="financial-decisions-card-heading">Discover</h3>
                    <p>Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                    <img src="../assets/Discover-image.svg" class="mt-3 d-lg-none d-inline" />
                </div>
                <div class="col-lg-4 mt-5 mt-lg-0">
                    <img src="../assets/Shop-image.svg" class="mb-3 d-lg-inline d-none" />
                    <h3 class="financial-decisions-card-heading">Shop</h3>
                    <p>Select from tailored offers from a rich inventory of top providers powered by our matching technology.</p>
                    <img src="../assets/Shop-image.svg" class="mb-3 d-lg-none d-inline" />
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
    .container {
        max-width: 540px !important;
    }
}
p {
    &::after {
        // position: absolute;
        margin: 10px auto;
        display: flex;
        content: '';
        height: 2px;
        width: 60px;
        background-color: #e8faff;
        bottom: 0;
    }
}
</style>
