<template>
    <b-container class="bg-header">
        <b-row>
            <b-col>
                <div class="header position-relative text-right d-md-block d-none">
                    <b-link id="addTooltip" class="text-site-dark header__link fs--14 mt-2">Advertising Disclosure</b-link>

                    <b-tooltip custom-class="add" target="addTooltip" variant="light" triggers="hover" placement="bottomleft">
                        This site is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising
                        compensation from companies that appear on the site, which may impact the location and order in which brands (and/or their products) are
                        presented, and may also impact the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all
                        providers on the market. Except as expressly set forth in our
                        <b-link to="#" class="fw--700 text-site-dark text-decoration-underline">Terms of Use</b-link>
                        , all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which
                        appears on this site is subject to change at any time
                    </b-tooltip>
                </div>
                <div class="text-md-left text-center">
                    <p class="fs--14 d-md-flex last-update">
                        <img class="d-none d-md-flex px-1" src="../assets/savingaccountsicon.svg" alt="" />
                        Last updated
                        <span class="fw--700 ml-1">November 2023</span>
                    </p>
                    <h1 class="fs--38 fw--700 d-md-block d-none">
                        Best
                        <span class="text-site-primary">{{ data.heading }}</span>
                        of November 2023
                        <br />
                    </h1>
                    <h1 class="fs--28 fw--700 d-md-none d-block">
                        Best
                        <span class="text-site-primary">{{ data.heading }}</span>
                        of November 2023
                        <br />
                    </h1>
                    <h1 class="fs--34 fw--600 d-md-block d-none">
                        {{ data.subHeading }}
                    </h1>

                    <p class="mb-0 fs--18 fw--400 mt-2 text-site-dark mw-650 d-md-block d-none">
                        {{ data.description }}
                    </p>
                    <p class="mb-0 fs--18 fw--400 text-site-dark mw-650 d-md-block d-none" v-for="(item, index) in data.list" :key="index">
                        {{ item.text }}
                    </p>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'HeaderAccounts',
    props: ['data'],
};
</script>

<style lang="scss" scoped>
.mw-650 {
    max-width: 650px;
    @media (max-width: 767.98px) {
        max-width: 100%;
        // text-align: center;
    }
}
.header {
    margin-top: 8px;
}
.last-update {
    margin: 18px 0 8px;
}
.bg-header {
    @media (max-width: 767.98px) {
        background-color: #f4f4f4 !important;
    }
}
</style>
