<template>
  <div class="text-justify">
    <h6 class="fs--20 fw--700">Disclaimers</h6>
    <p class="fs--17 fw--700 my-4">Lili:</p>

    <p class="fs--17 mt-3">
      ¹ Lili is a technology company and not a bank. Banking services are
      provided by Choice Financial Group, Member FDIC.
    </p>
    <p class="fs--17 mt-4">
      ² The Lili Visa® Business Debit Card is issued by Choice Financial Group,
      Member FDIC, pursuant to a license from Visa U.S.A
    </p>
    <p class="fs--17 mt-4">
      ³ The Annual Percentage Yield (“APY”) for the Lili Savings Account is
      variable and may change at any time. The disclosed APY is effective as of
      September 1st, 2023. Must have at least $0.01 in savings to earn interest.
      The APY applies to balances of up to and including $100,000. Any balance
      over $100,000 will not earn interest or have a yield. Available to Lili
      Pro, Lili Smart, and Lili Premium account holders only.
    </p>
    <p class="fs--17 mt-4">
      ^ Early access to direct deposit funds depends on the timing of the
      submission of the payment file from the payer. We generally make these
      funds available on the day the payment file is received, which may be up
      to 2 days earlier than the scheduled payment date.
    </p>
    <p class="fs--17 fw--700 my-4">Bluevine:</p>
    <p class="fs--17 mt-4">
      ¹ No limit on numbers of transactions, However, all accounts are subject
      to the aggregated monthly deposit and withdrawal amount limits of the
      <a class="text-site-dark text-decoration-underline">
        Account Agreement.</a
      >
    </p>
    <p class="fs--17 fw--700 my-4 d-lg-block d-none">Relay:</p>

    <p class="fs--17 mt-4 d-lg-block d-none">
      ¹ Limit of one (1) $100 USD cash reward per business. To qualify for this
      offer, your Relay application must be completed by July 31, 2023 at
      11:59pm ET; in addition, your Relay account must be funded with at least
      $100 USD by August 14, 2023 at 11:59pm ET. Offer cannot be combined with
      other offers. $100 ('The Offer') will be automatically applied to an
      approved Relay account within 10 business days of fulfilling the
      eligibility requirement(s).
    </p>
    <p class="fs--17 fw--700 my-4 d-lg-block d-none">Axos:</p>

    <p class="fs--17 mt-4 d-lg-block d-none">
      *Open a business checking account by Dec. 31 and get up to $400 when you
      use promo code NEW400 on your application.
    </p>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "Disclaimer",
};
</script>

<style></style>
