<template>
  <div class="py-5 mb-5 explore-more">
    <h1 class="fw--700 mb-3">
      Explore more <br />
      from CreditGrail
    </h1>
    <VueSlickCarousel
      class="custom-carousel d-md-block d-none"
      v-bind="settings"
    >
      <div
        v-for="(item, index) in carouselData"
        class="carousel-item"
        :key="index"
      >
        <img :src="item.link" alt="Carousel Item" class="carousel-image" />
        <div class="carousel-title">{{ item.title }}</div>
      </div>
    </VueSlickCarousel>
    <div class="d-md-none d-flex custom-carouselsm">
      <div
        v-for="(item, index) in carouselData"
        class="carousel-itemsm"
        :key="index"
      >
        <img :src="item.link" alt="Carousel Item" class="carousel-image" />
        <div class="carousel-title fs--16 fw--600 mt-2">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "MyComponent",
  components: { VueSlickCarousel },
  data() {
    return {
      carouselData: [
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/ML_image.jpg",
          title: "Home loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/MLRefi_image.jpg",
          title: "Home loans refinance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/SL_image.jpg",
          title: "Private student loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/SLR_image.jpg",
          title: "Student loan refinance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/PL_image.jpg",
          title: "Personal loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/OB_image.jpg",
          title: "Online banking",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/BL_image.jpg",
          title: "Business loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/CL_image.jpg",
          title: "Car loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/MT_image.jpg",
          title: "Money transfer",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/DC_image.jpg",
          title: "Debt Consolidation Loans",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/PI_image.jpg",
          title: "Pet insurance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/LI_image.jpg",
          title: "Life insurance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/CI_image.jpg",
          title: "Car insurance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/HI_image.jpg",
          title: "Home insurance",
        },
        {
          link: "https://images.bestmoney.com/iu/f_auto/q_auto/v1/production/best-money/home-page/CC_image.jpg",
          title: "Credit cards",
        },
      ],
      settings: {
        dots: false,
        infinite: false,

        slidesToShow: 5,
        slidesToScroll: 5,
        arrows: true,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.custom-carousel {
  position: relative;
  @media (min-width: 1200px) and (max-width: 1420px) {
    width: 90%;
    margin: auto;
  }
  @media (max-width: 1199.98px) {
    width: 95%;
    margin: auto;
  }
  @media (max-width: 767.98px) {
    width: 90%;
    margin: auto;
  }
}

.carousel-item {
  cursor: pointer;
  padding: 0 10px;
  padding-top: 10px;
}

/* .custom-carousel .slick-slide {
  margin-right: 40px !important; 
} */

/* Add styles for the image and title */
.custom-carousel .carousel-image {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  transition-duration: 0.5s;
}

.carousel-image:hover {
  transform: translateY(-4px);
  box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.2);
}

.custom-carousel .carousel-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  text-align: center;
  color: #171717;
  margin-top: 23px;
  letter-spacing: -0.004em;
  /* Adjust as needed */
}
.custom-carouselsm {
  overflow-x: auto;
  .carousel-itemsm {
    // width: 40%;
    padding-right: 10px;
  }
  .carousel-image {
    border-radius: 10px;
    max-width: 200px;
    height: auto;
    transition-duration: 0.5s;
    @media (max-width: 575.98px) {
      max-width: 150px;
    }
  }
}
</style>
