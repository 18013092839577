<template>
    <div>
        <b-container class="d-lg-none d-block bg-header py-md-5 py-3">
            <div class="d-flex justify-content-between align-items-center flex-row filter card p-2 business-filter">
                <p class="text-left m-0 fs--14">
                    Which
                    <b class="font-weight-bold">online account</b>
                    are you looking for?
                </p>
                <div class="bg-site-primary business-filter__img" @click="toggleDrawer">
                    <img src="../../assets/filter-icon.svg" />
                </div>
            </div>
        </b-container>

        <div v-if="isDrawerOpen" class="overlay" @click="toggleDrawer"></div>

        <!-- The bottom drawer -->
        <div class="bottom-drawer" :class="{ open: isDrawerOpen }">
            <b-button class="bottom-drawer__btn d-flex justify-content-center align-items-center bg-site-dark">
                <img src="../../assets/filter-icon.svg" class="mr-1" />
                REFINE RESULTS
            </b-button>
            <div class="d-flex flex-column p-3 drawer-content">
                <FloatingSelect :selectedOption="selectedOption" @change="handleSelectChange" />

                <b-button class="drawer-content__btn" @click="handleApply">APPLY CHANGES</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import FloatingSelect from './FloatingSelect.vue';
export default {
    name: 'FilterMobile',

    data() {
        return {
            isDrawerOpen: false,
            selectedOption: '',
        };
    },
    components: {
        FloatingSelect,
    },
    methods: {
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen;
        },
        handleApply() {
            console.log('Selected option in parent:', this.selectedOption);
            this.isDrawerOpen = false;

            if (this.selectedOption === 'BusinessChecking') {
                this.$router.push('/compare-business-checking');
            } else if (this.selectedOption === 'CheckingAccounts') {
                this.$router.push('/compare-checking-accounts');
            } else if (this.selectedOption === 'OnlineBanking') {
                this.$router.push('/compare-online-banking');
            } else {
                this.$router.push('/compare-savings-accounts');
            }
        },
        handleSelectChange(newOption) {
            // Handle the change in the parent component
            this.selectedOption = newOption;
        },
    },
    mounted() {
        // Set the default value based on the current route
        const currentRoute = this.$route.path;
        if (currentRoute.includes('compare-business-checking')) {
            this.selectedOption = 'BusinessChecking';
        } else if (currentRoute.includes('compare-online-banking')) {
            this.selectedOption = 'OnlineBanking';
        } else if (currentRoute.includes('compare-checking-accounts')) {
            this.selectedOption = 'CheckingAccounts';
        } else {
            this.selectedOption = 'SavingAccounts';
        }
    },
};
</script>

<style lang="scss">
.business-filter {
    border-radius: 0.5rem !important;
    &__img {
        width: 35px;
        height: 27px;
        margin-left: 8px;
        padding: 2px 5px;
        display: flex;
        align-items: center;
        border-radius: 2px;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;

        img {
            width: 15px;
            height: 17px;
        }
    }
}

//Drawer styling

.overlay {
    position: fixed;
    display: flex !important;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(34, 34, 34, 0.6);
    z-index: 450; /* Place it on top of everything */
    display: none;
}

.bottom-drawer {
    position: fixed;
    bottom: -100%; /* Start offscreen */
    left: 0;
    width: 100%;
    // height: 300px;
    // background-color: #fff;
    // border-top: 1px solid #ccc;
    transition: bottom 0.3s ease;
    z-index: 500;
    transition: all 300ms ease-in-out 300ms;
    display: flex;
    flex-direction: column;

    &__btn {
        min-height: 48px;
        border-radius: 20px 20px 0px 0px !important;
        &:before {
            content: '';
            background: white;
            width: 103px;
            height: 5px;
            border-radius: 20px;
            position: absolute;
            top: -14px;
        }
    }
}

.bottom-drawer.open {
    bottom: 0;
}

.drawer-content {
    background: white !important;
    &__btn {
        background: #49a8ff !important;
        border: none !important;
        border-radius: 0px !important;
        font-weight: bold !important;
    }
}
.bg-header {
    @media (max-width: 767.98px) {
        background-color: #f4f4f4 !important;
    }
}
</style>
