<template>
  <b-link
    :to="item.link"
    class="financial-card d-flex flex-column align-items-center px-2 py-3 bg-white text-decoration-none"
  >
    <div class="financial-card__icon mb-2">
      <img :src="item.icon" alt="Financial Product Icon" />
    </div>

    <p class="financial-card__title mt-1 mb-0 fw--700 text-site-dark">
      {{ item.title }}
    </p>
  </b-link>
</template>

<script>
import "../../assets/scss/index.scss";

export default {
  name: "FinancialCard",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
