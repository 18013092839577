<template>
  <div class="financial-product">
    <div class="d-flex justify-content-center w-100 px-3">
      <div class="financial-product__container">
        <p
          class="financial-product__heading fw--700 text-site-secondary mb-md-3 mb-4"
        >
          Compare and shop the best <br class="d-none d-md-inline" />

          financial products for you
        </p>
        <div class="d-none d-lg-block w-100">
          <div class="grid-cols-12 w-100 text-left">
            <div class="col-span-5">
              <div>
                <FinancialRatingCard :item="items[0]" />
              </div>
              <div class="grid-cols-12 mt-3">
                <div class="col-span-6">
                  <FinancialCard :item="items[4]" />
                </div>
                <div class="col-span-6">
                  <FinancialCard :item="items[5]" />
                </div>
              </div>
            </div>
            <div class="col-span-5">
              <div class="grid-cols-12">
                <div class="col-span-6">
                  <FinancialCard :item="items[1]" />
                </div>
                <div class="col-span-6">
                  <FinancialCard :item="items[2]" />
                </div>
              </div>
              <div class="mt-3">
                <FinancialRatingCard :item="items[6]" />
              </div>
            </div>
            <div class="col-span-2">
              <div>
                <FinancialCard :item="items[3]" />
              </div>
              <div class="mt-3">
                <div v-if="!showMore" @click="showMore = true">
                  <FinancialCard :item="showMoreItem" />
                </div>
                <div v-else>
                  <FinancialCard :item="items[7]" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-lg-block mt-3 w-100">
          <div v-if="showMore" class="grid-cols-12 w-100 text-left">
            <div class="col-span-5">
              <div>
                <FinancialRatingCard :item="items[8]" />
              </div>
              <div class="grid-cols-12 mt-3">
                <div class="col-span-6">
                  <FinancialCard :item="items[12]" />
                </div>
                <div class="col-span-6">
                  <FinancialCard :item="items[13]" />
                </div>
              </div>
            </div>
            <div class="col-span-5">
              <div class="grid-cols-12">
                <div class="col-span-6">
                  <FinancialCard :item="items[9]" />
                </div>
                <div class="col-span-6">
                  <FinancialCard :item="items[10]" />
                </div>
              </div>
              <div class="mt-3 grid-cols-12">
                <div class="col-span-6">
                  <FinancialCard :item="items[14]" />
                </div>
                <div class="col-span-6">
                  <FinancialCard :item="items[15]" />
                </div>
              </div>
            </div>
            <div class="col-span-2">
              <div>
                <FinancialCard :item="items[11]" />
              </div>
            </div>
          </div>
        </div>

        <b-row class="w-100 d-lg-none">
          <b-col
            v-for="(item, index) in mobileItems.slice(
              0,
              showMore ? mobileItems.length : 7
            )"
            :key="index"
            class="mt-2 col-6 financial-product__col"
            :class="index % 2 ? 'px-0' : 'pl-0 pr-2'"
          >
            <FinancialCardMobile :item="item" />
          </b-col>
          <b-col
            v-if="!showMore"
            class="mt-2 col-6 financial-product__col"
            :class="index % 2 ? 'px-0' : 'pl-0 pr-2'"
            @click="showMore = true"
          >
            <FinancialCardMobile :item="showMoreItem" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import businessloans from "../assets/FinancialProducts/businessloans.svg";
import carinsurance from "../assets/FinancialProducts/carinsurance.svg";
import carloans from "../assets/FinancialProducts/carloans.svg";
import creditcards from "../assets/FinancialProducts/creditcards.svg";
import debtconsolidation from "../assets/FinancialProducts/debtconsolidation.svg";
import goldandsilver from "../assets/FinancialProducts/godlandsilver.svg";
import homeinsurance from "../assets/FinancialProducts/homeinsurance.svg";
import homeloan from "../assets/FinancialProducts/homeloan.svg";
import lifeinsurance from "../assets/FinancialProducts/lifeinsurance.svg";
import moneytransfer from "../assets/FinancialProducts/moneytransfer.svg";
import onlinebanking from "../assets/FinancialProducts/onlinebooking.svg";
import personalloan from "../assets/FinancialProducts/personalloan.svg";
import petinsurance from "../assets/FinancialProducts/petinsurance.svg";
import showmore from "../assets/FinancialProducts/showmore.svg";
import studentloans from "../assets/FinancialProducts/studentloans.svg";
import taxrelief from "../assets/FinancialProducts/taxrelief.svg";
import travelinsurance from "../assets/FinancialProducts/travelinsurance.svg";
import "../assets/scss/index.scss";
import FinancialCard from "./FinancialProducts/FinancialCard.vue";
import FinancialCardMobile from "./FinancialProducts/FinancialCardMobile.vue";
import FinancialRatingCard from "./FinancialProducts/FinancialRatingCard.vue";

import mortgagepurchase from "../assets/FinancialProducts/mortgagepurchase.svg";
import mortgagerefinance from "../assets/FinancialProducts/mortgagerefinance.svg";
import studentrefinance from "../assets/FinancialProducts/studentrefinance.svg";
export default {
  name: "FinancialProducts",
  props: {},
  components: {
    FinancialCard,
    FinancialRatingCard,
    FinancialCardMobile,
  },
  data() {
    return {
      items: [
        {
          type: "rating",
          ratingText: "Low rates",
          icon: homeloan,
          title: "Home loans",
          description: "Take advantage of low rates",
          services: [
            {
              name: "Purchase",
              url: "",
            },
            {
              name: "Refinance",
              url: "",
            },
          ],
        },
        {
          type: "simple",
          title: "Personal loans",
          icon: personalloan,
        },
        {
          link: "/compare-online-banking",
          type: "simple",
          title: "Online banking",
          icon: onlinebanking,
        },
        {
          type: "simple",
          title: "Car insurance",
          icon: carinsurance,
        },
        {
          type: "simple",
          title: "Life insurance",
          icon: lifeinsurance,
        },
        {
          type: "simple",
          title: "Pet insurance",
          icon: petinsurance,
        },
        {
          type: "rating",
          ratingText: "Low rates",
          icon: homeinsurance,
          title: "Home insurance",
          description: "Cover your money & everything in it",
          services: [
            {
              name: "Home owners",
              url: "",
            },
            {
              name: "Renters",
              url: "",
            },
          ],
        },
        {
          type: "simple",
          title: "Business loans",
          icon: businessloans,
        },
        {
          type: "rating",
          ratingText: "Loans for all credit scores",
          icon: studentloans,
          title: "Student loans",
          description: "Take advantage of low rates",
          services: [
            {
              name: "Private",
              url: "",
            },
            {
              name: "Refinance",
              url: "",
            },
          ],
        },
        {
          type: "simple",
          title: "Car loans",
          icon: carloans,
        },
        {
          type: "simple",
          title: "Money transfer",
          icon: moneytransfer,
        },
        {
          type: "simple",
          title: "Debt Consolidation",
          icon: debtconsolidation,
        },
        {
          type: "simple",
          title: "Credit Cards",
          icon: creditcards,
        },
        {
          type: "simple",
          title: "Travel Insurance",
          icon: travelinsurance,
        },
        {
          type: "simple",
          title: "Gold and Silver IRA",
          icon: goldandsilver,
        },
        {
          type: "simple",
          title: "Tax relief",
          icon: taxrelief,
        },
      ],
      showMoreItem: {
        type: "simple",
        title: "Show more",
        icon: showmore,
      },
      showMore: false,
      mobileItems: [
        {
          title: "Mortgage Purchase",
          icon: mortgagepurchase,
        },
        {
          title: "Mortgage Refinance",
          icon: mortgagerefinance,
        },
        {
          title: "Personal loans",
          icon: personalloan,
        },
        {
          link: "/compare-online-banking",
          title: "Online banking",
          icon: onlinebanking,
        },
        {
          title: "Car insurance",
          icon: carinsurance,
        },
        {
          title: "Life insurance",
          icon: lifeinsurance,
        },
        {
          title: "Home insurance",
          icon: homeinsurance,
        },
        {
          title: "Pet insurance",
          icon: petinsurance,
        },
        {
          title: "Private student loans",
          icon: studentloans,
        },
        {
          title: "Business loans",
          icon: businessloans,
        },
        {
          title: "Student loans refinance",
          icon: studentrefinance,
        },
        {
          title: "Car loans",
          icon: carloans,
        },
        {
          title: "Money transfer",
          icon: moneytransfer,
        },
        {
          title: "Dept Consolidation",
          icon: debtconsolidation,
        },
        {
          title: "Credit Cards",
          icon: creditcards,
        },
        {
          title: "Travel Insurance",
          icon: travelinsurance,
        },
        {
          title: "Gold and Silver IRA",
          icon: goldandsilver,
        },
        {
          title: "Tax relief",
          icon: taxrelief,
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss"></style>
