<template>
  <div class="text-left">
    <h6 class="fs--20 fw--700">Chime Disclaimer</h6>
    <p class="fs--17 mt-3">
      Chime is a financial technology company, not a bank. Banking services and
      debit card provided by The Bancorp Bank, N.A. or Stride Bank, N.A.,
      Members FDIC.
    </p>
    <p class="fs--17 mt-4">
      ˜ Out-of-network ATM withdrawal fees may apply except at MoneyPass ATMs in
      a 7-Eleven, or any Allpoint or Visa Plus Alliance ATM.
    </p>
    <p class="fs--17 mt-4">
      * SpotMe® on Debit is an optional, no fee overdraft service attached to
      your Chime Checking Account. To qualify for the SpotMe on Debit service,
      you must receive $200 or more in qualifying direct deposits to your Chime
      Checking Account each month and have activated your Visa debit card.
      Qualifying members will be allowed to overdraw their Chime Checking
      Account for up to $20 on debit card purchases and cash withdrawals
      initially but may later be eligible for a higher limit of up to $200 or
      more based on Chime Account history, direct deposit frequency and amount,
      spending activity and other risk-based factors. The SpotMe on Debit limit
      will be displayed within the Chime mobile app and is subject to change at
      any time, at Chime's sole discretion. Although Chime does not charge any
      overdraft fees for SpotMe on Debit, there may be out-of-network or
      third-party fees associated with ATM transactions. SpotMe on Debit will
      not cover any non-debit card transactions, including ACH transfers, Pay
      Anyone transfers, or Chime Checkbook transactions. SpotMe on Debit Terms
      and Conditions.
    </p>
    <p class="fs--17 mt-4">
      ^ Early access to direct deposit funds depends on the timing of the
      submission of the payment file from the payer. We generally make these
      funds available on the day the payment file is received, which may be up
      to 2 days earlier than the scheduled payment date.
    </p>
    <p class="fs--17 mt-4">
      ⁺ The Annual Percentage Yield ("APY") for the Chime Savings Account is
      variable and may change at any time. The disclosed APY is effective as of
      September 20, 2023. No minimum balance required. Must have $0.01 in
      savings to earn interest.
    </p>
    <p class="fs--17 mt-4">
      ¹ Round Ups automatically round up debit card purchases to the nearest
      dollar and transfer the round up from your Chime Checking Account into
      your savings account. Save When I Get Paid automatically transfers 10% of
      your direct deposits of $500 or more into your savings account.
    </p>
    <p class="fs--17 mt-4">
      ² FDIC deposit insurance limit to $250,000 per depositor, per insured
      bank, per ownership category. For Stride Savings Account depositors, funds
      are eligible for FDIC insurance when it arrives at the bank. Please visit
      www.chime.com/savings-apy-and-program-banks/ for more details on FDIC
      insurance.
    </p>
    <p class="fs--17 mt-4">
      ³ Sometimes instant transfers can be delayed. The recipient must use a
      valid debit card to claim funds. See your issuing bank’s Deposit Account
      Agreement for full Pay Friends Transfers details.
    </p>
    <p class="fs--17 mt-4">
      ⁴ The average national savings account interest rate of 0.17% is
      determined by FDIC as of September 19, 2022 based on a simple average of
      rates paid (uses annual percentage yield) by all insured depository
      institutions and branches for which data are available. Visit National
      Rates and Rate Caps to learn more.
    </p>
    <p class="fs--17 mt-4">
      ⁵ Chime will be offering members a chance to get their federal tax refund
      up to 5 days early when they file directly with the IRS and direct deposit
      with Chime. Getting your federal tax refund early is simple, just sign up
      for a Chime Checking Account, file your return with the IRS, and set up
      your tax refund to direct deposit into your Chime Checking Account. After
      setting up direct deposit, members will also be eligible to get regular
      paychecks up to 2 days early^.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
