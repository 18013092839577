<template>
  <div
    class="bg-site-secondary-lite brands d-flex align-items-center py-5 py-md-3"
  >
    <b-container>
      <b-row class="h-100">
        <b-col md="3" class="d-flex align-items-center brands__left-col">
          <span class="brands__text text-site-dark">
            Explore offers from brands top-rated on
            <img src="../assets/Trustpilot-logo.svg" height="21px" />
          </span>
        </b-col>
        <b-col md="9" class="d-flex align-items-center mt-md-0 mt-4">
          <img
            src="../assets/Brands/brands.svg"
            class="img-fluid d-md-flex d-none"
          />
          <img
            src="../assets/Brands/brandsmobile.svg"
            class="img-fluid d-md-none"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import "../assets/scss/index.scss";

export default {
  name: "Brands",

  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.brands {
  position: relative;
  min-height: 96px;
  &__text {
    font-size: 18px;
    text-align: start;
  }
  &__left-col {
    border-right: 1px solid #a4b3ae;
  }

  @media (max-width: 767.99px) {
    border-radius: 8px !important;
    margin-top: -100px;
    margin-left: 15px;
    margin-right: 15px;
    &__left-col {
      border-right: none !important;
      justify-content: center;
      img {
        height: 25px !important;
      }
    }

    &__text {
      width: 215px;
      font-size: 24px;
      text-align: center;
      line-height: 29px;
      font-weight: 600;
    }
  }
}
</style>
