<template>
  <b-link
    :to="item.link"
    class="financial-card-mobile d-flex align-items-center px-2 py-2 bg-site-secondary text-decoration-none"
  >
    <img
      :src="item.icon"
      alt="Financial Product Icon"
      class="mr-2 financial-card-mobile__img"
    />

    <p class="financial-card-mobile__title mt-1 mb-0 text-site-dark fs--15">
      {{ item?.title }}
    </p>
  </b-link>
</template>
<script>
import "../../assets/scss/index.scss";

export default {
  name: "FinancialCardMobile",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss"></style>
