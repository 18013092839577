<template>
    <div class="footer-section" no-gutters>
        <b-container>
            <b-row class="pb-5">
                <b-col md="12" lg="4" class="mt-5">
                    <div class="mb-4 text-left">
                        <img src="../../assets/creditgrail-logo.svg" alt="" width="250px" />
                    </div>
                    <div class="mb-3 mt-1 py-2">
                        <p class="text-site-dark fs--14 text-left mb-0">
                            Copyright © 2009-2023 Natural Intelligence Ltd.
                            <br class="d-none d-lg-flex" />
                            All Rights Reserved.
                        </p>
                        <p class="text-site-dark text-left fs--14">
                            By using our content, products & services you
                            <br class="d-none d-lg-flex" />
                            agree to our
                            <span class="fw--700">Terms of Use</span>
                            and
                            <span class="fw--700">Privacy Policy.</span>
                            <br class="d-none d-lg-flex" />

                            <span class="fw--700">NMLS Consumer Access</span>
                        </p>
                        <p class="text-site-dark text-left fs--14 mb-0">
                            CreditGrail is a dba of Natural Intelligence
                            <br class="d-none d-lg-flex" />
                            Technologies Inc.
                        </p>
                        <p class="text-site-dark text-left fs--14 mb-0">
                            Natural Intelligence Technologies Inc. NMLS #
                            <br class="d-none d-lg-flex" />
                            2084135
                        </p>
                        <p class="text-site-dark text-left fs--14">
                            CT: Mortgage Broker only, not a mortgage lender
                            <br class="d-none d-lg-flex" />
                            or mortgage correspondent lender.
                        </p>
                        <div class="border-top d-lg-none"></div>
                        <p class="text-site-dark d-none d-lg-flex fs--14 fw--700 text-left">Don't Sell My Personal Information</p>
                        <p class="text-site-dark text-left mt-4 mt-lg-0 d-lg-none fw--400 fs--14 mb-0">
                            Designed to help users make confident decisions online, this website contains information about a wide range of products and services. Certain
                            details, including but not limited to prices and special offers, are provided to us directly from our partners and are dynamic and subject to
                            change at any time without prior notice. Though based on meticulous research, the information we share does not constitute legal or
                            professional advice or forecast, and should not be treated as such. Company listings on this site DO NOT imply endorsement. We are not an
                            investment adviser, loan provider, or a broker and we do not offer loans or mortgages directly to end users, but only allows users to match
                            with lending partners and platforms that may extend a loan. All loan approval decisions and terms are determined by the loan providers at the
                            time of your application with them. Any matching request submitted through our website does not constitute a loan application and you will
                            have to submit a loan application to the respective lender before the lender provides you with an actual offer. We do not warrant that you
                            will be approved for a loan, nor that you will be offered a loan with the same terms presented on our website.
                        </p>
                        <p class="text-site-dark text-left d-lg-none fw--400 fs--14">
                            Insurance services offered through Natural Intelligence Technologies Inc.:
                            <span class="fw--700">Licenses</span>
                            Reproduction in whole or in part is strictly prohibited.
                        </p>
                        <div class="border-top mt-4 d-lg-none"></div>
                    </div>
                </b-col>
                <b-col md="12" lg="8" class="mt-3 mt-lg-5">
                    <div class="mb-4 text-left d-flex flex-column d-lg-block">
                        <a class="text-site-dark fs--14 fw--700" href="">About Us</a>
                        <a class="text-site-dark px-0 py-5 py-lg-0 px-lg-3 fw--700" href="">Cookie Policy</a>
                        <a class="text-site-dark fs--14 fw--700" href="">Terms Of Use</a>
                        <a class="text-site-dark fs--14 fw--700 px-0 py-5 py-lg-0 px-lg-3" href="">Partner With Us</a>
                        <a class="text-site-dark fs--14 fw--700" href="">Privacy Policy</a>
                        <a class="text-site-dark fs--14 fw--700 px-0 py-5 py-lg-0 px-lg-3" href="">Contact</a>
                        <div class="border-top mb-4 d-lg-none"></div>
                        <p class="text-site-dark mb-0 d-flex d-lg-none fs--14 fw--700 text-left">Don't Sell My Personal Information</p>
                        <div class="border-top mt-4 d-lg-none"></div>
                    </div>
                    <div class="text-left mb-4">
                        <p class="text-site-dark d-none d-lg-flex fw--400 fs--14 mb-0">
                            Designed to help users make confident decisions online, this website contains information about a wide range of products and services. Certain
                            details, including but not limited to prices and special offers, are provided to us directly from our partners and are dynamic and subject to
                            change at any time without prior notice. Though based on meticulous research, the information we share does not constitute legal or
                            professional advice or forecast, and should not be treated as such. Company listings on this site DO NOT imply endorsement. We are not an
                            investment adviser, loan provider, or a broker and we do not offer loans or mortgages directly to end users, but only allows users to match
                            with lending partners and platforms that may extend a loan. All loan approval decisions and terms are determined by the loan providers at the
                            time of your application with them. Any matching request submitted through our website does not constitute a loan application and you will
                            have to submit a loan application to the respective lender before the lender provides you with an actual offer. We do not warrant that you
                            will be approved for a loan, nor that you will be offered a loan with the same terms presented on our website.
                        </p>
                        <p class="text-site-dark d-none d-lg-flex fw--400 fs--14 mb-0">
                            Insurance services offered through Natural Intelligence Technologies Inc.:
                            <span class="fw--700">Licenses</span>
                            <br />
                        </p>
                        <p class="text-site-dark d-none d-lg-flex fw--400 fs--14">Reproduction in whole or in part is strictly prohibited.</p>
                        <div class="border-top d-none d-lg-flex pb-4"></div>

                        <p class="text-site-dark fs--10">Advertising Disclosure</p>
                        <p class="text-site-dark fs--10">
                            This site is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising
                            compensation from companies that appear on the site, which may impact the location and order in which brands (and/or their products) are
                            presented, and may also impact the score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all
                            providers on the market. Except as expressly set forth in our Terms of Use, all representations and warranties regarding the information
                            presented on this page are disclaimed. The information, including pricing, which appears on this site is subject to change at any time
                        </p>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style></style>
