<template>
  <div class="text-left">
    <h6 class="fs--28 fw--700">U.S. Bank Disclaimer</h6>
    <p class="fs--17">
      *Earn up to $1,000 when you open a new U.S. Bank Smartly® Checking account
      and a Standard Savings account online and complete qualifying activities.
      Subject to certain terms and limitations. Offer valid through September 6,
      2023. Member FDIC.
    </p>
    <p class="fs--17 mt-5">
      Applicant must reside in AZ, AR, CA, CO, ID, IL, IN, IA, KS, KY, MN, MO,
      MT, NE, NV, NM, NC, ND, OH, OR, SD, TN, UT, WA, WI, WY to be eligible for
      Checking account.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
