<template>
    <div>
        <HeaderAccounts :data="headerData" />
        <b-container class="d-md-block d-none"><toggle /></b-container>
        <FilterMobile />

        <div class="bg-site-secondary-lite extraPaddingTop">
            <b-container class="">
                <b-row class="border-bottom pb-lg-5 pb-4 mb-lg-5 mb-4">
                    <b-col xl="9">
                        <div v-for="(obj, index) in choiceData" :key="index" class="mb-3">
                            <TopChoice :objChoice="obj" :index="index" :topChoice="''" />
                        </div>
                    </b-col>

                    <b-col class="d-none d-xl-block" xl="3">
                        <sidebar-articles />
                    </b-col>
                </b-row>
                <div class="border-bottom pb-lg-5 pb-4 mb-lg-5 mb-4 smWhite">
                    <h4 class="text-left fs--20 fw--700 d-lg-block d-none">Our Top Choice</h4>
                    <div class="d-flex align-items-center d-lg-none d-block my-3">
                        <img src="../assets/TrendingList/crown.svg" />
                        <span class="mx-3 text-site-primary fs--20 fw--700">|</span>
                        <h4 class="text-left fs--20 fw--600 mb-0">Our Top Choice</h4>
                    </div>
                    <TopChoice :objChoice="choiceData[0]" :index="'01'" :topChoice="'Most Popular on Credit Grail'" />
                </div>

                <div class="pb-5">
                    <h4 class="text-left font-weight-bold mb-4 d-lg-block d-none">Review Our Top Online Accounts</h4>

                    <div v-for="(obj, index) in reviewOnlineAccount" :key="index" class="my-5 d-lg-block d-none">
                        <ReviewAccount :dataReview="obj" :index="index" :toggle="toggle" />
                    </div>
                    <hr class="my-5 d-lg-block d-none" />

                    <b-row no-gutters class="pl-lg-4 pl-0">
                        <b-col xs="12" lg="8">
                            <WhyOpenOnlineAccount />
                            <WhichAccountIsRight />
                            <hr class="my-5" />
                            <CompareOnlineAccount :compareData="compareOnline" />
                            <hr class="my-5" />

                            <OurTopClient />
                            <hr class="my-5" />
                            <CompareOnlineAccount :compareData="compareData" />
                            <LeadingOnlineAccount :data="prosCons" />
                            <USBankDisclaimer />
                            <hr class="my-5" />

                            <varo-money />
                            <hr class="my-5" />
                            <Chime />
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </div>
                <br />
                <br />
            </b-container>
        </div>
    </div>
</template>

<script>
import HeaderAccounts from '@/components/HeaderAccounts.vue';
import Toggle from '@/components/Toggle.vue';
import ReviewAccount from '../components/ReviewAccount.vue';
import SidebarArticles from '../components/SidebarArticles.vue';
import TopChoice from '../components/TopChoice.vue';
import brandLogo from '../assets/brandlogo.jpg';
import Trustpilotlogo from '../assets/Trustpilot-logo.svg';
import discover from '../assets/TrendingList/discover.png';
import upgrade from '../assets/TrendingList/upgrade.svg';
import current from '../assets/TrendingList/current.svg';

import BMO from '../assets/TrendingList/BMO.svg';
import Varo from '../assets/TrendingList/Varo.svg';
import hsbc from '../assets/TrendingList/hsbc.svg';
import winden from '../assets/winden-Logo.svg';
import citi from '../assets/TrendingList/citi.png';
import chrime from '../assets/TrendingList/chrime.png';
import starsm from '../assets/TrendingList/stargreen.svg';
import dollar from '../assets/dollar.svg';
import star from '../assets/TrendingList/star.svg';
import wallet from '../assets/wallet.svg';
import CompareOnlineAccount from '../components/CompareOnlineAccount.vue';
import rewardIcon from '../assets/Icon3.svg';
import FilterMobile from '../components/global/FilterMobile.vue';
import WhyOpenOnlineAccount from '../components/WhyOpenOnlineAccount.vue';
import WhichAccountIsRight from '../components/WhichAccountIsRight.vue';
import LeadingOnlineAccount from '../components/LeadingOnlineAccount.vue';
import VaroMoneyDisclaimer from '../components/VaroMoneyDisclaimer.vue';
import Chime from '../components/Chime.vue';
import UpgradeDisclaimer from '../components/UpgradeDisclaimer.vue';
import TopTableSaving from '../components/TopTableSaving.vue';
import OurTopClient from '../components/OurTopClient.vue';
import USBankDisclaimer from '../components/USBankDisclaimer.vue';
import VaroMoney from '../components/VaroMoney.vue';

export default {
    name: 'CheckingAccounts',
    components: {
        HeaderAccounts,
        Toggle,
        TopChoice,
        SidebarArticles,
        ReviewAccount,
        CompareOnlineAccount,
        FilterMobile,
        WhyOpenOnlineAccount,
        WhichAccountIsRight,
        LeadingOnlineAccount,
        VaroMoneyDisclaimer,
        Chime,
        UpgradeDisclaimer,
        TopTableSaving,
        OurTopClient,
        USBankDisclaimer,
        VaroMoney,
    },
    data() {
        return {
            headerData: {
                heading: 'Online Checking Accounts',
                subHeading: 'Secure your everyday transactions',
                description: 'Benefit from a complete online management with no fees on your financial operations.',
            },
            prosCons: {
                header: 'Explore Leading Online Checking Account',
                title: 'Chime',
                icon: chrime,
                description1:
                    "  Chime is an award-winning financial app and debit card for individuals who want to enjoy fee free services and ramp up their savings. Chime's checking account comes with a Chime Visa® Debit Card, and provides an easy  to use and enjoyable mobile app and online experience",
                description2:
                    '*Chime is a financial technology company, not a bank. Banking services and debit card provided by The Bancorp Bank N.A. or Stride Bank, N.A.;  Members FDIC.',

                pros: [
                    {
                        list: 'Automatic savings option',
                    },
                    {
                        list: '60,000+ fee-free˜ ATMs ',
                    },
                ],
                cons: [
                    {
                        list: 'No joint accounts or secondary cards',
                    },
                    {
                        list: 'No branch locations',
                    },
                ],
            },

            reviewOnlineAccount: [
                {
                    review: '2,597',
                    logo: winden,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No Fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '6.0',
                            description: 'Customer service during extended business hours',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent user friendly site with transparency of rates and full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['No starting or maintenance fees on Standard account', 'Automatically set aside money for taxes', 'Get your direct deposit early'],
                    consData: ['Limited withdrawal/deposit opportunities'],
                    check: false,
                    title: 'Winden',
                },
                {
                    review: '235',
                    logo: discover,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '10.0',
                            description: '24/7 service with multiple communication options, dedicated fraud team',
                        },
                        {
                            name: 'Online experience',
                            price: '8.0',
                            description: 'User friendly site with full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['1% cash back on debit purchases. See website for details', 'No fees', 'Comprehensive fraud protection'],
                    consData: ['Only one physical branch location', 'No interest paid on the checking account'],
                    check: false,
                    title: 'Discover Bank Cashback Debit Checking',
                },
            ],
            choiceData: [
                {
                    rewardIcon: rewardIcon,
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: 'show',
                    icon: star,
                    iconsm: starsm,
                    logo: winden,
                    reviewValue: 9.8,
                    dollar: dollar,
                    siteView: '1,271',
                    earning: '60K+ Fee-Free ATMs˜',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Winden',
                        subtitle: 'Banking designed for entrepreneurs',
                        anualPercntage: 'Best for new and small businesses',
                        list: ['Free Wire and ACH transfers', 'Earn unlimited 1% cash-back on all purchases', 'No minimum deposit required'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: wallet,
                    logo: discover,
                    reviewValue: 9.3,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'Discover Bank Cashback Debit Checking',
                        subtitle: 'Zero fees and cashback rewards',
                        anualPercntage: 'Apply online in minutes',
                        list: [
                            '1% cash back on debit purchases. See website for details',
                            'No. Fees. Period.',
                            'Get paid early with direct deposit',
                            'Fee-free overdraft protection transfer service',
                        ],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: brandLogo,
                    reviewValue: 9.1,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'SoFi Checking and Savings',
                        subtitle: 'Earn 7x the national checking rate',
                        anualPercntage: '',
                        list: [
                            'Get 0.50% APY on your checking balances',
                            'Earn up to $250 with qualifying direct deposit, terms apply',
                            'No account fees or balance minimums',
                        ],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: citi,
                    reviewValue: 9.1,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'Citi® Priority Relationship Tier',
                        subtitle: 'Citi Mobile® App available to make banking easy',
                        anualPercntage: '',
                        list: [
                            'Earn up to $2,000 cash bonus when you open a new eligible checking account in the Citi Priority Relationship Tier',
                            'Access cash at 70,000+ Fee-Free ATMs in the U.S.',
                            'New To Relationship customers can choose their Relationship Tier',
                        ],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: hsbc,
                    reviewValue: 8.2,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'HSBC Premier',
                        subtitle: 'Seamlessly manage your worldwide finances',
                        anualPercntage: '',
                        list: [
                            'Free International account opening and portable credit history',
                            'Access your global HSBC accounts from one place with Global View',
                            'No HSBC incoming or outgoing wire transfer fees',
                        ],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: Varo,
                    reviewValue: 8.7,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'Varo Bank',
                        subtitle: 'Zero monthly fees for checking & savings',
                        anualPercntage: '',
                        list: ['Live customer service available all week', 'No minimum balance', 'Easily sync phone with account via app'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: current,
                    reviewValue: 8.2,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'Current',
                        subtitle: 'Mobile banking account that moves at your pace',
                        anualPercntage: '',
                        list: ['Sign up in under 2 minutes', 'Find free in-network ATMs on the Current app', 'Waive overdraft fees with Overdrive™'],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: upgrade,
                    reviewValue: 4.2,
                    earning: null,
                    siteView: null,
                    buttonTextsm: 'View Rates',
                    buttonText: 'Learn more',
                    topChoiceContent: {
                        title: 'Upgrade Rewards Checking Plus',
                        subtitle: 'Dedicated to providing users with more value',
                        anualPercntage: '',
                        list: ['Up to 2% cash back on purchases¹', 'Get paid up to 2 days early²', 'No monthly fees³'],
                    },
                },
            ],
            compareData: {
                title: 'What Services do Online Checking Accounts Offer?',
                description:
                    'Online checking accounts offer all the same functions as traditional checking accounts—and more. Online checking accounts have revolutionized banking, allowing users to do everything they would have previously done in-branch from the comfort of their own home, or from on the go.',
                featureTitle: 'Here are some of the features usually offered with an online checking account:',
                featureData: [
                    {
                        name: 'Domestic and international wire transfers -',
                        explanation: '<span>Send and receive money between your account and another person’s account.</span>',
                    },
                    {
                        name: 'Transfer money between accounts -',
                        explanation: `<span>Transfer money between your own checking and <a href="/" class="text-decoration-underline text-site-dark">savings accounts</a> .</span>`,
                    },
                    {
                        name: 'Multiple account integration -',
                        explanation: '<span> Keep your accounts organized and easy to access.</span>',
                    },
                    {
                        name: 'Bill payment - ',
                        explanation: '<span>Stay on top of your bills and set reminders so you never miss a beat.</span>',
                    },
                    {
                        name: 'Digital check deposit -',
                        explanation: '<span>Instantly scan and deposit a check with your smartphone and get access to the funds straight away. </span>',
                    },
                    {
                        name: 'Digital/downloadable account statements -',
                        explanation: '<span>Great for managing your budget, and for instantly sending records to your accountant.</span>',
                    },
                    {
                        name: 'Mobile banking app -',
                        explanation: '<span> Manage your accounts from your mobile device.</span>',
                    },
                    {
                        name: 'Mobile payments -',
                        explanation: '<span>Make purchases straight from your account with Google Pay, Apple Pay, or Samsung Pay.</span>',
                    },
                    {
                        name: 'Sophisticated security and encryption -',
                        explanation: '<span>Keep your data safe with multi-factor authentication and extra-strong encryption.</span>',
                    },
                    {
                        name: 'FDIC Insurance -',
                        explanation: '<span>Deposits insured up to at least $250,000 (and sometimes as high as $1.5 million, depending on the bank).</span>',
                    },
                    {
                        name: 'SMS / email alerts - ',
                        explanation: '<span>Notifications whenever any unusual or suspicious activity occurs in relation to your bank account.</span>',
                    },
                    {
                        name: 'ATMs -',
                        explanation:
                            '<span> Withdraw money from thousands of ATMs in the US and around the world, and get reimbursements from third-party ATM withdrawals.</span>',
                    },
                    {
                        name: 'Credit card rewards -',
                        explanation: '<span>Get points for making purchases with your account-linked credit card</span>',
                    },
                ],
            },

            compareOnline: {
                title: 'How to Compare Online Checking Accounts',
                description:
                    'No 2 checking accounts are equal, so it’s worth doing a comparison shop before settling on an online bank. The following is a checklist of things to focus on when comparing online checking accounts.',
                featureTitle: '',
                featureData: [
                    {
                        name: 'Monthly Fee:',
                        explanation: `<span>Some online checking accounts come with no monthly fees while others come with a flat monthly fee (typically ranging from $1 to $50). Typically, banks agree to waive the monthly fee if the customer keeps their balance above a minimum amount (e.g. $1,000 or $10,000). Always ask what you’re getting for your fee. For a basic checking account, there shouldn’t be any fee. For an account that’s packed with features, a fee is considered acceptable.<a href=""> Chime*</a> an award-winning financial app and debit card, offer overdraft protection up to $200 without fees on top of zero monthly, transaction, and foreign fees. *Chime is a financial technology company, not a bank. Banking services provided by, and debit card issued by, The Bancorp Bank or Stride Bank, N.A.; Members FDIC.</span>`,
                    },
                    {
                        name: 'Additional Fees:',
                        explanation: `<span>Online banks always charge a fee for international transfers and some also charge for domestic transfers. Most online banks offer free ATM withdrawals from their own ATMs and reimbursements from third-party ATMs.<a href=""> Varo </a>has no-fee ATM withdraws from over 55,000 Allpoint® ATMs, which are conveniently placed in stores like Walgreens, CVS, Target, and more.</span>`,
                    },
                    {
                        name: 'Customer service: ',
                        explanation: `<span>Some online banks have physical branches, while others operate exclusively online. Some offer personalized customer service (usually for a monthly fee) while others make you wait on the phone with everyone else. Some offer live chat straight from your online or <a href="" class="text-site-dark text-decoration-underline"> mobile bank account</a> others don’t. Many online banks offer round-the-clock customer service. </span>`,
                    },
                    {
                        name: 'Savings accounts:',
                        explanation: `<span>Some banks only offer checking accounts. Others offer a selection of checking accounts,<a href="" class="text-site-dark text-decoration-underline">savings accounts</a> , and certificates of deposit. Better yet, some banks offer hybrid checking-savings accounts that let the customer simultaneously have everyday access to their account and save money.</span>`,
                    },

                    {
                        name: 'Credit or debit cards:',
                        explanation: `<span>Like regular checking accounts, online checking accounts usually come with the option of linking a credit or debit card to the account. Online banks are increasingly offering customers a choice of credit card plans, each with a different rate and different set of features and benefits.</span>`,
                    },

                    {
                        name: 'Loans:',
                        explanation: `Another benefit of <a href="" class="text-site-dark text-decoration-underline">opening an online checking account</a>  is it can give you easy access to loans. Depending on the bank, there may be personal loans, home loans, auto loans, and many other types of lending products. Online loans offer the same benefits as online checking accounts: greater convenience, lower fees, and quicker applications.`,
                    },
                ],
            },
        };
    },
    methods: {
        toggle(id, value) {
            console.log('id,value', id, value);
            this.reviewOnlineAccount[id].check = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.extraPaddingTop {
    padding-top: 65px;
    @media (max-width: 991.98px) {
        padding-top: 20px;
    }
}
</style>
