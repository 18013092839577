<template>
    <div class="">
        <div class="border text-left p-3 bg-white">
            <b-row class="align-items-center" no-gutters>
                <b-col cols="8" class="pr-3">
                    <h4 class="m-0 fs--20 fw--600">20,948 visitors</h4>
                    <p class="fs--14 m-0 mt-2">chose an account with CreditGrail this week</p>
                </b-col>
                <b-col cols="4">
                    <img src="../assets/piggi.svg" />
                </b-col>
            </b-row>
        </div>
        <b-row class="justify-content-center mt-5">
            <b-col md="10">
                <h4 class="fw--600 text-left fs--22">CreditGrail Total Score</h4>
                <p class="text-left mt-2 fs--14">Our product scores consist of a combination of the following 3 components:</p>
                <best-money-dropdown
                    :icon="require('../assets/Icon1.svg')"
                    title="Popularity"
                    description="CreditGrail’s measures user engagement based on the number of clicks each listed brand received in the past 7 days. The number of clicks to each brand will be measured against other brands listed in the same query. Therefore, the higher the share of clicks a brand receives in any specific query, the higher the Click Trend Score. CreditGrail accepts advertising compensation from companies, which impacts their (and/or their products’) position, and in some cases, may also affect their Click Trend Score."
                />
                <best-money-dropdown
                    :icon="require('../assets/Icon2.svg')"
                    title="Brand Reputation"
                    description="Semrush is a trusted and comprehensive tool that offers insights about online visibility and performance. The CreditGrail Total Score will consist of the brand's reputation from Semrush. The brand reputation is based on Semrush's analysis of clickstream data, which includes user behavior, search patterns, and engagement, to accurately measure each brand's prominence, credibility, and trustworthiness. If a brand does not have a Semrush score, the CreditGrail Total Score will be based solely on the Click Trend Score and Products & Features Score (read below)."
                />
                <best-money-dropdown
                    :bottomLine="false"
                    class="mb-3"
                    :icon="require('../assets/Icon3.svg')"
                    title="Features & benefits"
                    description="CreditGrail’s editorial team researches and reviews financial products based on factors such as: range of products and services offered, ease-of-use, online accessibility, customer service, special awards, and more. Each brand is then given a score based on the offerings in each parameter. The specific parameters which we use to evaluate the score of each product can be found on its review page, which is updated every 3 months. If the editorial team cannot locate information relevant to a brand's Products & Services Score, it will not be included in its calculation."
                />
            </b-col>
        </b-row>
        <hr />
        <div class="text-left my-5">
            <b-row class="justify-content-center">
                <b-col md="10">
                    <h5 class="fs--18 fw--600 text-site-black text-left">Editorial Reviews</h5>
                    <div v-for="review of reviews" :key="review.id" class="mt-4 d-flex align-items-center">
                        <div class="review-icon">
                            <img :src="review.icon" />
                        </div>
                        <div class="d-flex ml-3 flex-column">
                            <a class="text-site-dark review-name">{{ review.name }}</a>
                            <a class="text-site-dark review-link">Read Review</a>
                        </div>
                    </div>

                    <a href="" class="all-reviews mt-4">View all reviews</a>
                </b-col>
            </b-row>
        </div>
        <b-row class="justify-content-center pt-4 mt-5">
            <b-col md="10">
                <h5 class="fs--18 fw--700 text-site-black text-left">Must Reads</h5>
                <router-link to="#" class="d-flex flex-column text-left text-decoration-none text-dark">
                    <small class="must-read__text">How to Choose the Right Online Bank for You</small>
                    <small class="text-muted mt-1" style="font-size: 12px">Oct. 28, 2022</small>
                </router-link>
                <router-link to="#" class="d-flex mt-3 flex-column text-left text-decoration-none text-dark">
                    <small class="must-read__text">Small Business Owners, Choose an Account That Fits Your Lifestyle</small>
                    <small class="text-muted mt-1" style="font-size: 12px">Apr. 21, 2022</small>
                </router-link>
                <router-link to="#" class="d-flex mt-3 flex-column text-left text-decoration-none text-dark">
                    <small class="must-read__text">How Banks Are Building Trust with Customers in 2023</small>
                    <small class="text-muted mt-1" style="font-size: 12px">Oct. 28, 2022</small>
                </router-link>
                <router-link to="#" class="d-flex mt-2 flex-column text-left text-decoration-none text-dark">
                    <small href="" class="all-reviews mt-2">Read all articles</small>
                </router-link>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Lili from '../assets/lili-Favicon-FS.20200901115106.webp';
import winden from '../assets/winden.svg';
import Relay from '../assets/logo.20230607074652.webp';
import Bluevine from '../assets/businessicon3.svg';
import Mercury from '../assets/Mercury-logo.svg';
import Found from '../assets/Found-logo.webp';
import BestMoneyDropdown from './BestMoneyDropdown.vue';
export default {
    components: { BestMoneyDropdown },
    data() {
        return {
            reviews: [
                {
                    id: 1,
                    name: 'Winden',
                    icon: winden,
                },
                {
                    id: 2,
                    name: 'Relay',
                    icon: Relay,
                },
                {
                    id: 3,
                    name: 'Bluevine',
                    icon: Bluevine,
                },
                {
                    id: 4,
                    name: 'Mercury',
                    icon: Mercury,
                },
                {
                    id: 5,
                    name: 'Found',
                    icon: Found,
                },
            ],
        };
    },
};
</script>

<style></style>
