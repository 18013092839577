<template>
  <div class="text-left text-site-dark">
    <h6 class="fs--20 fw--700 mb-3">Which Account Is Right For You?</h6>
    <div class="overflow-auto">
      <table class="table mt-1 text-center which-account">
        <thead>
          <tr>
            <th></th>
            <th>
              <router-link to="#" class="fw--500">Savings Account</router-link>
            </th>
            <th>
              <router-link to="#" class="fw--500">Checking Account</router-link>
            </th>
            <th>
              <router-link to="#" class="fw--500"
                >Business Checking Account</router-link
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Earns interest</td>
            <td>✓</td>
            <td>✓ (varies by bank)</td>
            <td>✓</td>
          </tr>
          <tr v-for="account of accounts" :key="account">
            <td>
              {{ account }}
            </td>
            <td>✓</td>
            <td>✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td>Our Top Choice:</td>
            <td>
              <img class="top-choice-img" :src="Discover" />
            </td>
            <td>
              <img class="top-choice-img" :src="Chime" />
            </td>
            <td>
              <img class="top-choice-img" :src="Relay" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Chime from "../assets/Chime.webp";
import Discover from "../assets/Discover.webp";
import Relay from "../assets/Relay.webp";
export default {
  data() {
    return {
      accounts: [
        "FDIC-insured",
        "ATM access",
        "Debit card access",
        "Checking writing access",
      ],
      Discover,
      Chime,
      Relay,
    };
  },
};
</script>

<style></style>
