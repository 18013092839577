<template>
    <div>
        <HeaderAccounts :data="headerData" />
        <b-container class="d-md-block d-none">
            <toggle />
        </b-container>
        <FilterMobile />

        <div class="bg-site-secondary-lite extraPaddingTop">
            <b-container class="">
                <b-row class="border-bottom pb-lg-5 pb-4 mb-lg-5 mb-4">
                    <b-col xl="9">
                        <div v-for="(obj, index) in choiceData" :key="index" class="mb-3">
                            <TopChoice :objChoice="obj" :index="index" :topChoice="''" />
                        </div>
                    </b-col>

                    <b-col class="d-none d-xl-block" xl="3">
                        <sidebar-articles />
                    </b-col>
                </b-row>
                <div class="border-bottom pb-lg-5 pb-4 mb-lg-5 mb-4 smWhite">
                    <h4 class="text-left fs--20 fw--700 d-lg-block d-none">Our Top Choice</h4>
                    <div class="d-flex align-items-center d-lg-none d-block my-3">
                        <img src="../assets/TrendingList/crown.svg" />
                        <span class="mx-3 text-site-primary fs--20 fw--700">|</span>
                        <h4 class="text-left fs--20 fw--600 mb-0">Our Top Choice</h4>
                    </div>
                    <TopChoice :objChoice="choiceData[0]" :index="'01'" :topChoice="'Most Popular on Credit Grail'" />
                </div>
                <div class="border-bottom pb-5 mb-5 d-lg-block d-none">
                    <h4 class="text-left font-weight-bold mb-4">Review Our Top Online Accounts</h4>

                    <div v-for="(obj, index) in reviewOnlineAccount" :key="index">
                        <ReviewAccount :dataReview="obj" :index="index" :toggle="toggle" />
                    </div>
                </div>
                <b-row no-gutters>
                    <b-col xs="12" lg="8">
                        <WhyOpenOnlineSavingAccount />
                        <hr class="my-5" />
                        <which-account-is-right />
                        <hr class="my-5" />

                        <CompareOnlineAccount :compareData="compareOnline" />
                        <hr class="my-5 d-md-block d-none" />

                        <div class="d-md-block d-none"><TopTableSaving /></div>
                        <hr class="my-5" />

                        <CompareOnlineAccount :compareData="compareData" />
                        <hr class="d-md-block d-none my-5" />
                        <h4 class="d-md-block d-none text-left fs--20 fw--600 mb-0">Explore Leading Online Savings Account</h4>
                        <ExploreAccount class="d-md-block d-none" :data="prosCons" />
                        <DisclaimerSaving class="d-md-block d-none" />
                    </b-col>
                    <b-col></b-col>
                </b-row>
                <!-- <hr class="my-5" />
        <b-row no-gutters
          ><b-col xs="12" lg="8"> <which-account-is-right /> </b-col
          ><b-col></b-col
        ></b-row> -->

                <!-- <div
          v-for="(obj, index) in reviewOnlineAccount"
          :key="index"
          class="my-5"
        >
          <ReviewAccount :dataReview="obj" :index="index" :toggle="toggle" />
        </div> -->
                <!-- <b-row no-gutters
          ><b-col xs="12" lg="8">
            <CompareOnlineAccount :compareData="compareOnline" /> </b-col
          ><b-col></b-col
        ></b-row>
        <b-row no-gutters class="pb-5"
          ><b-col xs="12" lg="8">
            <CompareOnlineAccount :compareData="compareData" /> </b-col
          ><b-col></b-col
        ></b-row> -->
                <br />
                <br />
                <br />
            </b-container>
        </div>
    </div>
</template>

<script>
import Toggle from '@/components/Toggle.vue';
import HeaderAccounts from '../components/HeaderAccounts.vue';
import ReviewAccount from '../components/ReviewAccount.vue';
import SidebarArticles from '../components/SidebarArticles.vue';
import TopChoice from '../components/TopChoice.vue';
import discover from '../assets/TrendingList/discover.png';
import Trustpilotlogo from '../assets/Trustpilot-logo.svg';
import brandLogo from '../assets/brandlogo.jpg';
import starsm from '../assets/TrendingList/stargreen.svg';
import barclays from '../assets/TrendingList/barclays.png';
import citi from '../assets/TrendingList/citi.png';
import customerbank from '../assets/TrendingList/customerbank.png';
import saving from '../assets/TrendingList/saving.svg';
import star from '../assets/TrendingList/star.svg';
import synchrony from '../assets/TrendingList/synchrony.jpg';
import western from '../assets/TrendingList/western.png';
import winden from '../assets/winden-Logo.svg';
import dollar from '../assets/dollar.svg';
import Chime from '../components/Chime.vue';
import CompareOnlineAccount from '../components/CompareOnlineAccount.vue';
import OurTopClient from '../components/OurTopClient.vue';
import USBankDisclaimer from '../components/USBankDisclaimer.vue';
import VaroMoney from '../components/VaroMoney.vue';
import WhichAccountIsRight from '../components/WhichAccountIsRight.vue';
import WhyOpenOnlineAccount from '../components/WhyOpenOnlineAccount.vue';
import WhyOpenOnlineSavingAccount from '../components/WhyOpenOnlineSavingAccount.vue';
import FilterMobile from '../components/global/FilterMobile.vue';
import TopTableSaving from '../components/TopTableSaving.vue';
import DisclaimerSaving from '../components/FinancialProducts/DisclaimerSaving.vue';
import ExploreAccount from '../components/ExploreAccount.vue';
import rewardIcon from '../assets/Icon3.svg';

export default {
    name: 'SavingAccounts',
    components: {
        HeaderAccounts,
        Toggle,
        TopChoice,
        SidebarArticles,
        ReviewAccount,
        CompareOnlineAccount,
        WhyOpenOnlineSavingAccount,
        WhyOpenOnlineAccount,
        WhichAccountIsRight,
        OurTopClient,
        USBankDisclaimer,
        VaroMoney,
        Chime,
        FilterMobile,
        TopTableSaving,
        DisclaimerSaving,
        ExploreAccount,
    },
    data() {
        return {
            headerData: {
                heading: 'Online Savings Accounts',
                subHeading: 'Make your money work for you',
                list: [{ text: '✓ Grow your savings with high-APY accounts' }, { text: '✓ 24/7, easy-to-use, secure banking tools' }],
            },
            reviewOnlineAccount: [
                {
                    review: '1,241',
                    logo: winden,
                    Trustpilotlogo: Trustpilotlogo,
                    data: [
                        {
                            name: 'Fees?',
                            price: '10.0',
                            description: 'No Fees at all',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured, SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '6.0',
                            description: 'Customer service during extended business hours',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent user friendly site with transparency of rates and full online banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Excellent mobile app with all mobile banking features enabled, including digital check deposits',
                        },
                    ],
                    prosData: ['No starting or maintenance fees on Standard account', 'Automatically set aside money for taxes', 'Get your direct deposit early'],
                    consData: ['Limited withdrawal/deposit opportunities'],
                    check: false,
                    title: 'Winden',
                },

                {
                    review: '235',
                    logo: discover,
                    Trustpilotlogo: Trustpilotlogo,
                    title: 'Discover High Yield Online Savings Account',
                    data: [
                        {
                            name: 'Fees?',
                            price: '8.0',
                            description: 'No monthly fees',
                        },
                        {
                            name: 'Security',
                            price: '10.0',
                            description: 'FDIC insured SSL/AES encryption and supports 2 factor and biometric authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '10.0',
                            description: '24/7 customer service via phone',
                        },
                        {
                            name: 'Online experience',
                            price: '10.0',
                            description: 'Excellent user friendly site, full banking capabilities',
                        },
                        {
                            name: 'Mobile experience',
                            price: '10.0',
                            description: 'Full-service mobile app with mobile check deposit',
                        },
                    ],
                    prosData: ['No minimum deposit requirement', 'High yields savings account', 'No monthly fees', 'Incredible mobile app experience'],
                    consData: ['Only one physical location'],
                    check: false,
                },

                {
                    review: '11',
                    logo: citi,
                    Trustpilotlogo: Trustpilotlogo,
                    title: 'Citi® Accelerate Savings',
                    data: [
                        {
                            name: 'Fees?',
                            price: '8.0',
                            description: 'Fees waived with minimum balance',
                        },
                        {
                            name: 'Security',
                            price: '9.0',
                            description: 'FDIC insured, SSL encryption, multi-factor authentication',
                        },
                        {
                            name: 'Customer service',
                            price: '9.0',
                            description: '24/7 customer service',
                        },
                        {
                            name: 'Online experience',
                            price: '9.0',
                            description: 'Excellent user-friendly site',
                        },
                        {
                            name: 'Mobile experience',
                            price: '9.0',
                            description: 'Excellent mobile app',
                        },
                    ],
                    prosData: [
                        ' No minimum deposit to open checking account',
                        'Wide range of savings accounts, CDs and IRAs',
                        'Solutions for small businesses',
                        'Range of lending products',
                    ],
                    consData: [
                        'Some features and rates limited to residents of certain states',
                        'Minimum balance to use checking accounts',
                        'Lacks features compared to native online banks',
                    ],
                    check: false,
                },
            ],
            choiceData: [
                {
                    rewardIcon: rewardIcon,
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: 'show',
                    icon: star,
                    iconsm: starsm,
                    logo: winden,
                    reviewValue: 9.8,
                    dollar: dollar,
                    siteView: '1,271',
                    earning: 'Earn up to $250*',
                    buttonTextsm: 'View Rates',
                    buttonText: 'EXPLORE',
                    topChoiceContent: {
                        title: 'Winden',
                        subtitle: 'Banking designed for entrepreneurs',
                        anualPercntage: 'Best for new and small businesses',
                        list: ['Free Wire and ACH transfers', 'Earn unlimited 1% cash-back on all purchases', 'No minimum deposit required'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: dollar,
                    logo: discover,
                    reviewValue: 4.2,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'Discover High Yield Online Savings Account',
                        subtitle: 'Recommended for higher deposit amounts',
                        anualPercntage: 'Earn 4.35% Annual Percentage Yield',
                        list: [
                            'Use code Nl923 to open a new Discover Savings account and earn up to a $200 bonus*',
                            'See advertiser website for full details on offer above',
                            'FDIC insured, no fees and 24/7 US based customer service',
                        ],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: dollar,
                    logo: citi,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW DETAILS',
                    buttonTextsm: 'View Details',
                    topChoiceContent: {
                        title: 'Citi Accelerate Savings',
                        subtitle: "Citi Mobile' App available to make banking easy",
                        anualPercntage: '4.45% Annual Percentage Yield with $0 min to earn',
                        list: ['Variable rate as of Thursday, November 16th 2023 may change after account opening*', 'Grow your money securely', 'Member FDIC'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: western,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'Western Alliance High Yield Savings',
                        subtitle: 'Earn more with a secure saving account',
                        anualPercntage: '',
                        list: ['Earn 5.26% APY', '$l minimum deposit to open', 'FDIC insured'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: dollar,
                    logo: saving,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'American Express National Bank',
                        subtitle: 'Earn more interest than your average savings account',
                        anualPercntage: '4.30% APY as of Nov 16th 2023, Terms Apply',
                        list: ['Member FDIC', 'Link account to Quicken or QuickBooks', '$0 monthly fees, competitive rates, $1 min to earn'],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: 'show',
                    icon: '',
                    logo: synchrony,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'Synchrony',
                        subtitle: 'Savings products with competitive rates and no minimum deposit',
                        anualPercntage: '4.75% Annual Percentage Yield with no min to earn',
                        list: ['No monthly fees', ' Member FDIC', 'Control accounts from your smartphone or tablet'],
                    },
                },
                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: 'show',
                    icon: '',
                    logo: customerbank,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'Customers Bank Savings',
                        subtitle: 'Grow your savings account easily',
                        anualPercntage: '',
                        list: ['Earn 5.30%  APY*', 'No fees, $1 minimum deposit', 'FDIC insured'],
                    },
                },

                {
                    popularity: 20,
                    brandReputation: 50,
                    editorialReviews: 70,
                    editorialLink: '',
                    link: '',
                    background: '',
                    icon: '',
                    logo: barclays,
                    reviewValue: 8.9,
                    earning: null,
                    siteView: null,
                    buttonText: 'VIEW RATES',
                    buttonTextsm: 'View Rates',
                    topChoiceContent: {
                        title: 'Barclays Online Savings',
                        subtitle: 'Premium bank with online services',
                        anualPercntage: '',
                        list: ['Earn 4.35% APY*', 'Virtual tools to help you save', 'No monthly fees or minimums'],
                    },
                },
            ],
            compareOnline: {
                title: 'How to Compare Online Savings Accounts',
                description:
                    'Each online savings account is unique, so it’s important to go comparison shopping before deciding where to park your money. The following is a list of the top things to focus on when comparing online savings accounts.',
                featureTitle: '',
                featureData: [
                    {
                        name: 'APY:',
                        explanation:
                            '<span> This is the most important thing to look for when comparing online savings accounts, given that the entire point of a savings account is to save as much as possible. Today’s APYs for online savings accounts range from as low as 0.01% to as high as 2.25%. The higher the rate, the greater the likelihood that there are minimum requirements (such as a minimum balance) attached.</span>',
                    },
                    {
                        name: 'Fees:',
                        explanation: `<span>Online banks typically charge lower fees than traditional banks, but that doesn’t mean their services come completely free of charge. Some banks charge a small monthly fee for maintaining an account (or for going below a pre-determined minimum balance). There may also be fees for taking certain actions such as withdrawals from third-party ATMs or international transfers.<a href=""> Chime® * </a>, an award-winning financial app and debit card, offers overdraft protection up to $200 without fees on top of zero monthly, transaction, and foreign fees.<a href="">Varo </a> has no-fee ATM withdraws from over 55,000 Allpoint® ATMs, which are conveniently placed in stores like Walgreens, CVS, Target, and more. *Chime is a financial technology company, not a bank. Banking services and debit card issued by The Bancorp Bank N.A. or Stride Bank, N.A.; Members FDIC. </span>`,
                    },
                    {
                        name: 'Minimum requirements:',
                        explanation:
                            '<span>As mentioned, savings accounts often come with minimum requirements that the customer must commit to in order to receive the promised APR. The requirement usually takes the form of a minimum monthly balance or minimum opening deposit. </span> ',
                    },
                    {
                        name: 'CDs:',
                        explanation:
                            '<span>An online certificate of deposit, or online CD, is an alternative type of online savings account. With a CD, the customer must lock in their money for a certain period of time, e.g. 3 months, 12 months, or 6 years. Generally speaking, the longer the customer locks their money in for, the better the rate. At the time of writing, online banks were offering 5-year CDs with as high as 3.10% APY, more than double the national average of around 1.30%.</span>',
                    },
                    {
                        name: 'Checking services:',
                        explanation:
                            '<span>Increasingly, online banks are offering hybrid checking-savings accounts that give the customer a combination of everyday banking services and a competitive savings rate. The best online savings accounts permit withdrawals (usually 4-6 per month), unlimited transfers between personal checking and savings accounts, and check deposits straight from a mobile app.</span>',
                    },
                    {
                        name: 'Customer service:',
                        explanation:
                            '<span>Increasingly, online banks are offering hybrid checking-savings accounts that give the customer a combination of everyday banking services and a competitive savings rate. The best online savings accounts permit withdrawals (usually 4-6 per month), unlimited transfers between personal checking and savings accounts, and check deposits straight from a mobile app.</span>',
                    },
                ],
            },
            compareData: {
                title: 'What Services do Online Savings Accounts Offer?',
                description: '',
                featureTitle: 'Here are some of the services users can expect to be offered with the best online savings accounts: ',
                featureData: [
                    {
                        name: 'Transfer money between accounts –',
                        explanation: '<span>Unlimited transfers between your personal savings and checking accounts</span>',
                    },
                    {
                        name: 'User-friendly dashboard –',
                        explanation: '<span>Track all bank accounts and download statements from the one online or mobile dashboard.</span>',
                    },
                    {
                        name: 'Digital check deposit -',
                        explanation: '<span> Instantly scan and deposit a check with your smartphone and see the funds land in your savings account right away.</span> ',
                    },
                    {
                        name: 'Savings and budgeting tools – ',
                        explanation: '<span>Set savings goals, track your progress, and get useful tips on how to make the most of your savings account or CD</span>',
                    },
                    {
                        name: 'Mobile banking app -',
                        explanation: '<span> Manage your accounts from your mobile device.</span>',
                    },
                    {
                        name: 'Sophisticated security and encryption -',
                        explanation: '<span>Keeps your data safe with multi-factor authentication and high standards of encryption.</span>',
                    },
                    {
                        name: 'FDIC Insurance –',
                        explanation: '<span>Deposits insured up to at least $250,000 (and sometimes as high as $1.5 million, depending on the bank).</span>',
                    },

                    {
                        name: 'SMS / email alerts - ',
                        explanation: '<span>Notifications whenever any unusual or suspicious activity occurs in relation to your bank account.</span>',
                    },
                    {
                        name: 'ATMs -',
                        explanation:
                            '<span> Withdraw money from thousands of ATMs in the US and around the world, and get reimbursements from third-party ATM withdrawals.</span>',
                    },
                    {
                        name: 'Add-ons -',
                        explanation:
                            '<span> In an effort to entice customers, many online banks offer add-ons to savings accounts. These include rewards programs, ID theft resolution services, roadside assistance, reduced loan rates, third-party partner discounts, and free wire transfers.</span>',
                    },
                ],
            },

            prosCons: {
                data: [
                    {
                        title: 'Discover',
                        icon: discover,
                        hideIndex: true,
                        link: 'Discover',
                        to: 'https://lp.lili.co/lili-business/?utm_term=desktop&utm_content=bestmoney-business-banking&utm_medium=partners&utm_source=Natural%20Intelligence%20LTD%2C&utm_partner=2QP1JgUo7xyPR4HV4SXUZzKhUkFXkS25US4i0w0&irgwc=1',
                        description1:
                            'online banking offers customers several products including convenient checking accounts with 1% cashback on monthly debit card purchases, high-yield savings accounts, money market accounts, CDs, and IRAs with equally impressive interest rates.',

                        pros: [
                            {
                                list: 'No minimum deposit requirement',
                            },
                            {
                                list: 'No monthly fees',
                            },
                        ],
                        cons: [
                            {
                                list: 'Only one physical location',
                            },
                            {
                                list: 'Outgoing wire transfer fees',
                            },
                        ],
                    },
                ],
            },
        };
    },
    methods: {
        toggle(id, value) {
            console.log('id,value', id, value);
            this.reviewOnlineAccount[id].check = value;
        },
    },
};
</script>
<style lang="scss" scoped>
.extraPaddingTop {
    padding-top: 65px;
    @media (max-width: 991.98px) {
        padding-top: 0px;
    }
}
</style>
