<template>
    <b-container class="bg-header">
        <div class="header position-relative text-right d-md-block d-none">
            <b-link id="addTooltip" class="text-site-dark header__link fs--14 mt-2">Advertising Disclosure</b-link>

            <b-tooltip custom-class="add" target="addTooltip" variant="light" triggers="hover" placement="bottomleft">
                This site is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation from
                companies that appear on the site, which may impact the location and order in which brands (and/or their products) are presented, and may also impact the
                score that is assigned to it. Company listings on this page DO NOT imply endorsement. We do not feature all providers on the market. Except as expressly
                set forth in our
                <b-link to="#" class="fw--700 text-site-dark text-decoration-underline">Terms of Use</b-link>
                , all representations and warranties regarding the information presented on this page are disclaimed. The information, including pricing, which appears on
                this site is subject to change at any time
            </b-tooltip>
        </div>
        <div class="business-header pt-5 d-md-block d-none">
            <b-row>
                <b-col>
                    <div class="text-center">
                        <p class="fs--12 text-site-secondary">
                            <img class="px-1" src="../assets/tick.png" alt="" />
                            Last updated
                            <span class="fw--600">November 2023</span>
                        </p>
                        <h1 class="fs--38 fw--600 text-site-secondary">
                            Best
                            <span class="text-site-primary">Online Business Checking Accounts</span>
                            <br />
                            <span class="mt-2 d-block">November 2023</span>
                        </h1>
                        <h1 class="fs--30 text-site-secondary fw--400">Set up your business for success</h1>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div class="d-md-none d-block">
            <b-row>
                <b-col>
                    <div class="text-center">
                        <p class="fs--14 mt-4">
                            <img class="px-1" src="../assets/savingaccountsicon.svg" alt="" />
                            Last updated
                            <span class="fw--600">November 2023</span>
                        </p>
                        <h1 class="fs--28 fw--600 text-site-dark">
                            Best
                            <span class="text-site-primary">Online Business Checking Accounts</span>
                            <!-- <br /> -->
                            <!-- <span class="mt-2 d-block">November 2023</span> -->
                        </h1>
                        <!-- <h1 class="fs--30 text-site-dark fw--400">
              Set up your business for success
            </h1> -->
                    </div>
                </b-col>
            </b-row>
        </div>
        <b-row class="mx-xl-5 mx-1 business-header__cards justify-content-md-center d-md-flex d-none">
            <b-col md="6" xl="4" class="pb-xl-0 pb-3 d-flex flex-column">
                <div
                    role="button"
                    @mousemove="(e) => handleMouseMove('interactiveDiv0')(e)"
                    @mouseleave="(e) => handleMouseLeave('interactiveDiv0')"
                    id="interactiveDiv0"
                    class="business-header__card shadow text-left"
                >
                    <img class="" src="../assets/logo.20230607074652.webp" alt="" height="40px" />
                    |
                    <span class="px-3 fw--600 fs--18">Relay</span>
                    <h1 class="fs--20 text-left fw--600 mt-3">Putting your in complete control of your...</h1>
                    <p class="fs--14">
                        Earn up to 3% APY with on
                        <br />
                        automated savings account
                    </p>

                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="fs--30 fw--700 m-0">9.35</div>
                            <div class="ml-1">
                                <div>
                                    <b-form-rating v-model="value" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                </div>

                                <div class="cursor-pointer" style="line-height: 0.8">
                                    <span class="fs--10 fw--600 mr-1">CreditGrail</span>
                                    <span class="fs--10">score</span>
                                </div>
                            </div>
                        </div>
                        <div class="business-header__button ml-3">
                            <span class="fs--12 fw--700">EXPLORE</span>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col md="6" xl="4" class="mt-xl-n5 pb-xl-0 pb-3 d-flex flex-column">
                <div
                    role="button"
                    @mousemove="(e) => handleMouseMove('interactiveDiv1')(e)"
                    @mouseleave="(e) => handleMouseLeave('interactiveDiv1')"
                    id="interactiveDiv1"
                    class="business-header__card shadow text-left"
                >
                    <img height="40px" src="../assets/winden.svg" alt="" />
                    |
                    <span class="px-3 fw--600 fs--18">Winden</span>
                    <h1 class="fs--20 fw--600 mt-3">
                        Banking designed for
                        <br />
                        entrepreneurs.
                    </h1>
                    <p class="fs--14">
                        Create unlimited virtual cards and earn 1% back on all purchases.
                        <!-- <br />
                        balance -->
                    </p>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="fs--30 fw--700 m-0">9.8</div>
                            <div class="ml-1">
                                <div>
                                    <b-form-rating v-model="value" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                </div>

                                <div class="cursor-pointer" style="line-height: 0.8">
                                    <span class="fs--10 fw--600 mr-1">CreditGrail</span>
                                    <span class="fs--10">score</span>
                                </div>
                            </div>
                        </div>
                        <div class="business-header__button ml-3">
                            <span class="fs--12 fw--700">EXPLORE</span>
                        </div>
                    </div>
                </div>
            </b-col>
            <b-col md="6" xl="4">
                <div
                    role="button"
                    @mousemove="(e) => handleMouseMove('interactiveDiv2')(e)"
                    @mouseleave="(e) => handleMouseLeave('interactiveDiv2')"
                    id="interactiveDiv2"
                    class="business-header__card text-decoration-none text-dark d-block shadow text-left"
                >
                    <img src="../assets/businessicon3.svg" alt="" height="40px" />
                    |
                    <span class="px-3 fw--600 fs--18">Bluevine</span>
                    <h1 class="fs--20 fw--600 mt-3">
                        Banking platform for
                        <br />
                        small businesses
                    </h1>
                    <p class="fs--14">
                        You can earn 2.0% APY on
                        <br />
                        balance up to $250k
                    </p>
                    <div class="d-flex align-items-center">
                        <div class="d-flex align-items-center">
                            <div class="fs--30 fw--700 m-0">9.1</div>
                            <div class="ml-1">
                                <div>
                                    <b-form-rating v-model="value" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                </div>

                                <div class="cursor-pointer" style="line-height: 0.8">
                                    <span class="fs--10 fw--600 mr-1">CreditGrail</span>
                                    <span class="fs--10">score</span>
                                </div>
                            </div>
                        </div>
                        <div class="business-header__button ml-3">
                            <span class="fs--12 fw--700">EXPLORE</span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'BusinessHeader',
    data() {
        return {
            value: 4.5,
        };
    },
    methods: {
        redirectTo(link) {
            window.location.href = link;
        },
        handleMouseLeave(id) {
            let target = document.getElementById(id);
            target.style.transform = `translate(0px, 0px) scale(1)`;
        },
        handleMouseMove(id) {
            return function (e) {
                const maxMove = 30;
                const maxMoveRatio = 0.3;
                let target = document.getElementById(id);
                const mouseX = e.clientX;
                const mouseY = e.clientY;
                const rect = target.getBoundingClientRect();
                const divX = rect.left + rect.width / 2;
                const divY = rect.top + rect.height / 2;
                let deltaX = mouseX - divX;
                let deltaY = mouseY - divY;
                deltaX = Math.max(-maxMove, Math.min(maxMove, deltaX)) * maxMoveRatio;
                deltaY = Math.max(-maxMove, Math.min(maxMove, deltaY)) * maxMoveRatio;
                const scaleAmount = 1.075;
                target.style.transform = `translate(${deltaX}px, ${deltaY}px) scale(${scaleAmount})`;
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.business-header {
    &__card {
        @media (max-width: 991.98px) {
            height: 100% !important;
        }
    }
}
.bg-header {
    @media (max-width: 767.98px) {
        background-color: #f4f4f4 !important;
    }
}
</style>
