<template>
  <div class="text-justify">
    <h6 class="fs--20 fw--700 text-site-dark">Upgrade Disclaimers</h6>
    <p class="fs--17 fw--400 mt-3 text-site-dark">
      Upgrade is a financial technology company, not a bank. Rewards Checking
      Plus services provided by Cross River Bank, Member FDIC. Upgrade VISA ®
      Debit Cards issued by Cross River Bank, Member FDIC, pursuant to a license
      from Visa U.S.A. Inc.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      ¹ Rewards Checking Plus customers who set-up monthly direct deposits of
      $1,000 or more earn 2% cash back on common everyday expenses at
      convenience stores, drugstores, restaurants and bars - including
      deliveries - and gas stations, as well as recurring payments on utilities
      and monthly subscriptions including phone, cable, TV and other streaming
      services, and 1% cash back on all other debit card purchases. 2% cash back
      is limited to $500 in rewards per calendar year; after $500, customers
      earn 1% cash back on all eligible debit card purchases for the remainder
      of the year. Rewards Checking Plus accounts with less than $1,000 in
      monthly direct deposits 60 days after account opening will earn 1% cash
      back on common everyday expenses and 0.50% cash back on all other eligible
      debit card purchases. Some limitations apply. Please refer to the
      applicable Upgrade VISA® Debit Card Agreement and Disclosures for more
      information.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      ² There are no overdraft fees or annual fees associated with Rewards
      Checking Plus accounts. Rewards Checking Plus charges no ATM fees, but
      third-party institutions may charge you a fee if you use their ATM/network
      or if you use your Upgrade VISA® Debit Card internationally. For Active
      Accounts, Upgrade will rebate ATM fees charged by another institution for
      debit card withdrawals in the United States, up to five times per calendar
      month. To be eligible to receive third-party ATM fee rebates in any
      calendar month for eligible ATM withdrawals made during that month on a
      Rewards Checking Plus account, the account must be an Active Account in
      the prior calendar month. See the Active Account page for more
      information. As a courtesy to new customers, Upgrade will provide
      third-party ATM fee rebates for 60 days after account opening regardless
      of account user status. Some limitations apply. Please refer to the
      applicable Cross River Bank Checking Deposit Account Agreement and Upgrade
      VISA® Debit Card Agreement and Disclosures for more information.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      ³ The 1.5% Annual Percentage Yield (APY) for the Performance Savings
      account is effective as of June 1, 2023. The APY for the Performance
      Savings account is variable and may change at any time. Active Accounts
      qualify for 5.07% APY with monthly $1000 direct deposit. The APY for
      Active Accounts is effective as of September 1, 2023, is variable, and may
      change at any time. Please refer to the applicable Cross River Bank
      Performance Savings Deposit Account Agreement and Truth in Savings
      Disclosure.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      To earn APY, you must open and fund a Performance Savings account. Rewards
      Checking Plus accounts do not earn APY.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      ⁴ Active Accounts with a monthly direct deposit of $1000 are eligible for
      additional benefits on Rewards Checking Plus such as additional cash back
      rewards, ATM fee reimbursement, and more.
    </p>
    <p class="fs--17 fw--400 mt-5 text-site-dark">
      ⁵ Early direct deposit requires set up of recurring electronic direct
      deposit from your employer, payroll, or benefits provider and is limited
      to direct deposits under $5000. Early access to direct deposit funds
      depends on the timing in which we receive notice of impending direct
      deposit, which is generally up to two days before the scheduled deposit
      date.
    </p>
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
