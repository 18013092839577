<template>
    <div class="">
        <b-row class="top-choice align-items-center d-none d-lg-block bg-white position-relative" :class="{ 'top-choice-small-top': topChoice }" no-gutters>
            <b-col sm="12" class="px-0 py-0 position-absolute" v-if="objChoice?.earning != null && topChoice == ''">
                <div class="top-choice__earning bg-site-primary px-2 py-2 fs--14 fw--600">
                    {{ objChoice?.earning }}
                </div>
            </b-col>
            <b-col sm="12" class="px-0 py-0 position-absolute" v-if="objChoice?.indexNumber && topChoice == ''">
                <div class="d-flex">
                    <div class="bg-site-dark px-2 fs--18 fw--700 text-white">
                        {{ objChoice?.indexNumber }}
                    </div>
                    <div class="top-choice__indexNumber"></div>
                </div>
            </b-col>
            <b-col sm="12" class="px-0 py-0" v-if="topChoice">
                <div class="bg-site-secondary1 text-site-dark fs--16 px-2 py-2 fw--700 text-center">
                    {{ topChoice }}
                </div>
            </b-col>
            <b-col sm="12" class="px-0 py-0 d-none d-lg-block">
                <b-row class="top-choice__content">
                    <b-col md="4" lg="3" class="d-flex flex-column">
                        <div class="text-left m-auto">
                            <img class="top-choice__logo" :src="objChoice?.logo" />
                        </div>
                        <div class="d-flex align-items-center mx-auto">
                            <div class="fs--36 fw--700 text-site-black">
                                {{ objChoice?.reviewValue }}
                            </div>
                            <div>
                                <div class="ml-2">
                                    <b-form-rating :value="objChoice?.reviewValue" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                </div>

                                <div class="cursor-pointer" :id="'tooltip-target-' + index">
                                    <span class="fs--10 fw--600 ml-1 mr-1 text-site-black">CreditGrail</span>
                                    <span class="fs--10">
                                        score
                                        <svg
                                            viewBox="0 0 8 5"
                                            class="cthH2V9o"
                                            style="
                                                 {
                                                    width: 5px;
                                                    height: 5px;
                                                }
                                            "
                                        >
                                            <path
                                                fill="#000"
                                                d="M3.941 4.609a.68.68 0 00.47-.195L7.667 1.16A.677.677 0 007.668.2a.679.679 0 00-.96-.002L3.933 2.973 1.157.198A.678.678 0 10.2 1.16l3.254 3.254a.68.68 0 00.487.195z"
                                            ></path>
                                        </svg>
                                    </span>
                                </div>

                                <b-tooltip custom-class="choice-tooltip" :target="'tooltip-target-' + index" triggers="hover" placement="bottom">
                                    <div class="text-left">
                                        <div class="fs--16 fw--600">Popularity</div>
                                        <div class="fs--12 fw--600">Based on visits in the past 7 days</div>
                                        <div class="d-flex w-100 align-items-center popularity">
                                            <b-progress height="7px" :value="objChoice?.popularity" max="10"></b-progress>
                                            <span class="mx-2">
                                                <svg
                                                    style="
                                                         {
                                                            width: 17px;
                                                            height: 17px;
                                                        }
                                                    "
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 16 17"
                                                >
                                                    <path
                                                        d="M5.097 2.731a.359.359 0 01-.36-.359V.36a.359.359 0 11.718 0v2.013c0 .199-.16.36-.358.36zM3.17 3.53a.358.358 0 01-.253-.106L1.493 2A.359.359 0 112 1.493l1.424 1.424a.359.359 0 01-.254.612zM2.372 5.455H.36a.359.359 0 110-.717h2.013a.359.359 0 110 .717zM1.746 8.806a.359.359 0 01-.254-.613L2.918 6.77a.359.359 0 11.507.508L2 8.7a.358.358 0 01-.254.105zM7.023 3.53a.358.358 0 01-.254-.613l1.424-1.424A.359.359 0 118.701 2L7.277 3.424a.358.358 0 01-.254.105zM14.805 7.899l-9.6-3.524a.704.704 0 00-.905.903l3.525 9.601a.604.604 0 001.084.103L10.5 12.33l3.38 3.38a1.242 1.242 0 001.756-1.756l-3.38-3.38 2.652-1.592a.604.604 0 00-.103-1.084z"
                                                        fill="#61ace6"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <span class="fs--16 fw--600">{{ objChoice?.popularity }}</span>
                                        </div>

                                        <hr class="bg-site-secondary mt-2" />

                                        <div class="fs--16 fw--600">Brand Reputation</div>
                                        <div class="fs--12 fw--600">Based on web trends</div>
                                        <div class="d-flex w-100 align-items-center brand-reputation">
                                            <b-progress height="7px" :value="objChoice?.brandReputation" max="10"></b-progress>
                                            <span class="mx-2">
                                                <svg
                                                    fill="#FFD969"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 14 14"
                                                    style="
                                                         {
                                                            width: 17px;
                                                            height: 17px;
                                                        }
                                                    "
                                                >
                                                    <path
                                                        d="M3.778 5.767v7.671c0 .247-.225.447-.502.447H.502c-.277 0-.502-.2-.502-.447V5.767c0-.247.225-.447.502-.447h2.774c.277 0 .502.2.502.447zM14 6.4c0-.599-.485-1.083-1.083-1.083h-.763l-2.849-.023s-.684.059-.9-.271c-.13-.2-.13-.45-.001-.743.538-1.221.728-2.477.213-3.505A1.592 1.592 0 007.87.038a.37.37 0 00-.525.27l-.001.006c-.05.288-.082.64-.116 1.013-.05.546-.201 1.146-.344 1.568-.236.693-.359.717-1.073 1.436-.167.168-.34.342-.524.534-.074.077-.15.147-.231.222-.125.115-.285.34-.43.562a2.308 2.308 0 00-.374 1.259v5.269c0 .52.348.977.85 1.114.43.117.984.225 1.57.225h6.133a.95.95 0 00.784-.94.96.96 0 00-.71-.927 1.083 1.083 0 00.037-2.112 1.083 1.083 0 00.342-2.11c.43-.144.741-.55.741-1.028z"
                                                        fill="#FFD969"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <span class="fs--16 fw--600">{{ objChoice?.brandReputation }}</span>
                                        </div>
                                        <hr class="bg-site-secondary mt-2" />
                                        <div class="fs--16 fw--600">Features & Benefits</div>
                                        <div class="fs--12 fw--600">Based on our editorial reviews</div>
                                        <div class="d-flex w-100 align-items-center editorial-reviews">
                                            <b-progress height="7px" :value="objChoice?.editorialReviews" max="10"></b-progress>
                                            <span class="mx-2">
                                                <svg
                                                    fill="none"
                                                    viewBox="0 0 16 22"
                                                    style="
                                                         {
                                                            width: 20px;
                                                            height: 20px;
                                                        }
                                                    "
                                                >
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M8.301 15.474L6.735 21l-1.84-2.328-2.99.931 1.84-5.585"
                                                        fill="#fff"
                                                    ></path>
                                                    <path
                                                        d="M8.301 15.474L6.735 21l-1.84-2.328-2.99.931 1.84-5.585"
                                                        stroke="#171717"
                                                        stroke-width="0.78"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    ></path>
                                                    <path
                                                        fill-rule="evenodd"
                                                        clip-rule="evenodd"
                                                        d="M12.049 14.274l2.276 4.399-2.76-.233L9.955 21l-2.07-3.724"
                                                        fill="#fff"
                                                    ></path>
                                                    <path
                                                        d="M12.049 14.274l2.276 4.399-2.76-.233L9.955 21l-2.07-3.724"
                                                        stroke="#171717"
                                                        stroke-width="0.78"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    ></path>
                                                    <ellipse cx="8.059" cy="8.143" rx="7.059" ry="7.143" fill="#fff" stroke="#171717" stroke-width="1.2"></ellipse>
                                                    <path
                                                        d="M5.484 8.986l1.474 1.491 3.439-3.728"
                                                        stroke="#117DFF"
                                                        stroke-width="1.357"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <span class="fs--16 fw--600">{{ objChoice?.editorialReviews }}</span>
                                        </div>
                                    </div>
                                </b-tooltip>
                            </div>
                        </div>
                    </b-col>
                    <b-col md="4" lg="6">
                        <div class="text-left">
                            <div class="d-flex align-items-center">
                                <span class="fs--16 fw--700 text-site-black">
                                    {{ objChoice?.topChoiceContent?.title }}
                                </span>
                                <div class="d-flex top-choice__rewardIcon ml-2 cursor-pointer" v-if="objChoice?.rewardIcon">
                                    <div>
                                        <img :src="objChoice?.rewardIcon" height="28px" width="22px" />
                                    </div>
                                    <div class="fs--14 py-1 hover-text px-2">Our Best Choice</div>
                                </div>
                            </div>

                            <h6 class="fs--14 fw--600 mt-2 text-site-black">
                                {{ objChoice?.topChoiceContent?.subtitle }}
                            </h6>
                        </div>
                        <div class="d-flex justify-content-start align-items-center" v-if="objChoice?.background != 'show'">
                            <span class="mr-2 d-flex" v-if="objChoice?.icon">
                                <img class="top-choice__icon" :src="objChoice?.icon" />
                            </span>
                            <span class="fs--14">{{ objChoice?.topChoiceContent?.anualPercntage }}</span>
                        </div>
                        <div class="my-2 top-choice__anualPercntage d-flex justify-content-start align-items-center" v-if="objChoice?.background == 'show'">
                            <span class="mr-2 d-flex" v-if="objChoice?.icon">
                                <img class="top-choice__icon" :src="objChoice?.icon" />
                            </span>
                            <span class="fs--12 fw--600 text-white">{{ objChoice?.topChoiceContent?.anualPercntage }}</span>
                        </div>
                        <div v-for="(value, index) in objChoice?.topChoiceContent?.list" :key="index" class="text-left d-flex">
                            <span class="mr-2">
                                <svg
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 12 9"
                                    class="XLjLPI2H"
                                    style="
                                         {
                                            width: 8px;
                                            height: 8px;
                                        }
                                    "
                                >
                                    <path
                                        d="M11.077 0a.881.881 0 00-.61.274C8.272 2.492 6.274 4.68 4.14 6.866L1.443 4.689a.881.881 0 00-1.248.14.901.901 0 00.139 1.26l3.329 2.687a.882.882 0 001.186-.07c2.386-2.412 4.53-4.79 6.88-7.166a.9.9 0 00.2-.996.887.887 0 00-.852-.543z"
                                        fill="#1564BF"
                                        style=""
                                    ></path>
                                </svg>
                            </span>
                            <span class="fs--14 text-site-black py-2">{{ value }}</span>
                        </div>
                    </b-col>
                    <b-col md="4" lg="3" class="align-self-center">
                        <div class="position-relative">
                            <div class="top-choice__site-visit px-2" v-if="objChoice?.siteView != null">
                                <div class="pr-2">
                                    <img :src="socialprooficon" />
                                </div>
                                <div class="text-left">
                                    <div>
                                        <span class="fs--14 fw--700 mr-1 text-site-black">{{ objChoice?.siteView }} people</span>
                                        <span class="fs--13">visited</span>
                                    </div>
                                    <div>
                                        <span class="fs--13">this site this week</span>
                                    </div>
                                </div>
                            </div>
                            <div class="button px-2 py-2 fs--16 fw--600">
                                <span>{{ objChoice?.buttonText }}</span>
                                <span class="arrow">
                                    <i class="fa-sharp fa-solid fa-arrow-right"></i>
                                </span>
                            </div>
                            <div class="text-site-blue fs--14 fw--600 mt-3 text-center">Read Review</div>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row class="align-items-center d-lg-none mx-1 mb-3" v-if="topChoice == ''">
            <div class="top-choice-small">
                <div class="top-choice-small__card position-relative overflow-hidden">
                    <b-col cols="12" class="px-0 py-0 position-absolute" v-if="objChoice?.indexNumber && topChoice == ''">
                        <div class="d-flex">
                            <div class="bg-site-dark px-2 fs--14 d-flex align-items-center fw--700 text-white">
                                {{ objChoice?.indexNumber }}
                            </div>
                            <div class="top-choice__indexNumber"></div>
                        </div>
                    </b-col>
                    <b-col sm="12" class="px-0 py-0" v-if="objChoice?.earning != null">
                        <div class="top-choice-small__earning bg-site-primary px-2 py-2 fs--14 fw--600">
                            {{ objChoice?.earning }}
                        </div>
                    </b-col>
                    <b-col xs="12" sm="12" class="pt-2 pb-0">
                        <b-row class="px- py-2 align-items-center">
                            <b-col cols="8" md="7" class="pt-3">
                                <div>
                                    <div class="text-left mb-3">
                                        <img class="top-choice-small__logo" :src="objChoice?.logo" />
                                    </div>
                                    <div class="mb-3">
                                        <h6 class="fs--15 text-left mt-2 mb-3 fw--400 text-site-black">
                                            {{ objChoice?.topChoiceContent?.subtitle }}
                                        </h6>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="4" md="5" class="top-choice-small__border-right pl-0">
                                <div>
                                    <div class="fs--24 fw--700">{{ objChoice?.reviewValue }}</div>
                                    <div>
                                        <div>
                                            <b-form-rating :value="objChoice?.reviewValue" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                        </div>
                                        <div class="cursor-pointer">
                                            <span class="fs--10 fw--600 mr-1">CreditGrail</span>
                                            <span class="fs--10">score</span>
                                        </div>
                                    </div>

                                    <div class="my-2">
                                        <span class="px-1 py-2 bg-site-button text-white fs--13 fw--600 d-lg-inline d-none text-uppercase">
                                            {{ objChoice?.buttonText }}
                                        </span>
                                        <span class="px-1 py-2 bg-site-button text-white fs--13 fw--600 d-inline d-lg-none text-capitalize">
                                            {{ objChoice?.buttonTextsm }}
                                        </span>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </div>

                <b-col col="12" sm="12" class="top-choice-small__bottom bg-white px-0" v-if="objChoice?.topChoiceContent?.anualPercntage">
                    <div class="top-choice__anualPercntag py-2 mb-0">
                        <span class="mr-2 d-flex">
                            <img class="top-choice__icon" :src="objChoice.iconsm ? objChoice.iconsm : objChoice.icon" />
                        </span>

                        <span class="fs--12 fw--600 text-site-dark">{{ objChoice?.topChoiceContent?.anualPercntage }}</span>
                    </div>
                </b-col>
            </div>

            <!-- <b-col sm="12" class="d-block d-md-none">
        <hr class="bg-site-grey" />
        <div class="d-flex justify-content-center align-items-center">
          <span class="mr-1"> <img :src="dollar" /> </span
          ><span class="fs--14">{{ topChoiceContent?.anualPercntage }}</span>
        </div>
      </b-col> -->
        </b-row>
        <b-row class="align-items-center d-lg-none mx-1" v-if="topChoice">
            <div class="top-choice-small-top bg-white">
                <div class="">
                    <b-col sm="12" class="px-0 py-0">
                        <div class="bg-site-secondary1 px-2 py-2 fs--16 fw--700">
                            {{ topChoice }}
                        </div>
                    </b-col>
                    <b-col cols="12" sm="12" class="px-3 py-2">
                        <b-row class="px-2 py-2 align-items-center">
                            <b-col cols="7" class="pt-3">
                                <div>
                                    <div class="text-left mb-3">
                                        <img class="top-choice-small__logo" :src="objChoice?.logo" />
                                        <div class="d-flex my-2 align-items-center">
                                            <div class="fs--24 fw--700">
                                                {{ objChoice?.reviewValue }}
                                            </div>
                                            <div class="lineHeight">
                                                <!-- <div> -->
                                                <b-form-rating :value="objChoice?.reviewValue" class="custom-color px-0 m-0 py-0"></b-form-rating>
                                                <!-- </div> -->
                                                <div class="cursor-pointer">
                                                    <span class="fs--10 fw--600 mr-1">CreditGrail</span>
                                                    <span class="fs--10">score</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>

                            <b-col cols="5" class="pl-0">
                                <div class="mb-3">
                                    <h6 class="fs--15 text-left mt-2 mb-3 fw--400 text-site-black">
                                        {{ objChoice?.topChoiceContent?.subtitle }}
                                    </h6>
                                </div>
                            </b-col>
                            <b-col cols="12">
                                <!-- <div class="px-5 py-2 bg-site-button text-white fs--13 fw--600">
                                    {{ objChoice?.buttonText }}
                                </div> -->
                                <div class="px-5 py-2 bg-site-button text-white fs--13 fw--600 d-none d-md-block">
                                    {{ objChoice?.buttonText }}
                                </div>
                                <div class="px-5 py-2 bg-site-button text-white fs--13 fw--600 d-block d-md-none">
                                    {{ objChoice?.buttonTextsm }}
                                </div>
                            </b-col>
                            <b-col cols="12" sm="12" v-if="objChoice?.topChoiceContent?.anualPercntage">
                                <div class="my-2 top-choice__anualPercntage d-flex justify-content-center align-items-center" v-if="objChoice?.background == 'show'">
                                    <span class="mr-2 d-flex" v-if="objChoice?.icon">
                                        <img class="top-choice__icon" :src="objChoice?.icon" />
                                    </span>
                                    <span class="fs--12 fw--600 text-white">{{ objChoice?.topChoiceContent?.anualPercntage }}</span>
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                </div>
            </div>
        </b-row>
    </div>
</template>
<script>
import '../assets/scss/index.scss';

import brandLogo from '../assets/brandlogo.jpg';

import rewardIcon from '../assets/Icon3.svg';
import socialprooficon from '../assets/social-proof-icon.svg';
export default {
    name: 'TopChoice',
    props: {
        objChoice: {
            type: Object,
            required: true,
        },
        index: Number,
        topChoice: String,
        // dataReview: Object,
        // index: Number,
        // toggle: Function,
    },

    data() {
        return {
            //popularity: 20,
            rewardIcon: rewardIcon,
            logo: brandLogo,
            socialprooficon: socialprooficon,
            value: 5,
            topChoiceContent: {
                title: 'SoFi Checking and Savings',
                subtitle: 'The savings account to reach your financial goals',
                anualPercntage: '4.35% Annual Percentage Yield with $0 min to earn',
                list: [
                    'No fees or minimum balances',
                    'Earn up to $250 with qualifying direct deposit, terms apply',
                    'Up to 15% cash back at local businesses',
                    'Get up to $2M of additional FDIC Insurance through the SoFi Insured',
                ],
            },
        };
    },
    methods: {},
};
</script>

<style lang="scss">
.lineHeight {
    line-height: 1;
}
.top-choice,
.top-choice-small {
    .b-rating {
        width: min-content !important;
        .b-rating-star {
            padding: 0.5px !important;
            .b-icon.bi {
                width: 15px;
            }
        }
        @media (max-width: 991.98px) {
            margin: auto !important;
            .b-rating-star {
                padding-right: 2px !important;
                .b-icon.bi {
                    width: 9px;
                }
            }
        }
    }
}
.top-choice-small-top {
    .b-rating {
        width: min-content !important;
        .b-rating-star {
            padding: 0.5px !important;
            .b-icon.bi {
                width: 15px;
            }
        }
        @media (max-width: 991.98px) {
            .b-rating-star {
                padding-right: 2px !important;
                .b-icon.bi {
                    width: 10px;
                }
            }
        }
    }
}
.custom-color .b-rating-star {
    color: #61ace6; /* Set your custom color for the stars */
}
.b-rating {
    border: none !important;
    height: 8px !important;
}
.popularity {
    .progress {
        width: 75% !important;
        .progress-bar {
            background-color: #61ace6 !important ;
        }
    }
}
.brand-reputation {
    .progress {
        width: 75% !important;
        .progress-bar {
            background-color: #ffd969 !important ;
        }
    }
}
.editorial-reviews {
    .progress {
        width: 75% !important;
        .progress-bar {
            background-color: #117dff !important ;
        }
    }
}
.choice-tooltip {
    // .tooltip {
    width: 240px !important;

    .arrow {
        opacity: 1 !important;
    }
    // }

    .tooltip-inner {
        max-width: 40vw !important;
        padding: 5px 16px 9px !important;
        color: #ffff !important;
        background-color: #171717 !important;
    }
}
.button {
    span {
        text-transform: uppercase;
        @media only screen and (max-width: 767.98px) {
            text-transform: capitalize;
        }
    }
}
</style>
