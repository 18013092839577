<template>
    <div class="details text-left">
        <h2>{{ data.title }}</h2>
        <div v-for="(detail, index) in data.details" :key="index">
            <div v-if="detail.title !== ''" class="d-flex align-items-center my-4">
                <span class="simple-box mr-3" />
                <small>{{ detail.title }}</small>
            </div>
            <p class="mb-0">
                {{ detail.desc }}
            </p>
        </div>
        <hr class="my-5" />
    </div>
</template>
<script>
export default {
    name: 'BusinessCheckingDetails',
    props: ['data'],
};
</script>
<style scoped>
h2 {
    color: #171717;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    @media only screen and (max-width: 767.98px) {
        font-weight: 700;
    }
}
p {
    font-weight: 300;
    margin: 17px 0;
    font-size: inherit;
    line-height: 30px;
    color: #171717;
}
hr {
    margin-top: 40px;
}
small {
    font-size: 15px;
}
.simple-box {
    background-color: #49a8ff;
}
</style>
