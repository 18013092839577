<template>
    <div id="app">
        <navbar />
        <router-view />
        <Footer />
    </div>
</template>
<script>
import Footer from '@/components/global/Footer.vue';
import Navbar from '@/components/global/Navbar.vue';
export default {
    name: 'Home',
    components: {
        Navbar,
        Footer,
    },
};
</script>
<style lang="scss">
#app {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    // color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.custom-carousel .slick-prev:before {
    position: absolute;
    opacity: 1 !important;
    font-family: monospace !important;
    top: 50%;
    transform: translateY(-20%);
    right: 0;
    left: auto;
    color: #49a8ff !important;
    font-size: 30px !important;
    @media (max-width: 767.98px) {
        right: -20px;
    }
}
.custom-carousel .slick-next:before {
    position: absolute;
    opacity: 1 !important;
    font-family: monospace !important;
    top: 50%;
    transform: translateY(-20%);
    right: 0;
    left: auto;
    color: #49a8ff !important;
    font-size: 30px !important;
    @media (max-width: 767.98px) {
        right: 27px;
    }
}

.add {
    .arrow {
        &::before {
            border-bottom-color: #ddd !important;
        }
    }
}
</style>
