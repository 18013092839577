<template>
    <div class="experienceCarousal">
        <h4 class="mb-n5 experienceCarousal__heading">In the past year:</h4>
        <VueSlickCarousel
            :arrows="false"
            :fade="true"
            :autoplay="true"
            :pauseOnHover="false"
            :dots="true"
            :infinite="true"
            :speed="500"
            :slidesToShow="1"
            :slidesToScroll="1"
        >
            <div>
                <b-row>
                    <b-col cols="12" md="7" class="mx-md-0 mx-auto"><h2 class="text fw--600 mt-5 pt-2">100,000+ drivers connected with a car lender</h2></b-col>
                    <b-col cols="12" md="5" class="mx-md-0 mx-auto">
                        <div class="img-col position-relative mx-md-0 mx-auto">
                            <img :src="img1" class="img1 position-absolute" />
                            <img :src="img2" class="img2 position-absolute" />
                            <img :src="img3" class="img3 position-absolute" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-row>
                    <b-col cols="12" md="7" class="mx-md-0 mx-auto"><h2 class="text fw--600 mt-5 pt-2">330,000+ borrowers applied for a personal loan</h2></b-col>
                    <b-col cols="12" md="5" class="mx-md-0 mx-auto">
                        <div class="img-col position-relative mx-md-0 mx-auto">
                            <img :src="img4" class="img1 position-absolute" />
                            <img :src="img5" class="img2 position-absolute" />
                            <img :src="img6" class="img3 position-absolute" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-row>
                    <b-col cols="12" md="7" class="mx-md-0 mx-auto"><h2 class="text fw--600 mt-5 pt-2">50,000+ pet parents received a pet insurance quote</h2></b-col>
                    <b-col cols="12" md="5" class="mx-md-0 mx-auto">
                        <div class="img-col position-relative mx-md-0 mx-auto">
                            <img :src="img7" class="img1 position-absolute img21" />
                            <img :src="img8" class="img2 position-absolute img22" />
                            <img :src="img9" class="img3 position-absolute img23" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-row>
                    <b-col cols="12" md="7" class="mx-md-0 mx-auto"><h2 class="text fw--600 mt-5 pt-2">100,000+ drivers connected with a car lender</h2></b-col>
                    <b-col cols="12" md="5" class="mx-md-0 mx-auto">
                        <div class="img-col position-relative mx-md-0 mx-auto">
                            <img :src="img10" class="img1 position-absolute img31" />
                            <img :src="img11" class="img2 position-absolute img32" />
                            <img :src="img12" class="img3 position-absolute img33" />
                        </div>
                    </b-col>
                </b-row>
            </div>
        </VueSlickCarousel>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import img1 from '../assets/carouselExp/1.webp';
import img2 from '../assets/carouselExp/2.webp';
import img3 from '../assets/carouselExp/3.webp';
import img4 from '../assets/carouselExp/4.webp';
import img5 from '../assets/carouselExp/5.webp';
import img6 from '../assets/carouselExp/6.webp';
import img7 from '../assets/carouselExp/7.webp';
import img8 from '../assets/carouselExp/8.webp';
import img9 from '../assets/carouselExp/9.webp';
import img10 from '../assets/carouselExp/10.webp';
import img11 from '../assets/carouselExp/11.webp';
import img12 from '../assets/carouselExp/12.jpg';

export default {
    name: 'Carousel',
    components: { VueSlickCarousel },

    data() {
        return {
            img1,
            img2,
            img3,
            img4,
            img5,
            img6,
            img7,
            img8,
            img9,
            img10,
            img11,
            img12,
        };
    },
};
</script>

<style lang="scss" scoped>
.experienceCarousal {
    .slick-list {
        overflow-x: visible !important;
    }
    &__heading {
        text-align: left;
        font-size: 24px;
        font-weight: 300;
        @media (max-width: 767.98px) {
            text-align: center;
            font-size: 18px;
        }
    }
    .text {
        text-align: left;
        font-size: 38px;
        font-weight: 600;
        @media (max-width: 767.98px) {
            text-align: center;
            font-size: 24px;
        }
    }
    .img-col {
        height: 350px;
        min-width: 400px;
        @media (max-width: 767.98px) {
            min-width: 250px;
            height: 250px;
        }
        img {
            object-fit: cover;
        }
        .img1 {
            width: 180px;
            height: 270px;
            left: 0;
            top: 40px;
            z-index: 3;
            border-radius: 10px;
            @media (max-width: 767.98px) {
                width: 130px;
                height: 190px;
                left: auto;
                right: 150px;
            }
        }
        .img31 {
            width: 195px;
            height: 280px;
            left: 30%;
            @media (max-width: 767.98px) {
                width: 130px;
                height: 200px;
            }
        }
        .img2 {
            width: 160px;
            height: 150px;
            right: 110px;
            top: 0;
            z-index: 2;
            border-radius: 10px;
            @media (max-width: 767.98px) {
                width: 120px;
                height: 107px;
                right: 60px;
            }
        }
        .img22 {
            width: 140px;
            height: 180px;
            @media (max-width: 767.98px) {
                width: 90px;
                height: 120px;
                right: 80px;
            }
        }

        .img32 {
            width: 170px;
            height: 160px;
            left: 0;
            z-index: 3;
            @media (max-width: 767.98px) {
                width: 120px;
                height: 107px;
            }
        }
        .img3 {
            width: 130px;
            height: 160px;
            right: 150px;
            bottom: 0;
            z-index: 2;
            border-radius: 10px;
            @media (max-width: 767.98px) {
                width: 100px;
                height: 120px;
                right: 90px;
            }
        }
        .img23 {
            width: 170px;
            height: 160px;
            right: 90px;
            bottom: -20px;

            @media (max-width: 767.98px) {
                width: 107px;
                height: 100px;
                right: 80px;
            }
        }
        .img33 {
            width: 140px;
            height: 180px;
            right: 0;
            z-index: 3;
            @media (max-width: 767.98px) {
                width: 95px;
                height: 120px;
                right: 40px;
            }
        }
    }
    .col-12,
    .col-sm-6 {
        @media (max-width: 767.98px) {
            max-width: 400px;
        }
    }
}
</style>
