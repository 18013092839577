<template>
  <div
    class="d-flex flex-column flex-md-row align-items-center align-items-md-start toggle my-4"
  >
    <b-link
      v-if="$route.fullPath == '/compare-online-banking'"
      class="mr-md-2 my-2 my-md-0 d-flex flex-column toggle__button align-items-center"
      :class="
        $route.fullPath == '/compare-online-banking' && 'toggle__button-active'
      "
      to="/compare-online-banking"
    >
      <img
        class="toggle__button--icon"
        src="../assets/OnlineBankingicon.svg"
        alt=""
      />
      <span class="pt-1 fs--14 toggle__button--text">Online Banking</span>
    </b-link>
    <b-link
      class="d-flex flex-column toggle__button align-items-center"
      to="/compare-savings-accounts"
      :class="
        $route.fullPath == '/compare-savings-accounts' &&
        'toggle__button-active'
      "
    >
      <img
        class="toggle__button--icon"
        src="../assets/Savings_accountsBlue.svg"
        alt=""
      />
      <span class="pt-1 fs--14 toggle__button--text">
        Savings Accounts</span
      ></b-link
    >

    <b-link
      class="mx-md-2 my-2 my-md-0 d-flex flex-column toggle__button align-items-center"
      to="/compare-checking-accounts"
      :class="
        $route.fullPath == '/compare-checking-accounts' &&
        'toggle__button-active'
      "
    >
      <img
        class="toggle__button--icon"
        src="../assets/Checking.accounts.Blue.svg"
        alt=""
      />
      <span class="pt-2 fs--14 toggle__button--text"> Checking Accounts</span>
    </b-link>

    <b-link
      class="d-flex flex-column toggle__button align-items-center"
      to="/compare-business-checking"
      :class="
        $route.fullPath == '/compare-business-checking' &&
        'toggle__button-active'
      "
    >
      <img
        class="toggle__button--icon"
        src="../assets/Business.checkingBlue.svg"
        alt=""
      />
      <span class="pt-2 fs--14 toggle__button--text"
        >Business Checking</span
      ></b-link
    >
  </div>
</template>
<script>
export default {
  name: "toggle",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.toggle {
  margin-bottom: -40px !important;
}
</style>
