import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import OnlineBanking from "../views/OnlineBanking.vue";
import SavingAccounts from "../views/SavingAccounts.vue";
import BusinessChecking from "../views/BusinessChecking.vue";
import CheckingAccounts from "../views/CheckingAccounts.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/compare-online-banking",
    name: "OnlineBanking",
    component: OnlineBanking,
  },
  {
    path: "/compare-business-checking",
    name: "BusinessChecking",
    component: BusinessChecking,
  },
  {
    path: "/compare-checking-accounts",
    name: "CheckingAccounts",
    component: CheckingAccounts,
  },
  {
    path: "/compare-savings-accounts",
    name: "SavingAccounts",
    component: SavingAccounts,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
});

export default router;
